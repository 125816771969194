import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { InnerNav } from './RoleNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {setUser} from '../../ducks';

const actions = {
  setUser,
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    is_masquerade : state.auth.isMasquerade,
    admin_id : state.auth.masquerade_user,
    isAuthenticated: state.auth.isAuthenticated
  };
};

class ConnectedAdminternHeader extends Component {

  logout = () => {
    //localStorage.removeItem('admintern_admin_id');
    //Meteor.logout();
    axios({
      method: 'get',
      url: '/auth/logout',
    }).then(function (response) {
      console.log(response);
      this.props.history.push("/");
    });
    //this.props.history.push("/login");
  }


  render() {
    
    let is_dev = window.location.href.indexOf("phocusdesigns.com") > 0 ? "dev_head" : "bg_dark";

    return (
      <header>

        <nav className={`navbar navbar-expand-lg fixed-top navbar-dark ${is_dev}`}>
          {<Link to={this.props.isAuthenticated ? '/default' : '/'} className="nav-link lonelyLink">
              <img src="/images/admintern.png" height="80px" alt="Admintern Logo" />
          </Link>
          }
          {window.location.href.indexOf("phocusdesigns.com") > 0 ? <span className="dev_alert">THIS IS THE DEV SITE</span> : ""}
          {window.location.href.indexOf("localhost") > 0 ? <span className="local_alert">YOU ARE WORKING LOCALLY</span> : ""}
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent" ref="toggleMenu">
            <ul className="navbar-nav ml-auto">
              {this.props.user.role === "SuperAdmin" ? 
              <li className="nav-item">
                <Link className="nav-link" to='/admintern/dashboard'>Client Management</Link>
                
              </li> : null }
              
              {this.props.user.role === "SuperAdmin" ? 
                <li className="nav-item dropdown">
              
              <button className="nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Client Defaults
              </button>
              
              <div className="dropdown-menu" aria-labelledby="navbarDropdown" onClick={this.props.closeMenu}>
                <Link className="dropdown-item" to="/admintern/reflectionQuestions">Reflection Questions</Link>
                <Link className="dropdown-item" to="/admintern/evaluationQuestions">Evaluation Questions</Link>
                <Link className="dropdown-item" to="/admintern/focusAreas">Focus Areas</Link>
                <Link className="dropdown-item" to="/admintern/programs">Programs</Link>
                <Link className="dropdown-item" to="/admin/focusAreas">Files</Link>
              </div>
            </li> : null }

              {this.props.user._id ? (
                <InnerNav logout={this.logout} masquerade={false} />
              ) : (
                <li className="nav-item more-room">
                  <Link className="nav-link" to='/login'><FontAwesomeIcon icon={["far", "sign-in-alt"]}/> Login</Link>
                </li>
              )}

            </ul>

          </div>
        </nav>

      </header>
    )
  }
}

const AdminternHeader = connect(mapStateToProps, actions)(ConnectedAdminternHeader);
export default withRouter(AdminternHeader);
