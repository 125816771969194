import React, { Component } from 'react';
import axios from 'axios'
import {setUser, setMasquerade, updateSettings, updateFiles, updateProjects } from '../../ducks';
import { connect } from 'react-redux';

const actions = {
  setUser,
  setMasquerade,
  updateSettings,
  updateFiles,
  updateProjects
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

class Masquerade extends Component {

  componentDidMount() {
    // this.makeCalls();
    // this.masquerade_user();
    setTimeout(this.masquerade_user, 1500)
  }
  
  getFiles = () => {
      return axios({
        method: 'get',
        url: '/api/getUsersFiles',
      });
    }
    
    getSettings = () => {
      return axios({
        method: 'get',
        url: '/api/getSettings',
      });
    }
    
    getProjects = () => {
        return axios({
          method: 'get',
          url: '/api/getProjectsByStudent',
        });
      }

  masquerade_user = () => {
    let setUser = this.props.setUser;
    let adminUser_id = this.props.match.params.admin_id;
    let user_id = this.props.match.params.user_id;
    let updateSettings = this.props.updateSettings;
    let updateFiles = this.props.updateFiles;
    let updateProjects = this.props.updateProjects
    axios({
      method: 'post',
      url: '/auth/masquerade',
      data : {
        id: user_id,
        password: 'yyz'
      }
    }).then(response => {
      let payload = {
        user : response.data.user,
        is_masquerade : "true",
        admin_id : adminUser_id
      }

      setUser(payload);

      // another server call here to set cookies on the server w/masqerade details      
      axios({
        method: 'post',
        url: '/api/user/set_masquerade',
        data : {
          adminUser_id: adminUser_id,
        }
      }).then(response => {
        
        axios.all([this.getSettings(), this.getFiles(), this.getProjects()])
        .then(axios.spread(function (settings,files,projects) {
          Promise.all([
            updateSettings(settings.data[0]),
            updateFiles(files.data),
            updateProjects(projects.data)
            ]).then(() => {
            this.props.history.push("/default");
            });
          
          
        }.bind(this)));
      })
      
      

      // NotificationManager.success('Application successfully submitted.', 'Application Successfull');
      
    });
  }




   render() {


    return (
      <div className="side-page">
        <div className="basic_form">
          <div className="page_header"><h1>Masquerading</h1></div>

          <div className="masquerade_screen">
            <p>Logging in as the requested user. Please wait.</p>
            <i class="far fa-cog fa-spin"></i>
          </div>

        </div>
      </div>
    )
   }
}

export default connect(mapStateToProps, actions)(Masquerade);
