import React, { Component } from "react";
import ld from "lodash";
import axios from "axios";

export default class Project_Questions extends Component {
  enableRemainingReflectionQuestions = () => {
    axios({
      method: "post",
      url: "/api/project_enableRemainingReflectionQuestions",
      data: {
        project_id: this.props.project._id,
      },
    }).then(function (response) {
      window.location.reload(false);
    });
  };

  render() {
    // console.log(this.props.project.reflections);
    let btn_enableAllQuestions = false;
    let has_locked = ld.filter(this.props.project.reflections, { status: "locked" });

    if (has_locked.length) btn_enableAllQuestions = true;

    return (
      <div>
        <h3>Reflection Questions</h3>
        {this.props.project.reflections.map((reflection) => (
          <>
            <div className="reflectionQuestionText">{reflection.question_text}</div>
            <div className="reflectionQuestionAnswer">
              {reflection.status === "locked" ? (
                "Question currently locked."
              ) : reflection.status === "unlocked" ? (
                "Question is available, but currently unanswered."
              ) : reflection.status === "complete" ? (
                <div dangerouslySetInnerHTML={{ __html: reflection.answer_text }}></div>
              ) : null}
            </div>
          </>
        ))}

        {btn_enableAllQuestions === true ? (
          <div style={{ textAlign: "center" }}>
            <hr />
            <button className="btn btn-primary" onClick={this.enableRemainingReflectionQuestions}>
              {" "}
              Click Here to Enable Remaining Reflection Questions{" "}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}
