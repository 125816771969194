import React, { Component} from "react";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


export default class OpenMap extends Component {

  state = {
    lat: 38.9530,
    lng: -76.5488,
    zoom: 10,
  }

  render() {

    let icon = L.icon({
     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
     iconUrl: require('leaflet/dist/images/marker-icon.png'),
     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
   })
    let loc = false;
    let zoom = this.state.zoom;
    let position = [this.state.lat, this.state.lng];
    
    
    if (this.props.location.lat && this.props.location.lon){
      loc = true;
      zoom = 13;
      position = [this.props.location.lat, this.props.location.lon];
    }

    // console.log("this is position: ", position)
    return (
      <>
      {!loc && <div className="locationError">Location Data Not Found</div>}
      <Map center={position} zoom={zoom}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {loc && 
      <Marker position={position} icon={icon}>
          <Popup>
            <a href={`https://www.google.com/maps/search/?api=1&query=${this.props.location.lat},${this.props.location.lon}`}>{this.props.location.street}<br /> {this.props.location.city} {this.props.location.state} {this.props.location.zip}</a>
          </Popup>
        </Marker>
      }
      </Map>
      </>
    )
  }
}




//
//
// <Map center={this.props.position} zoom={13}>
//   <TileLayer
//     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//   />
//   <Marker position={this.props.position}>
//     <Popup><a href={"https://www.google.com/maps/place/"+this.props.street+"+"+this.props.city+",+"+this.props.state+"+"+this.props.zip}>
//     {this.props.street}<br />{this.props.city} {this.props.state} {this.props.zip}
//     </a></Popup>
//   </Marker>
// </Map>
