import axios from 'axios';
import {NotificationManager} from 'react-notifications';

const apikey = "AIzaSyCKgMu1FtvFXMv38B8ntSJ56hdcqihYgaw"

const OSMgetStreetCords = (location) => {
  //const api = 'https://nominatim.openstreetmap.org/?format=json&limit=1&addressdetails=1&q=';
  const api = 'https://nominatim.openstreetmap.org/search?format=json&limit=5&addressdetails=1&country=US&';
  let query = "street=" + location.street +  "&state=" + location.state + "&postalcode=" + location.zip;
  return axios({
    method: 'get',
    url: api + query,
  }).then(results => {
    if (results.data.length > 0)
      return {lat: results.data[0].lat, lon : results.data[0].lon}
    else
      return null
  });
}

const OSMgetZipCords = (location) => {
  //const api = 'https://nominatim.openstreetmap.org/?format=json&limit=1&addressdetails=1&q=';
  const api = 'https://nominatim.openstreetmap.org/search?format=json&limit=1&addressdetails=1&country=US&';
  let query = "city=" + location.city + "&state=" + location.state + "&postalcode=" + location.zip;
  //let query = this.state.location.state + " " + this.state.location.zip;
  return axios({
    method: 'get',
    url: api + query,
  }).then(results => {
    if (results.data.length > 0)
      return {lat: results.data[0].lat, lon : results.data[0].lon}
    else
      return null
  });;
}

const getStreetCords = (location) => {
  let api = "https://maps.googleapis.com/maps/api/geocode/json?address="+location.street+",+"+location.city+",+"+location.state+",+"+location.zip+"&key="+apikey
  return axios({
    method: 'get',
    url: api,
  }).then(results => {
    console.log("google maps api:", results)
    if (results.data.results)
      return {lat: results.data.results[0].geometry.location.lat, lon : results.data.results[0].geometry.location.lng}
    else {
      NotificationManager.error('Something went wrong in the google search.', 'Error');
      return null
    }
  }).catch(error => {
    NotificationManager.error('Error Reaching Google Maps API.', 'Something Went Wrong');
    api = 'https://nominatim.openstreetmap.org/search?format=json&limit=5&addressdetails=1&country=US&';
    let query = "street=" + location.street +  "&state=" + location.state + "&postalcode=" + location.zip;
    return axios({
      method: 'get',
      url: api + query,
    }).then(results => {
      if (results.data.length > 0)
        return {lat: results.data[0].lat, lon : results.data[0].lon}
      else
        return null
    }).catch(error => {
      NotificationManager.error('Error Reaching Open Street Maps API.', 'Something Went Wrong');
      return null
    });
  });
}


const getZipCords = (location) => {
  let api = "https://maps.googleapis.com/maps/api/geocode/json?address="+location.zip+"&key="+apikey
  return axios({
    method: 'get',
    url: api,
  }).then(results => {
    console.log("google maps api zip results:", results)
    if (results.data.results)
      return {lat: results.data.results[0].geometry.location.lat, lon : results.data.results[0].geometry.location.lng}
    else
      return null
  }).catch(error =>{
    api = 'https://nominatim.openstreetmap.org/search?format=json&limit=1&addressdetails=1&country=US&';
    let query = "city=" + location.city + "&state=" + location.state + "&postalcode=" + location.zip;
    //let query = this.state.location.state + " " + this.state.location.zip;
    return axios({
      method: 'get',
      url: api + query,
    }).then(results => {
      if (results.data.length > 0)
        return {lat: results.data[0].lat, lon : results.data[0].lon}
      else
        return null
    }).catch(error => {
      return null
    });
  });
}

export {OSMgetStreetCords, OSMgetZipCords, getStreetCords, getZipCords}