import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import SiteLogoCropTool from './SiteLogoCropTool'
import { connect } from 'react-redux';
import {updateFiles } from '../../ducks';
import axios from 'axios';
import {NotificationManager} from 'react-notifications';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const actions = {
  updateFiles
}
// import './index.css';

// export class BootBox extends React.Component {
// }

class CropLogoModal extends Component {

  state = {
    croppedURL: null
  }

  updateCropUrl = (data) => {
    this.setState({croppedURL: data})
  }

  save = () => {

    let that=this;
    let description = "site_logo_cropped_tmp";
    let key = "site_id_fk";
    const formData = new FormData()
    formData.append('fileupload', that.state.croppedURL, that.props.user.client+"_croppedpic.jpg");
    formData.append('description', description);
    formData.append('key', key);

    axios.post("/api/upload_file", formData, {
    }).then(res => {
      that.setState({profile_pic_path: "/"+res.data.path});
      that.props.getFile(res.data);
      that.props.onNoClick();
    }).catch(error => {
        NotificationManager.error('Please choose a smaller sized file', 'File too large to upload');
    });

  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
    //this.setState({croppedImage: croppedImage })
}

  render() {
  if (this.props.image === undefined)
  return null;

  return (
    <Modal className="bootbox-modal" show={this.props.show} onHide={this.props.onClose}>
      <Modal.Body>
        <SiteLogoCropTool image={this.props.image} save={this.state.save} updateCropUrl={this.updateCropUrl}  />
      </Modal.Body>
      <Modal.Footer className="bootbox-modal-footer">
        <Button className="btn btn-secondary" onClick={() => this.props.onNoClick()}>
            {this.props.cancelText ? this.props.cancelText : "No"}
        </Button>
        <Button className="btn btn-success" onClick={() => this.save()}>
            {this.props.confirmText ? this.props.confirmText : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
}

export default connect(mapStateToProps, actions)(CropLogoModal);
