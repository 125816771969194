import React, { Component } from "react";
import { connect } from "react-redux";
import { api } from "../../_helpers";
import { Filters, SortBy } from "./_shared.jsx";
import _ from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BrowseList from "./BrowseList";
import BrowseMap from "./BrowseMap";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    filters: state.filter,
  };
};

class OpportunityBrowse extends Component {
  state = {
    sort: "distance",
    view: "list",
    applications: [],
  };

  componentDidMount() {
    this.getOpportunities();
    this.getMyApplications();
  }

  componentDidUpdate(prevProps) {
    //runs when the store updates the filter
    if (prevProps.filters !== this.props.filters) {
      this.getOpportunities();
    }
  }

  changeView = (view) => {
    this.setState({ view });
  };

  getMyApplications = () => {
    api({
      method: "get",
      url: "/api/getMyApplications",
    }).then((response) => {
      console.log("get my applications:", response);
      if (response.data) {
        // remove Rescinded applications
        this.setState({ applications: response.data });
      } else {
        console.log("application error");
      }
    });
  };

  getOpportunities = () => {
    api({
      method: "get",
      url: "/api/getOpportunities",
      params: { filter: this.props.filters },
    }).then((response) => {
      console.log("get new opportunities:", response);
      if (response.data) {
        var filtered = response.data.filter((d, index, arr) => {
          let dis = 0;
          if (d.opportunity_type !== "Virtual") dis = this.calculateDistance(d.location.lat, d.location.lon);

          d.distance = dis;

          return this.props.filters.radius === "" || this.props.filters.radius === "No" || dis <= this.props.filters.radius;
        });
        let sorted = _.sortBy(filtered, (o) => {
          return o[this.state.sort];
        });
        this.setState({ opportunities: sorted });
      } else {
        console.log("get opportunity error");
      }
    });
  };

  handleSortChange = (event) => {
    this.setState({ sort: event.target.value });
    let sorted = [];
    if (event.target.value.indexOf(".") >= 0) {
      let sort = event.target.value.split(".");
      sorted = _.sortBy(this.state.opportunities, function (o) {
        return o[sort[0]][sort[1]];
      });
    } else {
      sorted = _.sortBy(this.state.opportunities, function (o) {
        return o[event.target.value];
      });
    }

    this.setState({ opportunities: sorted });
  };

  calculateDistance = (olat, olon) => {
    var rad = function (x) {
      return (x * Math.PI) / 180;
    };
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(olat - this.props.user.location.lat);
    var dLong = rad(olon - this.props.user.location.lon);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(this.props.user.location.lat)) * Math.cos(rad(olat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c * 0.000621371192; //convert to miles
    return d.toFixed(2);
  };

  render() {
    const opportunities = this.state.opportunities;
    const user = this.props.user;

    if (opportunities == null || user == null) {
      return null;
    } else {
      opportunities.map((opp) =>
        _.find(this.state.applications, function (app) {
          if (app.opportunity_id_fk._id === opp._id && app.status !== "Rescinded") opp.application = app;
        })
      );
    }

    //let app = "";

    return (
      <div className="side-page">
        <div className="basic_form">
          <div className="page_header">
            <div className="row">
              <div className="col-sm-9">
                <div>
                  <h1 className="bigger">Browse Opportunities</h1>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="linkHolder" onClick={() => this.changeView("list")}>
                  <FontAwesomeIcon icon={["fas", "list"]} />
                </div>
                <div className="linkHolder" onClick={() => this.changeView("map")}>
                  <FontAwesomeIcon icon={["fas", "map"]} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Filters focusAreas={this.props.user.focus_areas} />
            </div>

            {opportunities.length > 0 ? (
              <div className="col-md-8 table-responsive-sm">
                <SortBy onChange={this.handleSortChange} sort={this.state.sort} />
                {this.state.view === "list" ? <BrowseList opportunities={opportunities} /> : <BrowseMap user={this.props.user} opportunities={opportunities} />}
              </div>
            ) : (
              <div className="mobilePadding">"No Opportunites match your filters"</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(OpportunityBrowse);
