import React, { Component} from "react";
import {NotificationManager} from 'react-notifications';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import axios from 'axios';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default class SignUp extends Component {

  state = {
    email: '',
    password: '',
    password2: '',
    first_name: '',
    last_name: '',
    isError: false,
    helperText: '',
    type: '',
    business_name: '',
    showBusinessName: false,
    client: {}
  }

  goBack = e => {
    this.props.swapComponent(true);
  }

  componentDidMount(){
    this.setState({type: this.props.accountType});
    if (this.props.accountType === "Business") {
      //this.setState({profile: {business_name: ""}});
      this.setState({showBusinessName: true});
    }
    this.setState({client: this.props.client});
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  handlePwChange = event => {
    this.setState({ isError : false });
    this.setState({ helperText : '' });
    this.setState({ [event.target.name] : event.target.value });
  };

  registerUser = (e) => {
    e.preventDefault();

    if (this.state.password === this.state.password2) {
      axios({
        method: 'post',
        url: '/auth/signup',
        data: {
          client: this.state.client._id,
          email: this.state.email,
          password: this.state.password,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          business_name: this.state.business_name,
          type : this.state.type,
          safe: true
        }
      }).then(
        function(response) {
          if (response.data.message === "success"){
            document.location = "/profile";
          }
          else {
            NotificationManager.error('This user already exists.', 'Error');
            let newstate = {
              email: '',
              password: '',
              password2: '',
              first_name: '',
              last_name: '',
              isError: false,
              helperText: '',
              business_name: ''
            }
            this.setState(newstate);
          }
        }.bind(this));
      /*Accounts.createUser(
      {
        email: this.state.email,
        password: this.state.password,
        role : this.state.role.toLowerCase(),
        profile : profile
      }, (error) => {
        if (!error) {
          this.props.history.push("/profile/"+this.state.role);
        }
        console.log (error)
      })*/
    }
    else {
      this.setState({ isError : true });
      this.setState({ helperText : 'Passwords Must Match' });
    }
  }
  
  nevermind = () => {
    this.props.setClient(null);
    this.goBack()
  }

  render() {
    return (
        <Paper className="container_accounts mx-auto" elevation={1}>
          <h4><FontAwesomeIcon onClick={this.goBack} icon={["fas", "caret-circle-left"]}/> Create {this.props.accountType} Account <span className="client-title">{this.state.client.full_name} <span onClick={this.nevermind}>[change]</span></span></h4>
          <form onSubmit={this.registerUser}>
            <TextField
              className="inputs"
              label="Email"
              placeholder="Email"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
            />

          {this.state.showBusinessName ? (
            <TextField
              error={this.state.isError}
              className="inputs"
              label="Business Name"
              placeholder="Business Name"
              type="text"
              name="business_name"
              value={this.state.business_name}
              onChange={this.handleChange}
            />
            ) : (
              <>
              <TextField
                error={this.state.isError}
                className="inputs"
                label="First Name"
                placeholder="First Name"
                type="text"
                name="first_name"
                value={this.state.first_name}
                onChange={this.handleChange}
              />
              <TextField
                error={this.state.isError}
                className="inputs"
                label="Last Name"
                placeholder="Last Name"
                type="text"
                name="last_name"
                value={this.state.last_name}
                onChange={this.handleChange}
              />
              </>
            )}

            <TextField
              error={this.state.isError}
              className="inputs"
              label="Password"
              placeholder="Password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handlePwChange}
            />

            <TextField
              error={this.state.isError}
              className="inputs"
              label="Confirm Password"
              placeholder="Confirm Password"
              type="password"
              name="password2"
              value={this.state.password2}
              onChange={this.handlePwChange}
              helperText={this.state.helperText}
            />

            <br />
            <Button type="submit" variant="contained" color="primary" className="btn_action">
              Create {this.props.accountType} Account
            </Button>
            <br />
            <Link to='/login' className="switch-pages">Already Have An Account?</Link>
          </form>
        </Paper>
    )
  }
}
