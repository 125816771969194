import React, { Component} from "react";
import moment from 'moment';

export default class Project_HoursLog extends Component {

  render() {

    return (
      <div>
      <h3>Hours/Action Log</h3>
      {  this.props.project.hours.reverse().map(hour => (
        <div className="hour-log" key={hour._id}>
          <div className="hour-log-date">
            <div className="hour-log-date-cnt">{moment(hour.start_date).format("MM/DD/YYYY")}</div>
          </div>
          <div className="hour-log-flex">
            <div className="hour-log-hours">{hour.total_hours}</div>
            <div className="hour-log-log" dangerouslySetInnerHTML={{__html:hour.text_log}}></div>
          </div>
        </div>
      )) }
      </div>

    )
  }
}
