import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    logged_in: state.auth.isAuthenticated
  };
};

class ConnectedUnapprovedProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };

  }

  render() {
    if (this.props == null) {
        return null
      }

    return (
        <div className="side-page">
          <h2>Your Account is not approved</h2>
          Please contact the site administrator if you feel this is in error.
      </div>
    )
  }

}

const unapprovedProfile = connect(mapStateToProps)(ConnectedUnapprovedProfile);
export default withRouter(unapprovedProfile);
