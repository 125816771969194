import React, { Component } from 'react';
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NotificationManager} from 'react-notifications';

import Button from '@material-ui/core/Button';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {RTEHelper} from '../../_formhelpers'

export default class ReflectionQuestion extends Component {

  constructor(props) {
    super(props);

    this.state = {
      question_answer : this.props.reflection.answer_text,
      reflection_id : this.props.reflection._id,
      panel_name : "panel" + this.props.reflection.ordinal,
      showClass : 'reflection_hide_rte',
      // status : this.props.reflection.status,
    }

    this.handleRTEChange = this.handleRTEChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      showClass : this.props.expanded === this.state.panel_name ? 'row reflection_show_rte' : 'row reflection_hide_rte'
    });
  }

  // componentDidUpdate()
  //this used to be componentillreceive props which is deprecated
  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded){
    this.setState({
      showClass : this.props.expanded === this.state.panel_name ? 'row reflection_show_rte' : 'row reflection_hide_rte'
    });
  }
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  handleRTEChange(field, value) {
    this.setState({ [field] : value.toString("html") });
  };

  validate = () => {
    let is_valid = this.refs.form.isFormValid(false);

    if (!is_valid)
      NotificationManager.error('You have not completely filled out the form.', 'Error');

    return is_valid;
  }

  saveReflectionAnswer(e) {
    e.preventDefault();

    if (this.state.question_answer === "")
      NotificationManager.error('You must provide a reponse', 'Answer Is Empty');
    else {
    let jsonData = {
      project_id : this.props.projectID,
      _id : this.props.reflection._id,
      answer_text : this.state.question_answer,
      status : "complete"
    }

    let that = this;
    axios({
      method: 'post',
      url: '/api/project_saveReflectionQuestion',
      data: {
        jsonData
      }
    }).then(function (response) {
      NotificationManager.success('Answer successfully saved.', 'Answer Saved');
      that.props.updateReflectionQuestion(jsonData._id);
      // that.setState({ status : 'complete' });
    });
  }

  }

  render () {
    const expanded = this.props.expanded;
    const reflection = this.props.reflection;

    let icon = '';
    switch(this.props.reflection.status) {
      case 'unlocked':
        icon = 'lock-open-alt';
        break;
      case 'locked':
        icon = 'lock-alt';
        break;
      case 'complete':
        icon = 'check-circle';
        break;
      default:
        break;
    }

    return (
      <div className="accordion_panel">
          <Accordion expanded={expanded === this.state.panel_name} onChange={this.props.swapPanel(this.state.panel_name, reflection.status)}>
            <AccordionSummary className="header" expandIcon={<ExpandMoreIcon />}>
              <FontAwesomeIcon icon={["fal", icon]} className="access_state"/>
              <Typography className="heading">Q{reflection.ordinal}</Typography>
            </AccordionSummary>
            <AccordionDetails className="cancelFlexBox">
              <div className="row">
                {reflection.question_text}
              </div>

              <div className={this.state.showClass}>
                <RTEHelper
                    name="question_answer"
                    onChange={this.handleRTEChange}
                    value={this.state.question_answer}
                />

                {this.props.approval_state !== 2 ? (
                  <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={this.saveReflectionAnswer.bind(this)}>
                    Save Answer
                  </Button>
                ) : (null)}

              </div>

            </AccordionDetails>
          </Accordion>
      </div>

    )
  }
}
