import React, { Component } from 'react';
import {SingleSelect} from '../../_formhelpers';

export default class BusinessEvaluation_Question extends Component {

  state = {
    question_text : this.props.question.question_text,
    rating : this.props.question.rating,
  }

  handleChange = event => {
    // console.log("this is:", event.target.value)
    this.setState({ rating : event.target.value }, this.props.updateQuestionRating(this.props.question.evaluation_question_id, event.target.value));
  };


  render() {
    let eval_options = [
      {value : "Not Applicable", label : "Not Applicable"},
      {value : "Ineffective", label : "Ineffective"},
      {value : "Needs Improvement", label : "Needs Improvement"},
      {value : "Effective", label : "Effective"},
      {value : "Highly Effective", label : "Highly Effective"},
    ]

    return (
      <div>
        <p>{this.props.question.question_text}</p>

        <SingleSelect
          label="Rating" name="rating"
          onChange={this.handleChange}
          value={this.state.rating}
          options={eval_options}
        />

      </div>
    )
  }
}
