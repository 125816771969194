import React, { Component } from 'react';
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NotificationManager} from 'react-notifications';
import Button from '@material-ui/core/Button';
import ld from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Icons } from './icons';

export default class ProjectFile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      page : "final_project",
      project : null,
      fileupload: ''
    }
  }

  componentDidMount() {
    this.makeCalls();
  }

  makeCalls = () => {
    var that = this;
    axios({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.id,
      }
    }).then(function (response) {
      let rawHours = response.data.hours;
      response.data.hours = ld.orderBy(rawHours, 'start_date', 'asc')

      that.setState(state => ((state.project = response.data, state)));
    });
  }

  clickInput = event => {
    document.getElementById("fileupload").click();
  }

  handleFileUpload = e => {
    e.preventDefault();
    this.setState({disabled: true})
    let jsonData = {
      project_id : this.props.match.params.id,
    }
    let that=this;

    const formData = new FormData()
    formData.append('fileupload', e.target.files[0]);
    formData.append('description', 'student_project_file');
    formData.append('no_delete', true);
    axios.post("/api/upload_file", formData, {
    }).then(res => {
      console.log(res)
      this.setState({disabled: false})
      jsonData.file_id = res.data._id;
      NotificationManager.success('File Successfully Uploaded', 'Successfull');
      axios.post("/api/project_saveFileLink", jsonData, {
      }).then(res => {
        that.makeCalls()
      });

    }).catch(error => {
        NotificationManager.error('Please choose a smaller sized file', 'File too large to upload');
    });
  };

  deleteFile = (file_id) => {
    let that = this;
    let jsonData = {
      file_id  : file_id,
      project_id : this.state.project._id,
    };
    axios.post("/api/delete_file", jsonData, {}).then(res => {
      axios.post("/api/project_removeFileLink", jsonData, {}).then(res => {
        that.makeCalls()
      })
    })

  }

  render() {
    const project = this.state.project;

    if (project == null) {
      return null
    }

    let file_upload = null
    if (typeof project.final_project.files !== 'undefined'){
      file_upload = project.final_project.files.length;
    }

    return (


      <div className="side-page">
        <div className="basic_form">

          <div className="page_header">
            <div className="row">
              <div className="col-md-10">
                <div>
                  <h1 className="bigger">{this.state.project.opportunity_id_fk.opportunity_title}</h1>
                </div>
              </div>
              <div className="col-md-2 apply_container">
                <Icons project={this.props.match.params.id}/>
              </div>
            </div>
          </div>
          <div className="sub-header">Final Project</div>

          <div className="row">
            <div className="col-md-12 table-responsive-sm d-none d-sm-block">

            {(file_upload === null || file_upload === 0) ? (
              <div>Please click below to upload a final project file.</div>
            ) : (
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th width="65%">Uploaded File(s)</th>
                    <th width="20%">Date Uploaded</th>
                    <th width="15%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {project.final_project.files.map(file => (
                    <tr key={file._id}>
                      <td>{file.name}</td>
                      <td>{moment(file.createdAt).format("MM/DD/YYYY")}</td>
                      <td className="table-actions">
                        <a href={"../../" + file.path} target="_blank" rel="noopener noreferrer" title="View File"><i className="fal fa-eye"></i></a>
                        <Link to={'#'} onClick={() => {this.deleteFile(file._id)}} title="Delete File"><i className="fal fa-trash-alt"></i></Link>
                      </td>
                    </tr>

                  ))}
                </tbody>
              </table>
            )}

            </div>

            <div className="col-12 table-responsive d-block d-sm-none" style={{marginBottom: "10px"}}>
            {project.final_project.files.map(file => (

              <div key={file._id} className="mobile_application_opp_name">
                <div>{file.name}</div>
                <div className="break"></div>
                <div>Uploaded On: {moment(file.createdAt).format("MM/DD/YYYY")}</div>
                <div className="break"></div>
                <div className="mobile_application_buttons">
                  <a href={"../../" + file.path} target="_blank" rel="noopener noreferrer" title="View File"><Button variant="contained" color="default" className="btn_action info">
                    <i className="fal fa-eye"></i> Preview
                  </Button></a>

                  <Button variant="contained" color="default" className="btn_action danger" onClick={() => {this.deleteFile(file._id)}}>
                    <i className="fal fa-trash-alt"></i> Delete
                  </Button>
                </div>

              </div>
            ))}
            </div>

            <div className="col-md-12 upload_project_file_button">
              <Button variant="contained" color="default" disabled={this.state.disabled} onClick={this.clickInput} className="upload_files_button">
                {(file_upload === null || file_upload === 0) ? ("Upload Final Project \u00a0"):("Upload Additional Files \u00a0")}
                <FontAwesomeIcon icon={["far", "upload"]}/>
              </Button>

              <input
                name="fileupload"
                id="fileupload"
                label="Final Project"
                onChange={this.handleFileUpload}
                type="file"
                style={{display:"none"}}
              />
            </div>

          </div>

        </div>
      </div>










    )
  }
}
