import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { AdminNav, BusinessNav, StudentNav, OutsideNav, InnerNav } from './RoleNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {setUser} from '../../ducks';

const actions = {
  setUser
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    is_masquerade : state.auth.isMasquerade,
    admin_id : state.auth.masquerade_user,
    isAuthenticated: state.auth.isAuthenticated,
    settings: state.settings
  };
};

class ConnectedHeader extends Component {

  constructor(props) {
    super(props);
    this.closeMenu = this.closeMenu.bind(this);
  }

  closeMenu(e) {
    //const el = findDOMNode(this.refs.toggleMenu);
    //$(el).collapse('hide')
  }

  logout = () => {
    //localStorage.removeItem('admintern_admin_id');
    //Meteor.logout();
    axios({
      method: 'get',
      url: '/auth/logout',
    }).then(function (response) {
      console.log(response);
      this.props.history.push("/");
    });
    //this.props.history.push("/login");
  }

  returnToAdmin = () => {
    let setUser = this.props.setUser;
    let adminUser_id = this.props.admin_id;
    axios({
      method: 'post',
      url: '/auth/masquerade',
      data : {
        id: adminUser_id,
        password: 'yyz'
      }
    }).then(function (response) {
      let payload = {
        user : response.data.user,
        is_masquerade : "false",
        admin_id : "0"
      }

      setUser(payload);


      // another server call here to set cookies on the server w/masqerade details
      axios({
        method: 'post',
        url: '/api/user/end_masquerade',
        data : {
          adminUser_id: adminUser_id,
        }
      }).then(results => {
        // that.props.history.push("/admin/dashboard");
        document.location = '/default';
      })

      // NotificationManager.success('Application successfully submitted.', 'Application Successfull');

    });
  }

  render() {

    if (this.props.user == null) {
      return null
    }

    let obj = this.props.settings.logo;

    let is_mask = this.props.is_masquerade === "true" ? "masquerade" : "";
    let is_dev = window.location.href.indexOf("phocusdesigns.com") > 0 ? "dev_head" : "bg_dark";

    return (
      <header>

        <nav className={`navbar navbar-expand-lg fixed-top navbar-dark ${is_mask} ${is_dev}`}>
          {<Link to={'/default'} className="nav-link lonelyLink" onClick={this.closeMenu.bind(this)}>
              <img src={obj.path} height="80px" alt="Logo" />
          </Link>
          }

          {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}

          <div className="collapse navbar-collapse" id="navbarSupportedContent" ref="toggleMenu">
            {window.location.href.indexOf("phocusdesigns.com") > 0 ? <span className="dev_alert">THIS IS THE DEV SITE</span> : ""}
            {window.location.href.indexOf("localhost") > 0 ? <span className="local_alert">YOU ARE WORKING LOCALLY</span> : ""}
            <ul className="navbar-nav ml-auto">

              {this.props.user.role === 'Admin' || this.props.user.role === "Teacher" ? (
                <AdminNav closeMenu={this.closeMenu} />
              ) : (
                this.props.user.role === 'Business' ? (
                  <BusinessNav closeMenu={this.closeMenu} />
                ) : (
                  this.props.user.role === 'Student' ? (
                    <StudentNav closeMenu={this.closeMenu} />
                ) : (
                  <OutsideNav closeMenu={this.closeMenu} />
                )
              )
            )}

              {this.props.user._id ? (
                <InnerNav closeMenu={this.closeMenu} logout={this.logout} masquerade={is_mask} />
              ) : (
                <li className="nav-item more-room" onClick={this.closeMenu.bind(this)}>
                  <Link className="nav-link" to='/login'><FontAwesomeIcon icon={["far", "sign-in-alt"]}/> Login</Link>
                </li>
              )}

            </ul>

            {is_mask ? (
              <div title="Exit Masquerade" className="masquerade_icon" onClick={(e) => this.returnToAdmin()}><FontAwesomeIcon icon={["fal", "user-secret"]} /></div>
            ) : ("")}

          </div>
        </nav>
        {is_mask ? (
        <div className="masquerade_name">{this.props.user.role}: {this.props.user.role === "Business" ? <> {this.props.user.business_name} </> : <>{this.props.user.first_name} {this.props.user.last_name} </>}</div>
      ) : null }
      </header>
    )
  }
}

const Header = connect(mapStateToProps, actions)(ConnectedHeader);
export default withRouter(Header);
