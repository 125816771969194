import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { store } from "./stores/admintern.js";
//import injectTapEventPlugin from 'react-tap-event-plugin';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import 'react-notifications/lib/notifications.css';
import './less/main.less';
import _ from 'underscore';

import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.js';
//import $ from 'jquery';
//import Popper from 'popper.js';


window._ = _;

//injectTapEventPlugin();

const MainApplication = () => (
  
	<BrowserRouter>
	<Provider store={store}>
	  <App />
	</Provider>
	</BrowserRouter>
  
);

const root = document.getElementById('root')

if (root !== null) {
  ReactDOM.render(<MainApplication /> , root)
}

