import React, { Component } from 'react';
import axios from 'axios'
import ld from 'lodash';

import { Icons } from './icons';
import ProjectSummary from './ProjectSummary.jsx';

export default class Summary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page : "reflections",
      project : null,
    }
  }

  componentDidMount() {
    this.makeCalls();
  }

  makeCalls = () => {
    var that = this;
    axios({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.id,
      }
    }).then(function (response) {
      let rawHours = response.data.hours;
      response.data.hours = ld.orderBy(rawHours, 'start_date', 'asc')

      that.setState(state => ((state.project = response.data, state)));
    });
  }

  render() {
    const project = this.state.project;

    if (project == null) {
      return null
    }

    return (
      <div className="side-page">
        <div className="basic_form">

          <div className="page_header">
            <div className="row">
              <div className="col-md-10">
                <div>
                  <h1 className="bigger">{this.state.project.opportunity_id_fk.opportunity_title}</h1>
                </div>
              </div>
              <div className="col-md-2 apply_container">
                <Icons project={this.props.match.params.id}/>
              </div>
            </div>
          </div>
          <div className="sub-header">Final Summary</div>
          <div className="row">

            <div className="col-md-10 offset-md-1">
              <ProjectSummary projectId={this.props.match.params.id} summary={project.final_summary} />
            </div>

          </div>
        </div>
      </div>
    )
  }
}
