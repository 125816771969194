import React, { Component } from 'react';
import AccountType from './AccountType'
import SignUp from './SignUp'


export default class AccountWrapper extends Component {

constructor(props) {
  super(props);
  this.state = {
    type: true,
    accountType: "",
    client: null
  };

}

  swapComponent = (newComponent) => {
    this.setState({ type : newComponent });
  }

  swapAccountType = newType => {
    this.setState({ accountType: newType });
  }
  
  setClient = client => {
    this.setState({client: client});
  }

  render() {

    return (
      <div className="login-hero">
          {this.state.type ? (
                <AccountType
                  swapComponent={this.swapComponent}
                  swapAccountType={this.swapAccountType}
                  setClient={this.setClient}
                   />
            ) : (
              <SignUp
                accountType={this.state.accountType}
                swapComponent={this.swapComponent}
                history={this.props.history}
                client={this.state.client}
                setClient={this.setClient}
                />
            )}
      </div>
    )
  }
}
