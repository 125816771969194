import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {api} from "../../_helpers"
import {RTEHelper} from '../../_formhelpers'
import moment from 'moment';

import {
  DatePicker,
} from '@material-ui/pickers';


export default class HoursLogAdd extends Component {

  constructor(props) {
    super(props);

    this.state = {
      start_date : moment().format(),
      end_date : moment().format(),
      total_hours : 1,
      text_log : '',
      business_approved : false,
      error: false
    }

    this.handleRTEChange = this.handleRTEChange.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('hoursStartBefore', (value) => {
      if (!moment(value).isSameOrBefore(this.state.end_date))
        return false;
      else
        return true;
    });

    ValidatorForm.addValidationRule('hoursStopAfter', (value) => {
      if (!moment(value).isSameOrAfter(this.state.start_date))
        return false;
      else
        return true;
    });

    ValidatorForm.addValidationRule('nonZeroHours', (value) => {
      if (this.state.total_hours <= 0 || this.state.total_hours === "e"){
        this.setState({error: true})
        return false
      }
      else {
        this.setState({error: false})
        return true;
      }
    });
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  handleDateChange = (date, name) => {
    //console.log("date d", date._d);
    this.setState({ [name]: date._d });
  };

  handleRTEChange(field, value) {
    this.setState({ [field] : value.toString("html") });
  };

  validate = () => {
    let is_valid = this.refs.form.isFormValid(false);

    if (!is_valid)
      NotificationManager.error('You have not completely filled out the form.', 'Error');

    return is_valid;
  }

  saveHoursLogEntry = (e) => {
    e.preventDefault();
    if (!this.validate())
      return;

      if (this.state.text_log === "" || this.state.text_log === "<p><br></p>"){
        NotificationManager.error('You must provide an activity log', 'Activity Log Is Empty');
        return
      }  
      else if (this.state.error){
        NotificationManager.error('Hour log must be a number greater than 0', 'Error on Hour Log');
        return
      }
      else
      {
    let jsonData = {
      data : this.state,
      project_id : this.props.projectId
    }

    console.log (jsonData);

    api({
      method: 'post',
      url: '/api/project_createHoursLogEntry',
      data: {
        jsonData
      }
    }).then((response) => {
      NotificationManager.success('Hours successfully saved.', 'Hours Saved');
      this.props.saveCallback();
    });

    this.setState({text_log : '', total_hours: 1, error: false});
  }
  }

  render () {
    return (
      <div>
        <ValidatorForm
          ref="form"
          id="hoursForm"
          onSubmit={this.validate}
          onError={errors => console.log(errors)}
          instantValidate={true}
        >

        <div className="row>">
          <div className="col-5" style={{marginTop: '12px', display: 'inline-block', verticalAlign: 'text-top'}}>
            <DatePicker
              className="inputs" required={true} name="start_date" inputVariant="outlined"
              label="Start"
              format="MM/DD/YYYY"
              onChange={(date) => this.handleDateChange(date, 'start_date')}
              //onBlur={this.handleChange}
              value={this.state.start_date}
              validators={['hoursStartBefore',"required"]}
              placeholder="MM/DD/YYYY"
              //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
            autoOk={true} animateYearScrolling={false}
            />
          </div>
          <div className="col-5 offset-1" style={{marginTop: '12px', display: 'inline-block', verticalAlign: 'text-top'}}>
              <DatePicker
                className="inputs" required={true} name="end_date" inputVariant="outlined"
                label="Stop"
                format="MM/DD/YYYY"
                onChange={(date) => this.handleDateChange(date, 'end_date')}
                //onBlur={this.handleChange}
                value={this.state.end_date}
                validators={['hoursStopAfter',"required"]}
                placeholder="MM/DD/YYYY"
                //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                keyboard autoOk={true} disableOpenOnEnter animateYearScrolling={false}
              />
          </div>
        </div>

        <TextValidator
          name="total_hours" label="Total Hours" variant="outlined" type="number"
          onChange={this.handleChange} value={this.state.total_hours}
          className="inputs" required={true} validators={['nonZeroHours', 'required']} errorMessages={['hours must be greater than 0', 'this field is required']}
        />

        <RTEHelper
            label="Activity Log *"
            name="text_log"
            onChange={this.handleRTEChange}
            value={this.state.text_log}
        />

      </ValidatorForm>

      <div className="mobile-center-button">
      <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={this.saveHoursLogEntry}>
        Add Hours
      </Button>
      </div>

      </div>

    )
  }
}
