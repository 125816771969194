import React, { Component } from 'react';
import axios from 'axios'
import {api} from "../../_helpers"
import { connect } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import BootBox from '../Shared/BootBox.jsx';

//import  OpenMap  from '../Layout/Map';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

class ViewApplication extends Component {

  state = {
    db: {},
    areyousure: false
  }

  componentDidMount() {
    this.makeCalls();
  }

  getApplicationById = () => {
    return axios({
      method: 'get',
      url: '/api/getApplicationById',
      params: {
        app_id : this.props.match.params.id,
      }
    });
  }

  recind = () => {
    this.setState({areyousure: true});
  }

  handleClose = () => {
    this.setState({areyousure: false});
  }

  confirmRecind = () => {
    this.updateApplicationStatus(this.state.db.application._id, "Rescinded");
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getApplicationById() ])
    .then(axios.spread(function (app) {
      that.setState(state => ((state.db.application = app.data, state)));
    }));
  }

  updateApplicationStatus(id, status) {
    /* note for tomorrow: check the status here to see if recinded and throw a "are you sure" */

    let json = {
      application_id : id,
      status : status
    }

    api({
      method: 'post',
      url: '/api/updateApplicationStatus',
      data: {
        json
      }
    }).then((response) => {
      NotificationManager.success('Application successfully ' + status.toLowerCase() + '.', 'Application Status Change Successfull');
      let state_update = {...this.state};
      state_update.db.application.status = status;
      this.setState(state_update);
      if (this.props.user.role === "Business")
        this.props.history.push("/opportunity/applicants/"+this.state.db.application.opportunity_id_fk._id);
      else
        this.props.history.push("/default");
    });

  }

  business_buttons = () => {
    let app = this.state.db.application;

    let button_return;
    if (app.status === "New") {
      button_return = (<React.Fragment>
        <Button type="submit" variant="contained" color="default" className="btn_action danger" onClick={(e) => this.updateApplicationStatus(app._id, "Declined")}>
          Decline
        </Button>

        <Button type="submit" variant="contained" color="default" className="btn_action" onClick={(e) => this.updateApplicationStatus(app._id, "Interview Scheduled")}>
          Interview Scheduled
        </Button>

        <Button type="submit" variant="contained" color="default" className="btn_action" onClick={(e) => this.updateApplicationStatus(app._id, "Offered")}>
          Offer Position
        </Button>
        </React.Fragment>)
    }
    else if (app.status === "Interview Scheduled") {
      button_return = (<React.Fragment>

        <Button type="submit" variant="contained" color="default" className="btn_action danger" onClick={(e) => this.updateApplicationStatus(app._id, "Declined")}>
          Decline
        </Button>

        <Button type="submit" variant="contained" color="default" className="btn_action" onClick={(e) => this.updateApplicationStatus(app._id, "Offered")}>
          Offer Position
        </Button>
        </React.Fragment>)
    }

     return(
       button_return
     )
   }

   student_buttons = () => {
     let app = this.state.db.application;

     let button_return;
     if (app.status === "New" || app.status === "Interview Scheduled") {
       button_return = (<React.Fragment>
         <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={(e) => this.recind()}>
           Rescind
         </Button>
         </React.Fragment>)
     }
     else if (app.status === "Offered") {
       button_return = (<React.Fragment>
         <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={(e) => this.updateApplicationStatus(app._id, "Accepted")}>
           Accept
         </Button>

         <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={(e) => this.updateApplicationStatus(app._id, "Declined")}>
           Decline
         </Button>
         </React.Fragment>)
     }

      return(
        button_return
      )

   }

   download_resume = () => {
     document.location = "/"+this.state.db.application.student_id_fk.resume_file_id_fk.path;
   }

  render() {
    const application = this.state.db.application;

    if (typeof(application) === 'undefined')
      return null;

    return (
      <div className="side-page">
        <div className="page_header">

          <div className="row">
            <div className="col-md-8">
              <h1>{application.student_id_fk.first_name} {application.student_id_fk.last_name}</h1>
              <h3>{application.opportunity_id_fk.opportunity_title}</h3>
            </div>
            <div className="col-md-4 apply_container">

              {(this.props.user.role === "Business") ? ( this.business_buttons() ) : (
                this.props.user.role === "Student" ? ( this.student_buttons() ) : (null)
              ) }

            </div>
          </div>

        </div>

        <div className="application_view">
          <div className="row top_section align-right">
            {/* I'm going to move this into flexbox and add status to this list */}
            {/*]<div className="offset-md-8 col-md-2"><ResumeLink student={app.student_id_fk} /></div>*/}
            <div className="col-md-2">
              <FontAwesomeIcon icon={["fal", "calendar-alt"]} className="big-icons" /> {moment(application.createdAt).format("MM/DD/YYYY")}
            </div>

            {typeof(application.student_id_fk.resume_file_id_fk) !== 'undefined' ? (
              <div className="col-md-3 fake-a-link" onClick={this.download_resume}>
                <FontAwesomeIcon icon={["fal", "file-user"]} className="big-icons" />
                View Resume
              </div>
            ) : (
              <div className="col-md-3">
                <FontAwesomeIcon icon={["fal", "file-user"]} className="big-icons" />
                  Resume Unavailable
              </div>
            )}

          </div>
        </div>

        <div>Student Cover Letter:</div>
        <div className="cover-letter" dangerouslySetInnerHTML={{__html: application.cover_letter}} />

        <div id="application_actions">

        </div>

        <BootBox
          message="Are you sure you want to recind your application?"
          show={this.state.areyousure}
          onYesClick={this.confirmRecind}
          onNoClick={this.handleClose}
          onClose={this.handleClose}
          cancelText="Cancel"
          confirmText="Recind"
        />

      </div>
    )
  }
}

export default connect(mapStateToProps)(ViewApplication);
