import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import {PhoneMask} from '../../_masks';
import axios from 'axios'
import {api} from "../../_helpers"
import {RTEHelper, SingleSelect} from '../../_formhelpers';

import moment from 'moment';

import _ from 'underscore';
import ld from 'lodash';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import BusinessEvaluationQuestion from './BusinessEvaluation_Question.jsx'

export default class BusinessEvaluation extends Component {

  state = {
    evaluation_date : moment().format(),
    supervisor : '',
    internship_facilitator : '',
    contact_phone : '',
    term : '',
    ratings : [],
    comments : '',
    discussed: false,
    db: {
      project : null,
      questions : null,
      shrink: {
        contact_phone: true,
      }
    },
  }

  componentDidMount() {
    this.makeCalls();
  }

  getInternData = () => {
    return axios({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.id,
      }
    });
  }

  getEvaluationQuestions = () => {
    return axios({
      method: 'get',
      url: '/api/getDefaultEvaluationQuestions'
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getInternData(), this.getEvaluationQuestions() ])
    .then(axios.spread(function (project, questions) {

      let ratings = [];
      _.each(questions.data, function (q) {
        let rating = q;
        rating.evaluation_question_id = q._id;
        rating.rating = "Effective";
        rating = _.omit(q, ['ordinal', '_id']);
        ratings.push(rating)
        // console.log(rating);
      })
      // console.log(ratings);

      that.setState(state => ((state.db.project =  project.data, state)));
      that.setState(state => ((state.ratings = ratings, state)));
    }));
  }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date._d });
  };

  handleChange = event => {
    // console.log("this is:", event.target.value)
    this.setState({ [event.target.name] : event.target.value });
  };

  handleMaskChange = event => {
    //let that = this;
    let name = event.target.name;
    if (event.target.value.length > 0)
        this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));

    this.setState({ [event.target.name] : event.target.value });
  };

  handleMaskFocus = event => {
    let name = event.target.name;
    this.setState(state => ((state.db.shrink[name] = true, state)));
  };

  handleMaskBlur = event => {
    let name = event.target.name;
    if (event.target.value.length > 0)
      this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));
  };
  
  toggle_discussed = () => {
    this.setState({discussed: !this.state.discussed})
  }

  handleRTEChange = (field, value) => {
    this.setState({ [field] : value.toString("html") });
  }

  buildTermOptions = () => {
    let current_year = parseInt(moment().format('YYYY'));

    let options = [
      {value : "Summer " + (current_year-1), label: "Summer " + (current_year-1)},
      {value : "Fall " + (current_year-1), label: "Fall " + (current_year-1)},
      {value : "Spring " + current_year, label: "Spring " + current_year},
      {value : "Summer " + current_year, label: "Summer " + current_year},
      {value : "Fall " + current_year, label: "Fall " + current_year},
      {value : "Spring " + (current_year+1), label: "Spring " + (current_year+1)}
    ]

    return options;
  }

  updateQuestionRating = (id, rating) => {
    let item = ld.find(this.state.ratings, { evaluation_question_id : id});
    item.rating = rating;
    this.setState();
  }

  formErrors = (errors) => {
    NotificationManager.error('Check Your Form for errors', 'Form Error');
  }

  save_evaluation = () => {
    let jsonData = ld.omit(this.state, ['db']);
    jsonData.project_id = this.props.match.params.id;

    console.log("+_+_+_+_+", jsonData);

    api({
      method: 'post',
      url: '/api/save_evaluation',
      data: {
        jsonData
      }
    }).then((response) => {
      NotificationManager.success('Evaluation successfully submitted.', 'Evaluation Save Successfull');
      this.props.history.push("/interns/viewall");
    });

  }

  render() {

    if (this.state.db.project == null)
      return null

    return (
      <div className="side-page">
        <div className="page_header"><h1>Business Evaluation</h1> </div>

        <div className="row">
          <div className="col-12 table-responsive-sm basic_form">


          <ValidatorForm
            ref="form"
            onSubmit={() => this.save_evaluation()}
            onError={errors => this.formErrors(errors)}
            instantValidate={true}
          >

            <div>
              <h3>Intern Evaluation Form</h3>
              <p>Students need two evaluations on file for their completed work-based learning. Please complete a work-based learning evaluation for your student intern at the mid-point and conclusion of the work-based learning. Comments are a required part of the evaluation.</p>
              <p>Trainee: {this.state.db.project.student_id_fk.first_name} {this.state.db.project.student_id_fk.last_name}</p>
              <p>Employed By: {this.state.db.project.business_id_fk.business_name}</p>

              <KeyboardDatePicker
                className="longinputs" required={true} name="evaluation_date" variant="outlined"
                label="Evaluation Date"
                format="MM/DD/YYYY"
                onChange={(date) => this.handleDateChange(date, 'evaluation_date')}
                onBlur={this.handleChange}
                value={this.state.evaluation_date}
                validators={["required"]}
                placeholder="MM/DD/YYYY"
                //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                keyboard autoOk={true} disableOpenOnEnter animateYearScrolling={false}
              />

              <TextValidator
                name="supervisor" label="Supervisor" variant="outlined"
                onChange={this.handleChange} value={this.state.supervisor}
                className="inputs" validators={['required']} errorMessages={['this field is required']}
              />

              <TextValidator
                name="internship_facilitator" label="Internship Facilitator" variant="outlined"
                onChange={this.handleChange} value={this.state.internship_facilitator}
                className="inputs" validators={['required']} errorMessages={['this field is required']}
              />

              <TextValidator
                name="contact_phone" label="Phone" variant="outlined"
                  onChange={this.handleMaskChange} value={this.state.contact_phone}
                  onFocus={this.handleMaskFocus}
                  onBlur={this.handleMaskBlur}
                  className="inputs" validators={['required']} errorMessages={['this field is required']}
                  placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
                  InputLabelProps={{ shrink: true }}
              />

              <SingleSelect
                label="Term" name="term"
                onChange={this.handleChange}
                value={this.state.term}
                options={this.buildTermOptions()}
              />


            </div>


            <hr />
            <h3>Performance on the Job</h3>
            <p>Highly Effective, Effective, Needs Improvement, Ineffective, Not applicable</p>

            <div>
              {this.state.ratings.map(question => (
                <BusinessEvaluationQuestion question={question} updateQuestionRating={this.updateQuestionRating} />
              ))}
            </div>

            <hr />
            <h3>Recomendations and Comments</h3>
            <p>Please write one or two commendations about your student and one or two recommendations for your student in relation to their work-based learning experience with you.</p>

            <RTEHelper
                label="Comments"
                name="comments"
                onChange={this.handleRTEChange}
                value={this.state.comments}
            />
            
            <hr />
            
            <Switch
              checked={this.state.discussed ? true : false}
              onChange={() => this.toggle_discussed()}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            /> <strong>I have discussed this evaluation with my intern/apprentice.</strong>
            
            <p></p>

            <Button type="submit" variant="contained" color="primary" className="btn_action mt-3">
              Save Evaluation
            </Button>


</ValidatorForm>
          </div>
        </div>
      </div>
    )
  }
}
