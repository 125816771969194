import axios from "axios"
import {NotificationManager} from 'react-notifications';

/*export function handleNestedChange(event, nestedItem ) {
  let nestedState = "this.state." + nestedItem;

  this.setState({
    [nestedItem] : Object.assign({}, eval(nestedState), {
      [event.target.name] : event.target.value,
    }),
  });
}*/

export const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };
  
export let api = axios.create({
  timeout: 100000,
});

/*api.interceptors.request.use(async (request) => {
  let req = request;
  return axios.get("/api/current-user").then((response) => {
    console.log("interceptor threw this response first", response)
    if (response.data.user){
      //console.log("request:", req)
      return req
    }
    else {
      console.log("the user is no longer logged in")
      document.location = "/login"
      return "error"
    }
  })
  }, (error) => {
  // whatever you want to do with the error
  console.log("there was an error:", error);
});*/

api.interceptors.response.use(async (response) => {
  if(response.status === 500) {
    console.log("a 500 was found what was the error?:", response)
    NotificationManager.error("Something went wrong", '500 ERROR');
    return Promise.reject(response);
  }
  else if(response.status === 401){
    console.log("a 401 was found")
    NotificationManager.error("Your user account is logged it, please log back in", '401 ERROR');
    //document.location = "/login"
    return Promise.reject(response);
  }
  else return response;
    
  }, (error) => {
  console.log("a different error  was found what was the error?:", error)
  NotificationManager.warning("Something went wrong", 'UNKNOWN ERROR');
  return Promise.reject(error);
});
