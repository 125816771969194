import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';


export default class QuestionForm extends Component {

  state = {
    question_text: "",
    default: false
  }

  componentDidMount() {
    if (this.props.user.role === "Super Admin")
      this.setState({default: true});
  }
  
  handleChange = event => {
    this.setState({ question_text : event.target.value });
  };
  

  createQuestion = (e) => {
    let json = this.state;
    let goBack = this.props.toggleBack;
    let link = this.props.api;
    let message = 'Question successfully created.';
    api({
        method: 'post',
        url: link,
        data: {
          json
        }
      }).then(function (response) {
        console.log("this is the response:", response)
        
          if (response.data === "success"){
            NotificationManager.success(message, 'Success');
            goBack(true);
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.createQuestion()}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        <TextValidator
        name="question_text" label="Question" variant="outlined"
        onChange={this.handleChange} value={this.state.question_text}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        
        <div className="mobile-center-button">
          <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
            Create Question
          </Button>

        </div>
        
       </ValidatorForm>
     )
   }
}
