import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios'
import OpportunityRow from './OpportunityRow';
import { MultiSelect, SingleSelect } from "../../_formhelpers";
import {updateFilter } from '../../ducks'
import Button from '@material-ui/core/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const actions = {
  updateFilter
}

const mapStateToProps = (state, props) => {
  return {
    filters: state.filter
  };
};

class ApplicantTable extends Component {

    render () {
        return (
          <table className="table table-striped table-hover">
            <thead className="thead-light">
              <tr>
                <th scope="col" width="49%">Student</th>
                <th scope="col" width="15%">Status</th>
                <th scope="col" width="18%">Application Date</th>
                <th scope="col" width="18%">Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map(d => (
                d.student_id_fk ?
                (<tr key={d._id}>
                  <td><Link to={'/application/view/' + d._id} title="View Application">{d.student_id_fk.first_name} {d.student_id_fk.last_name}</Link></td>
                  <td>{d.status}</td>
                  <td>{moment(d.createdAt).format("MM/DD/YYYY")}</td>
                  <td>{moment(d.updatedAt).format("MM/DD/YYYY")}</td>
                </tr>)
                : (
                  <tr>
                    <td colspan="4">There is an issue with this application please reach out to the administrator and provide id: {d._id}</td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        )
    }
};

class SortBy extends Component {

    render () {
        return (
          <div className="sort-container">
            <SingleSelect label="Sort" name="Sort" onChange={this.props.onChange} value={this.props.sort}
                  options={[{value: "distance", label: "Distance"},{value: "opportunity_title", label: "Opportunity Title"}
                ,{value: "business.business_name", label: "Business Name"},{value: "application_close", label: "Application Deadline"}]} />
          </div>
        )
    }
};

class OpportunityTable extends Component {
    render () {
        return (
          <table className="table table-striped table-hover">
            <thead className="thead-light">
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Dates</th>
                <th scope="col">Available Seats</th>
                <th scope="col">Applicants</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map(opp => (
                <OpportunityRow key={opp._id}
                  opportunity={opp}
                  deleteOpportunity={this.props.deleteOpportunity}
                  duplicateOpportunity={this.props.duplicateOpportunity} />
              ))}
            </tbody>
          </table>
        )
    }
};

class ConnectedFilters extends Component {

  state = {
    db: {},
    userFocusAreas: this.props.focusAreas,
    filter_show : false,
  }

  componentDidMount() {
    let that = this;
    axios({
      method: 'get',
      url: '/api/getFocusAreas',
    }).then(function (response) {
      console.log("get focusareas:", response);
      if (response.data){
        that.setState(state => ((state.db.focusAreas = response.data, state)));
      }
      else {
        console.log("focus area error");
      }
    });
  }

  handleChange = event => {
    //this.setState({ [event.target.name] : event.target.value });
    //this.setState(state => (state.filter[event.target.name] = event.target.value, state));
    //console.log("filters to the store:", this.state.filter);
    this.props.updateFilter({field: event.target.name, value: event.target.value});
    //this.reloadFilters();
  };

  addMyFAs = () => {
    this.props.updateFilter({field: "focusAreas", value: this.state.userFocusAreas});
  }

  swapFilterState = () => {
    this.setState({ filter_show : !this.state.filter_show});
  }

    render () {
        if (this.state.db.focusAreas == null || this.props.filters == null) {
          return null
        }

        let filter_show = this.state.filter_show ? 'open' : '';


        return (
          <div className="opportunity-search-filters">
            <div className="side-header">
              Filters

              <Button variant="contained" color="primary" className="btn_action btn_toggle_filters" onClick={this.swapFilterState}>
                  {this.state.filter_show ? "hide" : "show"}
                  {this.state.filter_show ? <FontAwesomeIcon icon={["fas", "angle-up"]}/> : <FontAwesomeIcon icon={["fas", "angle-down"]}/>}

              </Button>
            </div>

            <div className={"opportunity-search-filters-container " + filter_show}>
              <MultiSelect label="Focus Areas" name="focusAreas" onChange={this.handleChange}
                value={this.props.filters.focusAreas}
                options={this.state.db.focusAreas.map(fa =>
                {
                  return {value: fa.name, label: fa.name};
                })}
              />
              <SingleSelect label="Compensation" name="compensation" onChange={this.handleChange} value={this.props.filters.compensation}
                options={[{value: "Paid", label: "Paid"},{value: "Unpaid", label: "Unpaid"}
                ,{value: "Either", label: "Either"}]} />
              <SingleSelect label="Radius" name="radius" onChange={this.handleChange} value={this.props.filters.radius}
                options={[{value: 1, label: "1 Mile"},{value: 5, label: "5 Miles"},{value: 10, label: "10 Miles"}
                ,{value: 15, label: "15 Miles"},{value: 20, label: "20 Miles"},{value: "No", label: "No Limit"}]} />

          </div>

          </div>
        )
    }
};
const Filters = connect(mapStateToProps, actions)(ConnectedFilters);
export {ApplicantTable, OpportunityTable, Filters, SortBy}
