import React, { Component} from "react";
import { Map, Marker, TileLayer } from 'react-leaflet'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios'
import {NotificationManager} from 'react-notifications';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import _ from 'underscore';


class ViewMap extends Component {

  state = {
    lat: 0,
    lon: 0,
    pointlat: 0,
    pointlon: 0,
    zoom: 11,
  }
  
  save = () => {
    this.props.save(this.state.pointlat, this.state.pointlon)
  }
  
  componentDidMount () {
    this.setState({lat: this.props.location.lat, lon: this.props.location.lon, pointlat: this.props.location.lat, pointlon: this.props.location.lon })
  }
  
  componentDidUpdate (prevProps) {
    if (!_.isEqual(prevProps.location, this.props.location))
      this.setState({lat: this.props.location.lat, lon: this.props.location.lon, pointlat: this.props.location.lat, pointlon: this.props.location.lon })
  }
  
  moveMarker = (e) => {
    console.log("clicked:", e.latlng.lat, e.latlng.lng)
    this.setState({pointlat: e.latlng.lat, pointlon: e.latlng.lng})
  }
  

  render() {

    let icon = L.icon({
      iconSize: [26, 41], 
      iconAnchor: [13, 45],
     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
     iconUrl: require('leaflet/dist/images/marker-icon.png'),
     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
   })

    const position = [this.state.lat, this.state.lon]
    const point = [this.state.pointlat, this.state.pointlon]

    return (
      <Modal className="bootbox-modal" show={this.props.show} onHide={this.props.onClose} animation={false}>
          <Modal.Header>
            Your Current Location
          </Modal.Header>
          <Modal.Body>
            If your map is showing the wrong location, please click the map to move the marker to the correct location and save to overwrite.<br /><br />
            <Map center={position} zoom={this.state.zoom} onClick={this.moveMarker}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              <Marker position={point} icon={icon}>
                  
                </Marker>
              </Map>
          </Modal.Body>
          <Modal.Footer className="bootbox-modal-footer">
            <Button className="btn btn-secondary" onClick={this.props.onClose}>
                Cancel
            </Button>
            <Button className="btn btn-success" onClick={() => this.save()}>
                Save
            </Button>
          </Modal.Footer>
        </Modal>

      
    )
  }
}

class ClickToViewMap extends Component {
  
  state = {
    showModal: false,
    location: {lat: 0,lon:0}
  }
  
  onClose = () => {
    this.setState({showModal: false})
  }
  
  onOpen = () => {
    this.setState({showModal: true})
  }
  
  save = (lat, lon) => {
    let id = this.state.id;
    let onClose = this.onClose;
    let updateLocation = this.props.updateLocation;
    console.log("save location:", lat, lon)
    
    axios({
        method: 'post',
        url: '/api/updateLatLong',
        data: {
          collection: this.props.collection,
          lat: lat,
          lon: lon,
          id: id
        }
      }).then((response) => {
        if (response.data.errors === undefined  &&  response.data.name === undefined){
          NotificationManager.success('Location Updated.', 'Save Successfull');
          console.log("this is the response:", response);
          onClose();
          updateLocation();
          this.setState({location: response.data.location})
        }
        else {
          NotificationManager.error('Error Saving.', 'Something Went Wrong');
        }
      
      });
  }
  
  componentDidMount () {
    console.log("location from props:", this.props.location)
    this.setState({location: this.props.location, id: this.props.id})
  }
  
  componentDidUpdate (prevProps) {
    if (!_.isEqual(prevProps.location, this.props.location))
      this.setState({location: this.props.location});
  }
  
  render(){
    return (
      <>
      {this.state.location.lat ? 
      <span className="viewMap" onClick={this.onOpen}>Click to View/Edit on Map</span> : null }
      <ViewMap onClose={this.onClose} location={this.state.location} show={this.state.showModal} save={this.save} />
      </>
    )
  }
}

export {ViewMap, ClickToViewMap}




//
//
// <Map center={this.props.position} zoom={13}>
//   <TileLayer
//     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//   />
//   <Marker position={this.props.position}>
//     <Popup><a href={"https://www.google.com/maps/place/"+this.props.street+"+"+this.props.city+",+"+this.props.state+"+"+this.props.zip}>
//     {this.props.street}<br />{this.props.city} {this.props.state} {this.props.zip}
//     </a></Popup>
//   </Marker>
// </Map>
