import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {RTEHelper, SingleSelect} from '../../_formhelpers';
import {api} from "../../_helpers"
import moment from 'moment';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default class StudentContactLogForm extends Component {

  state = {
    log_date: moment().format(),
    primary_contact_name : '',
    associated_business : '',
    contact_relationship : '',
    contact_type : '',
    comments : '',
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  handleRTEChange = (field, value) => {
    this.setState({ [field] : value.toString("html") });
  }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date._d });
  };

  validate = () => {
    let is_valid = this.refs.form.isFormValid(false);

    if (!is_valid)
      NotificationManager.error('You have not completely filled out this portion of the form.', 'Error');

    return is_valid;
  }

  updateProfile = (e) => {
    e.preventDefault();

    let profile = this.state;
    let setUser = this.props.setUser;

      api({
        method: 'post',
        url: '/api/user',
        data: {
          profile
        }
      }).then(function (response) {
        if (response.data.errors === undefined  &&  response.data.name === undefined){
          setUser(response.data);
          NotificationManager.success('Profile Updated.', 'Save Successfull');
        }
        else {
          NotificationManager.error('Error Saving.', 'Something Went Wrong');
        }
      });

  }

  formErrors = (errors) => {
    NotificationManager.error('Check Your Form for errors', 'Form Error');
  }

  saveContactLog = (e) => {
    let errors =  document.getElementsByClassName('error', 'react-select-container');
    if (errors.length > 0){
      NotificationManager.error('Error Saving.', 'Check your form for errors');
      return false;
    }

    let jsonData = {
      data : this.state,
      student_id : this.props.student_id
    }

    api({
      method: 'post',
      url: '/api/student_contact_log_save_new',
      data: {
        jsonData
      }
    }).then((response) => {
      NotificationManager.success('Contact Log successfully saved.', 'Contact Log Saved');
      this.props.callback();
      this.clearForm();
    });
  }

  clearForm = () => {
    this.setState({
      log_date: moment().format(),
      primary_contact_name : '',
      associated_business : '',
      contact_relationship : '',
      contact_type : '',
      comments : '',
    })
  }

  render() {

    return (
      <div className="basic_form contact_log_form">
        <ValidatorForm
          ref="form"
          onSubmit={() => this.saveContactLog()}
          onError={errors => this.formErrors(errors)}
          instantValidate={true}
        >
          <div className="inline-block student_name">Student Contact Log</div>

          <div className="row">
            <div className="col-lg-6">
              <KeyboardDatePicker
                className="inputs" required={true} name="log_date" variant="outlined"
                label="Date"
                format="MM/DD/YYYY"
                onChange={(date) => this.handleDateChange(date, 'log_date')}
                onBlur={this.handleChange}
                value={this.state.log_date}
                validators={["required"]}
                placeholder="MM/DD/YYYY"
                //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                keyboard autoOk={true} disableOpenOnEnter animateYearScrolling={false}
              />
            </div>
          </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <TextValidator
                  name="primary_contact_name" label="Primary Contactee Name" variant="outlined"
                  onChange={this.handleChange} value={this.state.primary_contact_name}
                  className="inputs" required={true} validators={['required']} errorMessages={['this field is required']}
                />
              </div>

              <div className="col-md-6">
                <SingleSelect label="Pirmary Contactee Relationship" name="contact_relationship" onChange={this.handleChange} value={this.state.contact_relationship}
                  options={[
                    {value: "Student", label: "Student"},
                    {value: "Parent", label: "Parent"},
                    {value: "Mentor", label: "Mentor"},
                    {value: "Department Chair", label: "Department Chair"},
                    {value: "Administrator/School Counselor", label: "Administrator/School Counselor"}
                  ]} />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <TextValidator
                  name="associated_business" label="Associated Business" variant="outlined"
                  onChange={this.handleChange} value={this.state.associated_business}
                  className="inputs" required={true} validators={['required']} errorMessages={['this field is required']}
                />
              </div>

              <div className="col-md-6">
                <SingleSelect label="Type of Contact" name="contact_type" onChange={this.handleChange} value={this.state.contact_type}
                  options={[
                    {value: "Phone Call", label: "Phone Call"},
                    {value: "Email", label: "Email"},
                    {value: "Site Visit", label: "Site Visit"},
                    {value: "In-Person Meeting", label: "In-Person Meeting"},
                    {value: "Other", label: "Other"}
                  ]} />
              </div>
            </div>


            <div className="row">
              <div className="col-md-12">
                <RTEHelper
                  label="Comments"
                  name="comments"
                  onChange={this.handleRTEChange}
                  value={this.state.comments}
                />
              </div>
            </div>

            <div className="mobile-center-button">
            <Button type="submit" variant="contained" color="primary" className="btn_action">
              Save Log
            </Button>
            </div>

        </ValidatorForm>
      </div>
    )
  }
}
