import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {setUser, setMasquerade } from '../../ducks';
import { connect } from 'react-redux';

const actions = {
  setUser,
  setMasquerade
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

class AdminForm extends Component {

  state = {
  }

  componentDidMount() {
    this.setState({client: this.props.clientId});
  }
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };
  
  createAdmin = (e) => {
    let json = this.state;
    let goBack = this.props.toggleBack;
    let link = '/api/createAdmin';
    let message = 'Admin successfully created.';
    if (this.state.user) {
      link = '/api/editAdmin';
      message = 'Admin successfully edited.';
    }
    api({
        method: 'post',
        url: link,
        data: {
          json
        }
      }).then(function (response) {
        console.log("this is the response:", response)
        
          if (response.data === "success"){
            NotificationManager.success(message, 'Success');
            goBack(true);
          }
          else if (response.data === "User Exists") {
            NotificationManager.warning("Check the Email", 'User Exists');
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
        console.log("create admin:", json);
  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.createAdmin()}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        <TextValidator
        name="first_name" label="First Name" variant="outlined"
        onChange={this.handleChange} value={this.state.first_name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <TextValidator
        name="last_name" label="Last Name" variant="outlined"
        onChange={this.handleChange} value={this.state.last_name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <TextValidator
        name="email" label="Email" variant="outlined"
        onChange={this.handleChange} value={this.state.email}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        
        <div className="mobile-center-button">
          <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
            Create Admin
          </Button>
        </div>
        
       </ValidatorForm>
     )
   }
}

export default connect(mapStateToProps, actions)(AdminForm);
