import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { api } from "../../_helpers";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      email: "",
      password: "",
      new_password: "",
      new_password_match: "",
      expired: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changePassword = (e) => {
    e.preventDefault();
    if (this.state.new_password !== this.state.new_password_match) NotificationManager.error("Passwords Don't Match", "Try Again", 5000);
    else {
      api({
        method: "post",
        url: "/auth/changePasswordById",
        data: {
          _id: this.state._id,
          new_password: this.state.new_password,
        },
      }).then((response) => {
        console.log("response:", response);
        if (response.data.message === "success") {
          NotificationManager.success("Password Successfully Changed", "Thanks!", 5000);
          this.props.history.push("/login");
        } else if (response.data.message === "expired") {
          this.setState({ expired: true });
        }
      });
    }
  };

  componentDidMount() {
    axios({
      method: "post",
      url: "/auth/findUserByCode",
      data: {
        code: this.props.match.params.code,
      },
    }).then((user) => {
      console.log("user", user);
      if (user.data.message === "expired") this.setState({ expired: true });
      else this.setState({ _id: user.data.user._id, email: user.data.user.email });
    });

    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.new_password) {
        return false;
      }
      return true;
    });
  }

  render() {
    return (
      <div className="login-hero">
        <Paper className="container_accounts mx-auto" elevation={1}>
          <h4>Update Password</h4>
          {!this.state.expired ? (
            <ValidatorForm onSubmit={this.changePassword}>
              <TextValidator
                className="inputs"
                variant="outlined"
                label="Current Email"
                placeholder="Current Email"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["email is required"]}
                disabled
              />

              <TextValidator
                className="inputs"
                variant="outlined"
                label="New Password"
                placeholder="New Password"
                type="password"
                name="new_password"
                value={this.state.new_password}
                onChange={this.handleChange}
                //validators={['required']}
                //errorMessages={['new password is required']}
              />

              <TextValidator
                className="inputs"
                variant="outlined"
                label="Match Password"
                placeholder="Match Password"
                type="password"
                name="new_password_match"
                value={this.state.new_password_match}
                onChange={this.handleChange}
                validators={["isPasswordMatch"]}
                errorMessages={["this field must match the other password"]}
              />

              <br />
              <Button type="submit" variant="contained" color="primary" className="btn_action">
                Change Password &nbsp; <FontAwesomeIcon icon={["fal", "key"]} />
              </Button>
            </ValidatorForm>
          ) : (
            <div>This link is expired</div>
          )}
        </Paper>
      </div>
    );
  }
}
