import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {setUser, updateSettings } from '../../ducks';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import {api} from "../../_helpers"
import { Progress } from 'react-sweet-progress';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Row} from 'react-bootstrap';
import PieChart from '../Shared/Charts';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "react-sweet-progress/lib/style.css";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
  };
};

const actions = {
  setUser, updateSettings
}

let options = {};

class ConnectedAdminDashboard extends Component {

  state = {
    ...this.props.user,
    db: {
      schools: [],
      programs: [],
      focusAreas: [],
      reflectionQuestions: [],
      evaluationQuestions: []
    }
  }

  componentDidMount() {    
    if (!this.props.settings.is_complete)
      this.makeCalls();
    else {
        this.getCounts();
    }
  }

  getPrograms = () => {
    return axios({
      method: 'get',
      url: '/api/getPrograms',
    });
  }

  getFocusAreas = () => {
    return axios({
      method: 'get',
      url: '/api/getFocusAreas',
    });
  }

  getSchools = () => {
    return axios({
      method: 'get',
      url: '/api/getSchools',
    });
  }

  getReflectionQuestions = () => {
    return axios({
      method: 'get',
      url: '/api/getMyReflectionQuestions',
    });
  }

  getEvaluationQuestions = () => {
    return axios({
      method: 'get',
      url: '/api/getMyEvaluationQuestions',
    });
  }

  setSettingsAsComplete = () => {
    let updateSettings = this.props.updateSettings
    api({
      method: 'post',
      url: '/api/setSettingsAsComplete',
    }).then(results => {
      console.log("settings are complete", results);
      updateSettings(results.data);
    });
  }
  
  getUserCounts = () => {
    return api({
      method: 'get',
      url: '/api/countClientUsers'      
    })
  }
  
  getApplicationCounts = () => {
    return api({
      method: 'get',
      url: '/api/getStudentApplicationCount'
    })
  }
  
  getBusinessCounts = () => {
    return api({
      method: 'get',
      url: '/api/getBusinessInternshipStats'
    })
  }
  
  getEmployeedCounts = () => {
    return api({
      method: "get",
      url: "/api/getTotalStudentsEmployed"      
    })
  }
  
  getCounts = () => {
    axios.all([this.getUserCounts(), this.getApplicationCounts(), this.getBusinessCounts(), this.getEmployeedCounts()])
    .then(axios.spread((users, applications, businessStats, employeed) => {
      this.setState(state => ((state.db.users = users.data, state)));
      this.setState(state => ((state.db.applications = applications.data, state)));
      this.setState(state => ((state.db.businessStats = businessStats.data, state)));
      this.setState(state => ((state.db.employeed = employeed.data, state)));
    }))
  }

  makeCalls = () => {
    axios.all([this.getSchools(), this.getFocusAreas(), this.getPrograms(), this.getEvaluationQuestions(), this.getReflectionQuestions()])
    .then(axios.spread((schools, focusAreas, programs, evaluationQuestions, reflectionQuestions) => {
      this.setState(state => ((state.db.schools = schools.data, state)));
      this.setState(state => ((state.db.focusAreas = focusAreas.data, state)));
      this.setState(state => ((state.db.programs = programs.data, state)));
      this.setState(state => ((state.db.evaluationQuestions = evaluationQuestions.data, state)));
      this.setState(state => ((state.db.reflectionQuestions = reflectionQuestions.data, state)));
    })).then(() => {
      if (this.state.db.schools.length > 0 && this.state.db.focusAreas.length > 0 && this.state.db.programs.length > 0 &&
        this.state.db.evaluationQuestions.length > 0 && this.state.db.reflectionQuestions.length > 0)
          this.setSettingsAsComplete();

    });
  }

  deactivate = () => {
    api({
      method: 'post',
      url: '/api/deactivateGraduatedStudents',
    }).then(results => {
      console.log('returned back', results)
    });
  }

  render() {
    let completion = 0;
    if (this.props.settings.is_complete)
      completion = 100;
    else {

      if (this.props.settings.short_name)
        completion += 17;

      if (this.state.db.schools.length > 0)
        completion += 17;

      if (this.state.db.focusAreas.length > 0)
        completion += 17;

      if (this.state.db.programs.length > 0)
      completion += 17;

      if (this.state.db.evaluationQuestions.length > 0)
      completion += 17;

      if (this.state.db.reflectionQuestions.length > 0)
      completion += 17;
    }

    console.log(this.props.user)


    return (
      <div className="side-page">
      {completion < 100 ?
      <div className="progressBar">
      <div className="progressExplanation">
        <h2>Admintern Dashboard</h2>
        Welcome to the Admintern Client Dashboard.  You must complete certain sections before the site is ready for students and partners to use the tool.  To the right you will see the Databases you still need to populate.  Once these databases are populated, the tool will be ready for use.  If you have anyquestion, please contact the admintern admin for assistance.
      </div>
      <Progress percent={completion} type="circle"
      theme={{
        active: {
          color: "#20416c"
        }
      }}
        />
       <div className="progressButtons">
        {this.state.db.schools.length === 0 ? <Button variant="contained" color="primary" href="/admin/schools">Add schools</Button> : null}
        {this.state.db.focusAreas.length === 0 ? <Button variant="contained" color="primary" href="/admin/focusAreas">Add focus areas</Button> : null}
        {this.state.db.programs.length === 0 ? <Button variant="contained" color="primary" href="/admin/programs">Add programs</Button> : null}
        {this.state.db.evaluationQuestions.length === 0 ? <Button variant="contained" color="primary" href="/admin/evaluationQuestions">Add evaluation questions</Button> : null}
        {this.state.db.reflectionQuestions.length === 0 ? <Button variant="contained" color="primary" href="/admin/reflectionQuestions">Add reflection questions</Button> : null}
      </div>
      </div>
      :
      
        <>
        <div className="page_header with_search no_results"><h1>Admin Dashboard</h1></div>
        <Row>
        <Col md={4}>
        <div className="adminDashboard">
          <Link to="businessReport/"><div className="dashboardBlock"><FontAwesomeIcon className="dashboardIcon" icon={["fal", "building"]}/>Business Report</div></Link>
          <Link to="studentReport/"><div className="dashboardBlock"><FontAwesomeIcon className="dashboardIcon" icon={["fal", "graduation-cap"]}/>Student Report</div></Link>
          <Link to="internship_status_report/"><div className="dashboardBlock"><FontAwesomeIcon className="dashboardIcon" icon={["fal", "chart-bar"]}/>Work-Based Learning Report</div></Link>
          <Link to="available_opportunities_report/"><div className="dashboardBlock"><FontAwesomeIcon className="dashboardIcon" icon={["fal", "car-building"]}/>Opportunities Report</div></Link>
          <Link to="application_count/"><div className="dashboardBlock"><FontAwesomeIcon className="dashboardIcon" icon={["fal", "chart-line"]}/>Student Application Count</div></Link>
          <Link to="/admin/duplicateUsersReport/"><div className="dashboardBlock"><FontAwesomeIcon className="dashboardIcon" icon={["fal", "users"]}/>Duplicate Users Report</div></Link>
          <Link to="#"><div className="dashboardBlock" onClick={this.deactivate}><FontAwesomeIcon className="dashboardIcon" icon={["fal", "users"]}/>Deactivate Users</div></Link>
        </div>

        </Col>
        <Col md={8} className="chart-container">

        {this.props.user.role === "Admin" ? (
            <HighchartsReact
            highcharts={Highcharts}
            options={options = {
                title: {
                    text: 'Active Users - ' + (this.state.db.users?.active[0] ? this.state.db.users.active[0].count : 0)
                },
                   chart: {
                     type: "bar",
                     height: "200"
                   },
                   xAxis: {
                       categories: [''],
                       title: {
                         text: ""
                       },
                       
                   },
                yAxis: {
                  visible: false,
                    min: 0,
                    title: {
                        text: ''
                    },
                    
                },
                legend: {
                    reversed: true
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
              
                  credits: {
                      enabled: false
                  },
                  
                  series: [{name: "Students", data: [this.state.db.users?.active_students[0]?.count]},
                  {name: "Partners", data: [this.state.db.users?.active_partners[0]?.count]},
                  {name: "Teachers/Admins", data: [this.state.db.users?.teachers[0]?.count]},]
                    
              }}
          /> 
        ) : null}
           
           
            <div className="pies">
            
            <PieChart title="Active Employeed Students" series="Students" data={[
              {name: "Active Students With Internship", y: this.state.db.employeed?.active_unique_users?.length || null},
              {name: "Active Students Without Internship", y: (this.state.db.users?.active_students[0]?.count-this.state.db.employeed?.active_unique_users?.length) || null},
            ]} />
            
            <PieChart title="Available Opportunities" series="Opportunities" data={[
              {name: "Available", y: this.state.db.businessStats?.avail_open[0]?.count || null},
              {name: "Closed", y: this.state.db.businessStats?.avail_closed[0]?.count || null},
            ]} />
            
            <PieChart title="Applicants" series="Opportunities" data={[
              {name: "With Applicants", y: this.state.db.businessStats?.with_applicant[0]?.count || null},
              {name: "No Applicants", y: this.state.db.businessStats?.zero_applicants[0]?.count || null},
              
            ]} />
            
            <PieChart title="Application Breakdown" series="Applications" data={[
                  {
                      name: 'Accepted',
                      y: this.state.db.applications?.accepted[0]?.count || null
                  },
                  {
                      name: 'Declined',
                      y: this.state.db.applications?.declined[0]?.count || null
                  },
                  {
                      name: 'New',
                      y: this.state.db.applications?.new[0]?.count || null
                  },
                  {
                      name: 'Offered',
                      y: this.state.db.applications?.offered[0]?.count || null
                  },
                  {
                      name: 'Scheduled',
                      y: this.state.db.applications?.scheduled[0]?.count || null
                  }
              ]} />
              
              <PieChart title="Opportunity Locations" series="Opportunities" data={[
                  {name: "On-Site", y: this.state.db.businessStats?.format_onsite[0]?.count || null},
                  {name: "Virtual", y: this.state.db.businessStats?.format_virtual[0]?.count || null},
                  {name: "Hybrid", y: this.state.db.businessStats?.format_hybrid[0]?.count || null},
                ]} />
                <PieChart title="Opportunity Type" series="Opportunities" data={[
                    {name: "Internship", y: this.state.db.businessStats?.type_internship[0]?.count || null},
                    {name: "Apprenticeship", y: this.state.db.businessStats?.type_apprenticeship[0]?.count || null},
                  ]} />
                  <PieChart title="Paid Vs Unpaid" series="Opportunities" data={[
                    {name: "Paid", y: this.state.db.businessStats?.paid[0]?.count || null},
                    {name: "Unpaid", y: this.state.db.businessStats?.unpaid[0]?.count || null},
                  ]} />
                  <PieChart title="Opportunity Time" series="Opportunities" data={[
                      {name: "Winter", y: this.state.db.businessStats?.time_winter[0]?.count || null},
                      {name: "Spring", y: this.state.db.businessStats?.time_spring[0]?.count || null},
                      {name: "Summer", y: this.state.db.businessStats?.time_summer[0]?.count || null},
                      {name: "Fall", y: this.state.db.businessStats?.time_fall[0]?.count || null},
                    ]} />
            
            <PieChart title="Lifetime Employeed Students" series="Students" data={[
              {name: "Lifetime Students With Internship", y: this.state.db.employeed?.lifetime_unique_users?.length || null},
              {name: "Lifetime Students Without Internship", y: (this.state.db.users?.lifetime_students[0].count-this.state.db.employeed?.lifetime_unique_users?.length) || null},
            ]} />
            </div>
         </Col>
        </Row>
        </>
      }
    </div>
    )
  }
}

export default connect(mapStateToProps, actions)(ConnectedAdminDashboard);
