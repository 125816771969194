import React, { Component} from "react";
import moment from 'moment';

export default class Project_FinalProject extends Component {

  render() {

    let file_upload = null
    if (typeof this.props.project.final_project.files !== 'undefined'){
      file_upload = this.props.project.final_project.files.length;
    }


    return (
      <div>
        <h3>Final Project</h3>
        <div>

        {(file_upload === null || file_upload === 0) ? (
          <div>The student has not uploaded their final project file(s) yet.</div>
        ) : (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th width="65%">Uploaded File</th>
                <th width="20%">Date Uploaded</th>
                <th width="15%">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.project.final_project.files.map(file => (
                <tr key={file._id}>
                  <td>{file.name}</td>
                  <td>{moment(file.createdAt).format("MM/DD/YYYY")}</td>
                  <td className="table-actions">
                    <a href={"../../" + file.path} target="_blank" rel="noopener noreferrer" title="View File"><i className="fal fa-eye"></i></a>
                  </td>
                </tr>

              ))}
            </tbody>
          </table>
        )}

        </div>

      </div>

    )
  }
}
