import React, { Component } from 'react';
import axios from 'axios'

export default class ImportOpps extends Component {

  state = {

  }

  componentDidMount() {
    this.makeCalls();
  }

  fixOpportunities = () => {
    return axios({
      method: 'get',
      url: '../api/parseOppImport',
    });
  }

  makeCalls = () => {
    axios.all([this.fixOpportunities()])
    .then(axios.spread(function (opportunities) {

      console.log('back');
      alert('DONE')

    }));
  }



  render() {
    return (
      <div className="side-page">
        <div className="page_header"><h1>Update Imported Opportunities</h1> </div>

        <div className="row">
          <div className="col-12 table-responsive-sm">

            This page is going to automatically call the parseOppImport method. It will return a complete alert once done.

          </div>
        </div>
      </div>
    )
  }
}
