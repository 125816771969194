import React, { Component} from "react";

export default class Project_StudentApplication extends Component {

  render() {

    return (
      <div>
        <h3>Student Summary</h3>
        <div>
          <h3>Student Cover Letter:</h3>
          <div dangerouslySetInnerHTML={{__html: this.props.project.application_id_fk.cover_letter}} />

          <h3>Business Notes:</h3>
          <div></div>

        </div>
      </div>

    )
  }
}
