import React, { Component } from 'react';

export default class ParticipatingBusinesList extends Component {

  render() {

    return (
      <div className="side-page">
        <div className="page_header"><h1>Participating Businesses</h1> </div>

        <p>Coming Soon...</p>
      </div>
    )
  }
}
