import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

export default class FocusAreaForm extends Component {

  state = {
    disabled: true,
    name: ""
  }

  componentDidMount() {
    if (this.props.selectedUser) {
      this.setState({...this.props.settings, disabled: false});
    }
  }
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };
  
  saveFa = (end) => {
    if (this.state.name === "" && end){
      this.props.toggleBack(true);
      return 
    }
    let json = this.state;
    let goBack = this.props.toggleBack;
    let link = '/api/createFocusArea';
    let message = 'Focus Area Saved';
    
    api({
        method: 'post',
        url: link,
        data: {
          json
        }
      }).then((response) => {
        console.log("this is the response:", response)
        
          if (response.data === "success" || response.data === ""){
            NotificationManager.success(message, 'Success');
            if (end)
              goBack(true);
            else 
              this.setState({name: ""})
          }
          else if (response.data === "Focus Area Already Exists") {
            NotificationManager.warning("Focus Area Already Exists", 'Uh Oh');
          }
          else  {
            NotificationManager.warning("Something Went Wrong", 'Uh Oh');
          }
        
        })
  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.saveFa(false)}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        <TextValidator
        name="name" label="Focus Area Name" variant="outlined"
        onChange={this.handleChange} value={this.state.name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        
        <div className="mobile-center-button">
        <Button type="submit" variant="contained" color="primary" className="btn_action">
          Save and Add Another
        </Button>
        <Button type="button" variant="contained" color="primary" className="btn_action" onClick={() => this.saveFa(true)}>
        Save and Go Back
      </Button>

        </div>
        
       </ValidatorForm>
     )
   }
}
