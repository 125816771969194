import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

let options = {};

export default class PieChart extends Component {

	render () {
		return (
			
			this.props.data.reduce((acc, cur) => cur.y !== null ? ++acc : acc, 0) === 1 ? //show count if there is only one thing
			
				<div className="highcharts-container"><div className="nochart-title"> 
				
					{this.props.data.find((element) => element.y !== null).name} {this.props.series}</div><div className="nochart-count">{this.props.data.find((element) => element.y !== null).y}</div></div>
				
				: this.props.data.reduce((acc, cur) => cur.y !== null ? ++acc : acc, 0) > 1 && //only show chart if there are more than one thing to compare
			
	    			<HighchartsReact
					highcharts={Highcharts}
					options={options = {
						title: {
							text: this.props.title
						},
			   			chart: {
				 			type: "pie",
				 			height: "250"
			   			},
			   			xAxis: {
				   			categories: [''],
				   			title: {
					 			text: ""
				   			},
				   			
			   			},
						yAxis: {
			  			visible: false,
							min: 0,
							title: {
								text: ''
							},
							
						},
						plotOptions: {
							series: {
								dataLabels: {
									enabled: false
								},
								showInLegend: true
							}
						},
		  			
			  			credits: {
				  			enabled: false
			  			},
			  			
			  			series: [{name: this.props.series, 
			  				data: this.props.data
		  			}],
							
		  			}}
	  			/> 
			  
		)
	}
};