import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';


export default class ProgramForm extends Component {

  state = {
    disabled: true,
    name: ""
  }

  componentDidMount() {
    if (this.props.selectedProgram !== null)
    this.setState({
      ...this.props.selectedProgram
    })
  }
  
  
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };
  
  saveProgram = (end) => {
    let link = '/api/createProgram';
    let message = 'Program Created';
    if (this.state.name === "" && end){
      this.props.toggleBack(true);
      return 
    }
    if (this.state._id) {
      link = '/api/editProgram';
      message = 'Program successfully edited.';
    }
    let json = this.state;
    let goBack = this.props.toggleBack;
    api({
        method: 'post',
        url: link,
        data: {
          json
        }
      }).then((response) => {
        console.log("this is the response:", response)
        
          if (response.data !== "Already Exists"){
            NotificationManager.success(message, 'Success');
            if (end)
              goBack(true);
            else 
              this.setState({name: ""})
          }
          else {
            NotificationManager.warning("Program Already Exists", 'Uh Oh');
          }
        })
  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.saveProgram(false)}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        <TextValidator
        name="name" label="Program Name" variant="outlined"
        onChange={this.handleChange} value={this.state.name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        
        <div className="mobile-center-button">
          { !this.state._id ? (
            <>
        <Button type="submit" variant="contained" color="primary" className="btn_action">
          Add and Add Another
        </Button>
        <Button type="button" variant="contained" color="primary" className="btn_action" onClick={() => this.saveProgram(true)}>
        Save and Go Back
      </Button>
        </>) : 
        <>
        <Button type="button" variant="contained" color="primary" className="btn_action" onClick={() => this.saveProgram(true)}>
          Edit Program
        </Button>
        </>
      }

        </div>
        
       </ValidatorForm>
     )
   }
}

