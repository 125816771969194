import React, { Component } from 'react';
import axios from 'axios'
import {NotificationManager} from 'react-notifications';
import Button from '@material-ui/core/Button';

import {RTEHelper} from '../../_formhelpers'

export default class ProjectSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      summary : this.props.summary.text,
    }

    this.handleRTEChange = this.handleRTEChange.bind(this);
  }

  handleRTEChange = (field, value) => {
    this.setState({ [field] : value.toString("html") });
  };


  saveSummary = () => {
    //e.preventDefault();
    let jsonData = {
      project_id : this.props.projectId,
      text : this.state.summary,
    }

    //console.log(jsonData);
    if (this.state.summary === " ")
      NotificationManager.error('You must provide a summary', 'Summary Is Empty');
    else {
      axios({
        method: 'post',
        url: '/api/project_saveSummary',
        data: {
          jsonData
        }
      }).then(function (response) {
        NotificationManager.success('Summary successfully saved.', 'Project Summary Saved');
      });
  }
  }

  render () {
    return (
      <div className="project_summary">

        {this.props.summary.approved  ? (
          <div>
            Your Project Summary has been approved. Therefore you are no longer permitted to make edits. You can review your summary below.
            <p></p>
            <div dangerouslySetInnerHTML={{__html: this.state.summary}} />
          </div>
        ) : (
          <div>

          <div className="mb-4" style={{fontSize : '12pt', lineHeight : '20pt'}}>
            The final summary is to be completed within 1 week of the conclusion of your internship. Please complete this text box as a polished final reflection and summary of your internship. This is what we will share with your internship mentor and the community about your experience. Please include a few highlights of your experience, what you have learned from this experience, and how this experience will help you in your future academic studies and career. This may be very similar to something you may want to use for a college application essay. This should be approximately 250 words.
          </div>

          <RTEHelper
            name="summary"
            onChange={this.handleRTEChange}
            value={this.state.summary}
          />
        <br />
          <Button variant="contained" color="primary" className="btn_action" onClick={this.saveSummary}>
            Save Summary
          </Button>
          </div>
        )}

      </div>
    )
  }
}
