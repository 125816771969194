import React, { Component } from 'react';

import ReflectionQuestion from './ReflectionQuestion';

export default class Reflections extends Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded : "panel" + this.props.initial_question || null,
    }
  }

  //This used to be component till receive props which is deprecated
  componentDidUpdate(prevProps) {
    if (this.props.initial_question !== prevProps.initial_question)
    this.setState({
      expanded : "panel" + this.props.initial_question
    });
  }

  swapPanel = (panel, lock_state) => (event, expanded) => {
    if (lock_state === 'locked')
      return

    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render () {
    const { expanded } = this.state;
    const reflections = this.props.reflections;

    return (

      <div className="accordion_panel">
        {reflections.map(reflection => {
          return (
            <ReflectionQuestion
              key={reflection._id}
              reflection={reflection}
              projectID={this.props.projectId}
              expanded={expanded}
              swapPanel={this.swapPanel}
              updateReflectionQuestion={this.props.updateReflectionQuestion}
              approval_state={this.props.approval_state}
            />
          )
        })}
      </div>

    )
  }
}
