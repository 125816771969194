import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
//import { connect } from 'react-redux';
import { store } from "./stores/admintern";

import Home from "./Home";
import Login from "./components/Accounts/Login";
import Password from "./components/Accounts/Password";
import Forgot from "./components/Accounts/Forgot";
import ForgotPassword from "./components/Accounts/ForgotPassword";
import AccountWrapper from "./components/Accounts/AccountWrapper";
import ProfileWrapper from "./components/Profile/ProfileWrapper";
import Default from "./components/Accounts/Default";

import Uhoh from "./components/Layout/Uhoh";

import ResourceList from "./components/SimplePages/ResourceList";
import ContactUs from "./components/SimplePages/ContactUs";
import ParticipatingBusinesList from "./components/SimplePages/ParticipatingBusinesList";

import OpportunityList from "./components/Opportunities/OpportunityList";
import OpportunityForm from "./components/Opportunities/OpportunityForm";
import OpportunityView from "./components/Opportunities/OpportunityView";
import OpportunityBrowse from "./components/Opportunities/Browse";
import OpportunityApply from "./components/Opportunities/OpportunityApply";
import OpportunityApplicants from "./components/Opportunities/OpportunityApplicants";

import ViewApplication from "./components/Applications/ViewApplication";
import ProfileViewWrapper from "./components/Profile/ProfileViewWrapper";
import StudentApplications from "./components/Applications/StudentApplications";

import StudentProjects from "./components/Projects/StudentProjects";

import HourLog from "./components/Projects/Project_HourLog";
import ReflectionPage from "./components/Projects/Project_Reflections";
import Summary from "./components/Projects/Project_Summary";
import ProjectFile from "./components/Projects/Project_File";
import UnfinishedProfile from "./components/Profile/UnfinishedProfile";
import UnapprovedProfile from "./components/Profile/UnapprovedProfile";
import PermissionsError from "./components/Profile/PermissionsError";

import AdminDashboard from "./components/Admin/Dashboard";
import UserSearch from "./components/Admin/UserSearch";
import BusinessReport from "./components/Admin/BusinessReport";
import StudentReport from "./components/Admin/StudentReport";
import Masquerade from "./components/Admin/Masquerade";
import ProjectReviewWrapper from "./components/Admin/ProjectReviewWrapper";

import InternshipStatusReport from "./components/Admin/InternshipStatusReport";
import AvailableOpportunitiesReport from "./components/Admin/AvailableOpportunitiesReport";
import ApplicationCount from "./components/Admin/ApplicationCount";
import DuplicateUsersReport from "./components/Admin/DuplicateUsers";

import InternList from "./components/Interns/ViewInterns";
import BusinessEvaluation from "./components/Interns/BusinessEvaluation";
import BusinessEvaluationView from "./components/Interns/BusinessEvaluationView";
import BusinessHoursLog from "./components/Interns/BusinessHoursLog";

import AdminternDashboard from "./components/Admintern/Dashboard";
import EditClient from "./components/Admintern/EditClient";

import SiteSettings from "./components/SiteAdmin/SiteSettings";
import SchoolList from "./components/SiteAdmin/Schools";
import FocusAreaList from "./components/SiteAdmin/FocusAreas";
import ProgramList from "./components/SiteAdmin/Programs";
import AdminResourceList from "./components/SiteAdmin/Resources";
import ReflectionQuestions from "./components/SiteAdmin/ReflectionQuestions";
import EvaluationQuestions from "./components/SiteAdmin/EvaluationQuestions";
import AdminManager from "./components/SiteAdmin/AdminManager";

import ImportOpps from "./components/Opportunities/ImportOpps";
import BlowEmAway from "./components/Opportunities/BlowEmAway";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <UnAuthenticatedRoute path="/login" component={Login} incompleteProfile={true} />
        <UnAuthenticatedRoute path="/signup" component={AccountWrapper} incompleteProfile={true} />
        <UnAuthenticatedRoute path="/forgot" component={Forgot} incompleteProfile={true} />
        <UnAuthenticatedRoute path="/forgotPassword/:code" component={ForgotPassword} incompleteProfile={true} />
        <AuthenticatedRoute path="/resources" component={ResourceList} incompleteProfile={true} />
        <UnAuthenticatedRoute path="/contact" component={ContactUs} incompleteProfile={true} />
        <UnAuthenticatedRoute path="/business_list" component={ParticipatingBusinesList} incompleteProfile={true} />
        <AuthenticatedRoute path="/uhoh" component={Uhoh} incompleteProfile={true} />

        <AuthenticatedRoute path="/student_resources" component={ResourceList} />

        <AuthenticatedRoute path="/admintern/dashboard" component={AdminternDashboard} roles={["SuperAdmin"]} />
        <AuthenticatedRoute path="/admintern/editClient/:id?" component={EditClient} roles={["SuperAdmin"]} />
        <AuthenticatedRoute path="/admintern/reflectionQuestions" component={ReflectionQuestions} roles={["SuperAdmin"]} />
        <AuthenticatedRoute path="/admintern/evaluationQuestions" component={EvaluationQuestions} roles={["SuperAdmin"]} />
        <AuthenticatedRoute path="/admintern/focusAreas" component={FocusAreaList} roles={["SuperAdmin"]} />
        <AuthenticatedRoute path="/admintern/programs" component={ProgramList} roles={["SuperAdmin"]} />

        <AuthenticatedRoute path="/password" component={Password} />
        <AuthenticatedRoute path="/profile/view/:id" component={ProfileViewWrapper} />
        <AuthenticatedRoute path="/profile/view/" component={ProfileViewWrapper} />
        <AuthenticatedRoute path="/profile/:test?" component={ProfileWrapper} incompleteProfile={true} />
        <AuthenticatedRoute path="/unfinishedProfile" component={UnfinishedProfile} incompleteProfile={true} />
        <AuthenticatedRoute path="/permissionsError" component={PermissionsError} incompleteProfile={true} />
        <AuthenticatedRoute path="/unapprovedProfile" component={UnapprovedProfile} incompleteProfile={true} />
        <AuthenticatedRoute path="/default" component={Default} incompleteProfile={true} />

        <AuthenticatedRoute path="/opportunity/new" component={OpportunityForm} roles={["Business"]} />
        <AuthenticatedRoute path="/opportunity/edit/:id" component={OpportunityForm} roles={["Business"]} />
        <AuthenticatedRoute path="/opportunity/duplicate/:id" component={OpportunityForm} roles={["Business"]} />
        <AuthenticatedRoute path="/opportunity/all" component={OpportunityList} />
        <AuthenticatedRoute path="/opportunity/view/:id/apply" component={OpportunityApply} approvalNeeded />
        <AuthenticatedRoute path="/opportunity/view/:id" component={OpportunityView} approvalNeeded />
        <AuthenticatedRoute path="/opportunity/browse" component={OpportunityBrowse} approvalNeeded />
        <AuthenticatedRoute path="/opportunity/applicants/:id" component={OpportunityApplicants} />

        <AuthenticatedRoute path="/applications/list" component={StudentApplications} approvalNeeded />
        <AuthenticatedRoute path="/application/view/:id" component={ViewApplication} />

        <AuthenticatedRoute path="/projects/viewall" component={StudentProjects} approvalNeeded />
        <AuthenticatedRoute path="/project/:id/hours" component={HourLog} />
        <AuthenticatedRoute path="/project/:id/reflections" component={ReflectionPage} />
        <AuthenticatedRoute path="/project/:id/summary" component={Summary} />
        <AuthenticatedRoute path="/project/:id/file" component={ProjectFile} />

        <AuthenticatedRoute path="/admin/siteSettings" component={SiteSettings} roles={["Admin"]} />
        <AuthenticatedRoute path="/admin/schools" component={SchoolList} roles={["Admin"]} />
        <AuthenticatedRoute path="/admin/focusAreas" component={FocusAreaList} roles={["Admin"]} />
        <AuthenticatedRoute path="/admin/resources/:type" component={AdminResourceList} roles={["Admin"]} />
        <AuthenticatedRoute path="/admin/programs" component={ProgramList} roles={["Admin"]} />
        <AuthenticatedRoute path="/admin/dashboard" component={AdminDashboard} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/user_search" component={UserSearch} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/businessReport" component={BusinessReport} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/studentReport" component={StudentReport} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admins/:id?" component={AdminManager} roles={["Admin"]} />
        <AuthenticatedRoute path="/admin/internship_status_report" component={InternshipStatusReport} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/application_count" component={ApplicationCount} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/available_opportunities_report" component={AvailableOpportunitiesReport} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/masquerade/:admin_id/:user_id" component={Masquerade} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/project/:id/:content_type?" component={ProjectReviewWrapper} roles={["SuperAdmin", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/admin/reflectionQuestions" component={ReflectionQuestions} roles={["SuperAdmin", "Admin"]} />
        <AuthenticatedRoute path="/admin/evaluationQuestions" component={EvaluationQuestions} roles={["SuperAdmin", "Admin"]} />
        <AuthenticatedRoute path="/admin/duplicateUsersReport" component={DuplicateUsersReport} roles={["SuperAdmin", "Admin"]} />

        <AuthenticatedRoute path="/interns/viewall" component={InternList} roles={["Business"]} approvalNeeded />
        <AuthenticatedRoute path="/evaluation/new/:id" component={BusinessEvaluation} roles={["Business"]} />
        <AuthenticatedRoute path="/evaluation/view/:project_id/:id" component={BusinessEvaluationView} roles={["Business", "Admin", "Teacher"]} />
        <AuthenticatedRoute path="/interns/hours_log/:project_id/" component={BusinessHoursLog} roles={["Business", "Admin", "Teacher"]} />

        <AuthenticatedRoute path="/opportunity/parseImport" component={ImportOpps} roles={["Admin"]} />
        <AuthenticatedRoute path="/opportunity/blowItAway" component={BlowEmAway} roles={["Admin"]} />

        {/* CHAD NOTE TO ADD ROLES TO A Route
          Pass roles as a prop
          the prop must be an array, even if it's one role /*
          }

          {/*<Route path="/signup" component={AccountWrapper}/>
          {/*<UnAuthenticatedRoute path="/login" component={Login}/>

          <Route path="/faq" component={FAQ}/>
          <Route path="/about" component={AboutUs}/>
          <Route path="/contact" component={Contact}/>

          <Route path="*" component={Lost}/>*/}
      </Switch>
    );
  }
}

class UnAuthenticatedRoute extends Component {
  render() {
    const reduxstate = store.getState();
    console.log("this is weird:", reduxstate.auth);
    if (!reduxstate.auth.isAuthenticated) return <Route path={this.props.path} component={this.props.component} />;
    else return <Redirect to="/profile" />;
  }
}

class AuthenticatedRoute extends Component {
  render() {
    const reduxstate = store.getState();
    if (reduxstate.auth.isAuthenticated) {
      let userRole = reduxstate.auth.user.role;

      if (this.props.incompleteProfile === true)
        // no further vetting needed
        return <Route path={this.props.path} component={this.props.component} />;

      if (reduxstate.auth.user.__v === 0 && reduxstate.auth.user.role !== "Teacher" && reduxstate.auth.user.role !== "Admin")
        // version 0 of the profile means user hasn't updated yet
        return <Redirect to="/unfinishedProfile" />;

      if (this.props.approvalNeeded && !reduxstate.auth.user.approved && reduxstate.auth.user.role !== "Admin" && !reduxstate.auth.user.approved && reduxstate.auth.user.role !== "Teacher")
        return <Redirect to="/unapprovedProfile" />;

      if (this.props.roles !== undefined) {
        // role based route
        //console.log("this is in the routes:", this.props.roles, userRole)
        if (this.props.roles.includes(userRole)) return <Route path={this.props.path} component={this.props.component} />;
        else return <Redirect to="/permissionsError" />;
      } else return <Route path={this.props.path} component={this.props.component} />;
    } else return <Redirect to="/login" />;
  }
}

//export default connect(mapStateToProps)(ConnectedRoutes);
export default withRouter(Routes);
//connect(mapStateToProps)(UnAuthenticatedRoute);
//connect(mapStateToProps)(AuthenticatedRoute);
