import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { api } from "../../_helpers";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      success: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resetPasswordEmail = (e) => {
    e.preventDefault();
    api({
      method: "post",
      url: "/auth/resetPassword",
      data: {
        email: this.state.email,
      },
    }).then((response) => {
      console.log("response:", response);
      if (response.data.message === "success") {
        NotificationManager.success("Email Sent", "Check Your Email", 5000);
        this.setState({ success: true });
      } else if (response.data.message === "wrong email") {
        NotificationManager.error("No User Found", "No User Found", 5000);
      }
    });
  };

  render() {
    return (
      <div className="login-hero">
        <Paper className="container_accounts mx-auto" elevation={1}>
          <h4>Forgot Your Password</h4>
          {!this.state.success ? (
            <ValidatorForm onSubmit={this.resetPasswordEmail}>
              <TextValidator
                className="inputs"
                variant="outlined"
                label="Account Email"
                placeholder="Account Email"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["email is required"]}
              />

              <br />
              <Button type="submit" variant="contained" color="primary" className="btn_action">
                Reset Password &nbsp; <FontAwesomeIcon icon={["fal", "key"]} />
              </Button>
            </ValidatorForm>
          ) : (
            <div>An link to update your password was sent to {this.state.email}. The link expires in 30 minutes.</div>
          )}
        </Paper>
      </div>
    );
  }
}
