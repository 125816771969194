import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setUser } from '../../ducks';
import axios from 'axios'
import _ from "underscore";
import {api} from "../../_helpers"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MasqueradeIcon} from '../Admin/_shared';
import AdminForm from './AdminForm';
import {Col, Row} from 'react-bootstrap';
import swal from 'sweetalert';
import {NotificationManager} from 'react-notifications';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { v4 as uuid } from "uuid";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const actions = {
  setUser
}

let options = {};

class EditClient extends Component {

  state = {
    ...this.props.user,
    viewAddNew: false,
    client: null,
    admins: []
  }

  componentDidMount() {
    if (!this.props.match.params.id)
      this.props.history.push("/default")
    this.makeCalls();
  }
    

  getClient = () => {
    return axios({
      method: 'get',
      url: '/api/getClientById',
      params: {id: this.props.match.params.id}
    });
  }

  getAdmins = () => {
    return axios({
      method: 'get',
      url: '/api/getAdminsByClient',
      params: {id: this.props.match.params.id}
    });
  }
  
  getCounts = () => {
    return axios({
      method: 'get',
      url: '/api/countClientUsers',
      params: {client: this.props.match.params.id}
    });
  }

  makeCalls = () => {
    axios.all([this.getClient(), this.getAdmins(), this.getCounts()])
    .then(axios.spread((client, admins, counts) => {
      // console.log("counts:", counts.data);
      this.setState({client: client.data[0]});
      this.setState({admins: admins.data});
      this.setState({counts: counts.data})
    }));
  }
  
  toggleNew = (requery) => {
    this.setState({viewAddNew: !this.state.viewAddNew})
    if (requery)
      this.makeCalls();
  }
  
  
  deleteUserConfirm = (user) => {
    let deleteClient = this.deleteClient;
    let message = "You are going to delete " + user.first_name + " " + user.last_name;
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteClient(user._id);
      } 
    });
  }
  
  deleteClient = (id) => {
    let link = '/api/deleteTeacher';
    let message = 'Admin successfully deleted.';
    api({
        method: 'post',
        url: link,
        data: {id}
      }).then(response => {
        console.log("this is the response:", response)
        
          if (response.data !== "fail"){
            NotificationManager.success(message, 'Success');
            this.makeCalls();
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
  }
  printUser = (user) => {

    return (
      <tr key={uuid()}>
          <td>
          <span>{user.first_name} {user.last_name} ({user.email})</span></td>
        <td>
          <MasqueradeIcon userId={user._id}  />
        </td>
        <td>
        <span className="table_icons" onClick={()=>this.deleteUserConfirm(user)}>
          <FontAwesomeIcon  icon={["fal", "trash-alt"]} />
        </span>
        </td>
      </tr>
    )
  }

  render() {
    if (this.state.client === null)
      return null
      
      if (this.state.counts === null)
      return null

    return (
      <div className="side-page">
        <div className="page_header">
         <h1>{this.state.client.full_name} ({this.state.client.short_name})</h1>
         </div>
         <Row>
           <Col md={4}>
             <h2>Administrators {!this.state.viewAddNew ? 
               <FontAwesomeIcon className={"add-icon"} onClick={this.toggleNew} icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> :
               <FontAwesomeIcon className={"add-icon"} onClick={this.toggleNew} icon={["fal", "times"]}  mask={['fas', 'circle']} transform="shrink-7.5" />}
             </h2>
             {!this.state.viewAddNew ? 
               this.state.admins.length === 0 ? <span>Please Add an Admin</span> :
                <table className="table table-striped table-hover business-list-table">
                 <thead className="thead-light">
                   <tr>
                      <th scope="col" width="70%">User</th>
                      <th scope="col" width="15%">Masquerade</th>
                      <th scope="col" width="15%">Delete</th>
                   </tr>
                 </thead>
                 <tbody className="basic_form">
                   {this.state.admins.map(user => (
                     this.printUser(user)
                   ))}
                 </tbody>
               </table> 
               : 
                <div className="basic_form">
                  <AdminForm clientId={this.state.client._id} toggleBack={this.toggleNew} />
                </div>
              }
           </Col>
           <Col md={8} className="chart-container">
              
           </Col>
         </Row>
      </div>
    )
  }
}

export default connect(mapStateToProps, actions)(EditClient);
