import { createSlice } from 'redux-starter-kit'

export const filter = createSlice({
  initialState: {
      focusAreas: [],
      compensation: "",
      radius: ""
  },
  reducers: {
    updateFilter: (state, action) => {
      let thefield = action.payload.field
        return {
          ...state,
          [thefield]: action.payload.value
        }
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = filter
// Extract and export each action creator by name
export const { updateFilter } = actions
// Export the reducer, either as a default or named export
export default reducer
