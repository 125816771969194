import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NotificationManager} from 'react-notifications';
import axios from 'axios'
import { connect } from 'react-redux';
import {setUser, setMasquerade, updateSettings, updateFiles, updateProjects } from '../../ducks';
import swal from 'sweetalert';

const actions = {
	  setUser,
	  setMasquerade,
	  updateSettings,
	  updateFiles,
	  updateProjects
	}

	const mapStateToProps = (state, props) => {
	  return {
		user: state.auth.user,
	  };
	};

class ApproveIcon extends Component {

	state = {
		approved: true,
		id: ""
	  }

	componentDidMount() {
		this.setState({approved: this.props.approved, id: this.props.userId});
	}

	componentDidUpdate(prevProps) {
			if (this.props.userId !== prevProps.userId){
				this.setState({approved: this.props.approved, id: this.props.userId});
			}
		}

	swapApprovalState = (id) => {
		let json = {
		  _id : id,
		}

		let that = this;

		axios({
		  method: 'post',
		  url: '/api/swapApprovalStatus',
		  data: {
			json
		  }
		}).then(function (response) {
		  let newStatus = response.data.approved ? "activated" : "de-activated";
		  that.setState({approved: response.data.approved});
		  NotificationManager.success('User has been successfully ' + newStatus + '.', 'User Change Successfull');
		  if (that.props.callBack)
		  	that.props.callBack();
		});
	  }

	render () {
		return (
		  <span className="table_icons" onClick={() => this.swapApprovalState(this.state.id)} title='Toggle Approval'>
			{this.state.approved === true ? (<FontAwesomeIcon icon={["far", "toggle-on"]} />) : (<FontAwesomeIcon icon={["far", "toggle-off"]} />)}
			</span>
		)
	}
};

class MasqueradeIconConnected extends Component {

	state = {
		id: ""
	}

	componentDidMount(){
		this.setState({id: this.props.userId})
	}

	componentDidUpdate(prevProps) {
		if (this.props.userId !== prevProps.userId){
			this.setState({id: this.props.userId});
		}
	}

	getFiles = () => {
		  return axios({
			method: 'get',
			url: '/api/getUsersFiles',
		  });
		}

		getSettings = () => {
		  return axios({
			method: 'get',
			url: '/api/getSettings',
		  });
		}

		getProjects = () => {
				return axios({
				  method: 'get',
				  url: '/api/getProjectsByStudent',
				});
			  }

	masquerade_user = (user_id) => {
			let setUser = this.props.setUser;
			let adminUser_id = this.props.user._id;
			let updateSettings = this.props.updateSettings;
			let updateFiles = this.props.updateFiles;
			let updateProjects = this.props.updateProjects;
			axios({
			  method: 'post',
			  url: '/auth/masquerade',
			  data : {
				id: user_id,
				password: 'yyz'
			  }
			}).then(response => {
			  let payload = {
				user : response.data.user,
				is_masquerade : "true",
				admin_id : adminUser_id
			  }

			  setUser(payload);

			  // another server call here to set cookies on the server w/masqerade details
			  axios({
				method: 'post',
				url: '/api/user/set_masquerade',
				data : {
				  adminUser_id: adminUser_id,
				}
			  }).then(response => {

				axios.all([this.getSettings(), this.getFiles(), this.getProjects()])
				.then(axios.spread((settings,files,projects) => {
					console.log("I'm on the right file")


				  Promise.all([
						updateSettings(settings.data[0]),
						updateFiles(files.data),
						updateProjects(projects.data)
					  ]).then(() => {
						this.props.history.push("/default");
					  });

				}));


			  })

			});
		  }

	render () {
		return (
			<span className="table_icons" onClick={() => this.masquerade_user(this.state.id)} title='Impersonate User'>
		  		<FontAwesomeIcon icon={["fal", "user-secret"]} />
			</span>
		)
	}
}

class DeleteUserIcon extends Component {
	// state = {
	// 	id: ""
	// }

	// componentDidMount() {
	// 	this.setState({id: this.props.user._id});
	// }

	remove_user = () => {
		let deleteUser = this.deleteUser;
		let message = '';
		if (this.props.user.role === "Business")
    	message = "You are going to permanantly delete " + this.props.user.business_name + ". This will permanantly remove all of their user data.";
		else
			message = "You are going to permanantly delete " + this.props.user.first_name + " " + this.props.user.last_name  + ". This will permanantly remove all of their user data.";;

		swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteUser();
      }
    });

	}

	deleteUser = () => {
		let that = this;
    axios({
			method: 'post',
			url: '/api/deleteUser',
			data: this.props.user,
			context: this,
		}).then(function (response) {
			that.props.callBack(that.props.user._id);

			if (response.data === "success"){
				NotificationManager.success('User was successfully deleted.', 'Success');
			}
			else {
				// NotificationManager.warning("Something went wrong", 'Uh Oh');
				NotificationManager.error(response.data, 'Error', 10000);
			}
		})
  }

	render () {
		return (
			<span className="table_icons" onClick={this.remove_user} title='Delete'>
		  	<FontAwesomeIcon icon={["fal", "trash-alt"]} />
			</span>
		)
	}

}

const printAgreementBtn = (agreement) => {
	return (
	  ((agreement !== null) && (typeof agreement !== "undefined")) ?
		<span className="table_icons" title='View Agreement'>
		  <a href={agreement.path} download target="_self"><FontAwesomeIcon icon={["fal", "file-signature"]} /></a>
		</span>
	  :
		<span className="table_icons" title='No Agreement Uploaded'>
		  <FontAwesomeIcon icon={["fal", "file-signature"]} className="disabled" />
		</span>
	)
}

const printResumeBtn = (file) => {
	return (
	  ((file !== null) && (typeof file !== "undefined")) ?
		<span className="table_icons" title='View Resume'>
		  <a href={file.path} download target="_self"><FontAwesomeIcon icon={["fal", "file-user"]} /></a>
		</span>
	  :
		<span className="table_icons" title='No Resume Uploaded'>
		  <FontAwesomeIcon icon={["fal", "file-user"]} className="disabled" />
		</span>

	)
}

const MasqueradeIcon = withRouter(connect(mapStateToProps, actions)(MasqueradeIconConnected));
export {ApproveIcon, MasqueradeIcon, DeleteUserIcon, printAgreementBtn, printResumeBtn};
