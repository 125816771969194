import React, { Component } from 'react';

//import injectTapEventPlugin from 'react-tap-event-plugin';
import Routes from '../../_routes.jsx'

export default class Main extends Component {

  render() {
    return (
      <main>
        <Routes />
      </main>
    )
  }
}
