import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ContentLoading extends Component {
  state = {
    text: this.props.text || "",
  };

  render() {
    return (
      <div className="content_loader">
        <h3>{this.state.text}</h3>
        <FontAwesomeIcon icon={["far", "circle-notch"]} spin />
      </div>
    );
  }
}
