import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import React, { Component } from 'react';

export default class CropTool extends Component {

  constructor() {
        super()
        this.state = {
            src: null,
            crop: {
                unit: "%",
                width: 30,
                aspect: 1
            },
            croppedImageUrl: null,
        }
    }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
      //this.props.updateCropUrl(croppedImageUrl);
    }
  }

  onImageLoaded = image => {
    this.imageRef = image
  }

  getCroppedImg(image, crop, fileName) {
    console.log("made it here")
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        console.log("I have a blob:", blob);
        blob.name = fileName;
        this.props.updateCropUrl(blob);
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    if (this.props.image === undefined)
    return null;

    const { crop } = this.state

    return (
      <ReactCrop
                src={"/"+this.props.image}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
               />
      )
  }
}
