import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setUser } from '../../ducks';
import axios from 'axios'
import {api} from "../../_helpers"
import Avatar from '@material-ui/core/Avatar';
import swal from 'sweetalert';
import {NotificationManager} from 'react-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ClientForm from './ClientForm';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const actions = {
  setUser
}

class ConnectedAdminternDashboard extends Component {

  state = {
    ...this.props.user,
    viewAddNew: false,
    selectedUser: null,
    clients: []
  }

  componentDidMount() {
    this.makeCalls();
  }

  getClients = () => {
    return axios({
      method: 'get',
      url: '/api/getClients',
    });
  }


  makeCalls = () => {
    var that = this;
    axios.all([this.getClients()])
    .then(axios.spread(function (clients) {
      that.setState({clients: clients.data});
    }));
  }
  
  toggleNew = (requery = false) => {
    this.setState({viewAddNew: !this.state.viewAddNew})
    if (requery)
      this.makeCalls();
  }
  
  edit = (user) => {
    this.props.history.push("/admintern/editClient/"+user._id)
  }
  
  editForm = (user) => {
    this.setState({selectedUser: user, viewAddNew: true})
  }
  
  deleteConfirm = (user) => {
    let deleteClient = this.deleteClient;
    let message = "You are going to delete " + user.full_name;
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteClient(user._id);
      } 
    });
  }
  
  deleteClient = (id) => {
    let link = '/api/deleteClient';
    let message = 'Client successfully deleted.';
    api({
        method: 'post',
        url: link,
        data: {id}
      }).then(response => {
        console.log("this is the response:", response)
        
          if (response.data !== "failt"){
            NotificationManager.success(message, 'Success');
            this.makeCalls();
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
  }
  
  printUser = (user) => {

      
    let obj = user.profile_pic_id_fk;

    return (
      <tr key={user.id}>
          <td>
          {obj ?
            <Avatar className={"admin_avatar sm"} src={obj.path}  /> :
              <Avatar className="admin_avatar sm"><FontAwesomeIcon icon={["fal", "user"]}/></Avatar> }
          </td>
          <td>
          <span className="makeitalink" onClick={() => {this.edit(user)}}>{user.short_name} {user.full_name}</span></td>
        

        <td>
        <span className="table_icons">
          <span className="makeitalink addSomeSpace" onClick={() => {this.editForm(user)}}><FontAwesomeIcon  icon={["fal", "edit"]} /></span>
          <span  onClick={()=>this.deleteConfirm(user)}><FontAwesomeIcon  icon={["fal", "trash-alt"]} /></span>
        </span>
        </td>
      </tr>
    )
  }
  

  render() {

    return (
      <div className="side-page">
        <div className="page_header">
         <h1>Admintern Clients
           <span className="push-right" onClick={this.toggleNew} >
             {!this.state.viewAddNew ? 
               <FontAwesomeIcon icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> :
               <FontAwesomeIcon icon={["fal", "times"]}  mask={['fas', 'circle']} transform="shrink-7.5" /> }
           </span>
         </h1>
         </div>
         {!this.state.viewAddNew ? 
           <table className="table table-striped table-hover business-list-table">
            <thead className="thead-light">
              <tr>
                <th scope="col" width="25px"></th>
                 <th scope="col" width="80%">User</th>
                 <th scope="col" width="75px">Delete</th>
              </tr>
            </thead>
            <tbody className="basic_form">
              {this.state.clients.map(user => (
                this.printUser(user)
              ))}
            </tbody>
          </table> 
          : 
           <div className="basic_form"><ClientForm toggleBack={this.toggleNew} selectedUser={this.state.selectedUser} /></div>
         }
      </div>
    )
  }
}

export default connect(mapStateToProps, actions)(ConnectedAdminternDashboard);
