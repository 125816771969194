import React, { Component } from 'react';
import axios from 'axios'
import {api} from "../../_helpers"
import {NotificationManager} from 'react-notifications';
import {RTEHelper} from '../../_formhelpers'
import Button from '@material-ui/core/Button';
import _ from 'underscore';


export default class OpportunityApply extends Component {

  constructor(props) {
    super(props);

    this.state = {
      db : {},
      cover_letter : '',
      date_application : Date().valueOf(),
      opportunity_id_fk : this.props.match.params.id,
      status : 'New',
      disabled: false
    }
    this.handleRTEChange = this.handleRTEChange.bind(this);
  }

  componentDidMount() {
    this.makeCalls();
  }

  makeCalls = () => {
    axios({
      method: 'get',
      url: '/api/loadOpportunity',
      params: {
        opp_id : this.props.match.params.id,
      }
    }).then((response) => {
      console.log (response.data);
      this.setState(state => ((state.db.opportunity = response.data, state)));
    });
  }

  handleRTEChange(field, value) {
    this.setState({ [field] : value.toString("html") });
  };

  submitApplication(e) {
    this.setState({disabled: true});
    let json = _.omit(this.state, 'db');
    json.opportunity_title = this.state.db.opportunity.opportunity_title;
    // json.student_id_fk
    if (this.state.cover_letter === ""){
      NotificationManager.error('You must provide a cover letter', 'Cover Letter Is Empty');
      this.setState({disabled: false});
    }
    else {
      api({
        method: 'post',
        url: '/api/createApplication',
        data: {
          json
        }
      }).then((response) => {
        NotificationManager.success('Application successfully submitted.', 'Application Successfull');
        this.props.history.push("/applications/list");
      });
    }


    // Meteor.call('createApplication', json, (error) => {
    //   if (error)
    //     alert ("ERROR:" + error.reason);
    //   else {
    //     NotificationManager.success('Application successfully submitted.', 'Save Successfull');
    //     $("html, body").animate({ scrollTop: "0px" });
    //
    //     Meteor.call('incrementApplicants', json.opportunity_id_fk, (error) => {
    //       if (error)
    //         alert ("ERROR:" + error.reason);
    //       else {
    //         //NotificationManager.success('Incremented.', 'Save Successfull');
    //         this.props.history.push("/applications/list");
    //       }
    //     });
    //
    //   }
    // });
  }

  cancelApplication(e) {
    this.props.history.goBack();
  }

  render() {
    const opportunity = this.state.db.opportunity;

    if (opportunity == null)
      return null

    return (
      <div className="side-page">
        <div className="basic_form">

          <div className="page_header">
            <h1>Add A Cover Letter</h1>
            Please use this space below to include a cover letter, or a message to {this.state.db.opportunity.business_id_fk.business_name} in addition to the information you provided on your profile and your resume.
          </div>

          <div className="row">
            <div className="col-12">
              <RTEHelper
                name="cover_letter"
                additionalClass="solo-full"
                onChange={this.handleRTEChange}
                value={this.state.cover_letter}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 footer-buttons">
              <Button type="submit" variant="contained" color="default" className="btn_action" onClick={this.cancelApplication.bind(this)}>
                Cancel
              </Button>
              <Button disabled={this.state.disabled} type="submit" variant="contained" color="primary" className="btn_action" onClick={this.submitApplication.bind(this)}>
                {this.state.disabled ? "Submitting" : "Submit Application"}
              </Button>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
