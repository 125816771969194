export const reorder = (list, startIndex, endIndex) => {
	  const result = Array.from(list);
	  const [removed] = result.splice(startIndex, 1);
	  result.splice(endIndex, 0, removed);
	  return result;
	};
export const getItemStyle = (isDragging, draggableStyle) => ({
	  userSelect: "none",
	  marginBottom: "1.5rem",
	  background: isDragging ? "lightgreen" : "grey",
	  ...draggableStyle
	});
export const getListStyle = isDraggingOver => ({
	  background: isDraggingOver ? "lightblue" : null,
	  width: "100%"
	});