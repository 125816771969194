import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class ResouceForm extends Component {

  state = {
    disabled: false,
    name: "",
    no_delete: true,
    user: this.props.user,
    description: "",
    file: null
  }

  componentDidMount() {
    if (this.props.selectedUser) {
      this.setState({...this.props.settings, disabled: false});
    }
  }
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };
  
  clickInput = event => {
     document.getElementById("fileUpload").click();
   }
   
   handleFileUpload = e => {
     this.setState({file: e.target.files[0]});
     /*e.preventDefault();
     let setUser = this.props.setUser;
     let jsonData = {
       user_id : this.props.user._id,
     }
     let that=this;
     let api = "/api/user_saveFile";
     const formData = new FormData()
     formData.append('fileupload', e.target.files[0]);
     formData.append('description', 'business_agreement_file');
     axios.post("/api/upload_file", formData, {
     }).then(res => {
       console.log(res)
       that.setState({resume_file_id_fk: res.data._id});
       that.setState(state => ((state.db.file = res.data, state)));
       jsonData.file_id = res.data._id;
       jsonData.key = "agreement_file_id_fk";
       console.log("this is jsonData:", jsonData);
       NotificationManager.success('Agreement Successfully Uploaded', 'Successfull');
       axios.post(api, jsonData, {
       }).then(res => {
         axios.get("/api/current-user").then(function(response){
           let payload = {
             user : response.data.user,
             is_masquerade : response.data.is_masquerade,
             admin_id : response.data.admin_id
           }
           setUser(payload);
         })
       });
 
     });*/
   };
  
  saveFile = (end) => {
    if (this.state.name === "" && end){
      this.props.toggleBack(true);
      return 
    }
    let goBack = this.props.toggleBack;
    let link = '/api/upload_file';
    let message = 'Resource Saved';
    this.setState({disabled: true})
    const formData = new FormData()
     formData.append('fileupload', this.state.file);
     formData.append('description', this.props.type + "_resource");
     formData.append('no_delete', true);
     formData.append('name', this.state.name);
    api({
        method: 'post',
        url: link,
        data: formData
      }).then((response) => {
        console.log("this is the response:", response)
        setTimeout(() => {
          if (response.data._id){
            NotificationManager.success(message, 'Success');
            if (end)
              goBack(true);
            else 
              this.setState({name: "", file: null, disabled: false})
          }
          else  {
            NotificationManager.warning("Something Went Wrong", 'Uh Oh');
          }
        }, 0)
          
        
        }).catch(error => {
            NotificationManager.error('Please choose a smaller sized file', 'File too large to upload');
        });
  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.saveFa(false)}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        
        <TextValidator
        name="name" label="File Description" variant="outlined"
        onChange={this.handleChange} value={this.state.name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        
        <>{this.state.file ? this.state.file.name : null}<br /></>
        
        <Button variant="contained" color="default" onClick={this.clickInput} >
          Upload File &nbsp;
          <FontAwesomeIcon icon={["far", "upload"]}/>
        </Button>

        <input
          name="fileUpload"
          id="fileUpload"
          label="Agreement"
          onChange={this.handleFileUpload}
          type="file"
          style={{display:"none"}}
        />
        
        <div className="mobile-center-button">
        <Button type="button" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled} onClick={() => this.saveFile(true)}>
        {this.state.disabled ? 
          <><i class="fal fa-spinner fa-spin"></i>&nbsp;&nbsp;Saving...</> : <>Save</> }
      </Button>

        </div>
        
       </ValidatorForm>
     )
   }
}
