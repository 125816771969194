import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios'
import {api} from "../../_helpers"
import { Link } from 'react-router-dom';
import ld from 'lodash';

// import Project_Report from './Project_Report.jsx';
import ProjectHoursLog from './Project/Project_HoursLog.jsx';
import ProjectQuestions from './Project/Project_Questions.jsx';
import ProjectSummary from './Project/Project_Summary.jsx';
import ProjectFinalProject from './Project/Project_FinalProject.jsx';
import ProjectDashboardBlock from './Project/Project_DashboardBlock.jsx';
import ProjectStudentApplication from './Project/Project_StudentApplication.jsx'
import ProjectStatus from './Project/Project_Status.jsx';
import ProjectEvals from './Project/Project_Evals.jsx';

export default class ProjectReviewWrapper extends Component {

  state = {
    project : null,
    content_type : this.props.match.params.content_type || 'application',
    content_open : false,
  }

  componentDidMount() {
    this.makeCalls();
  }

  makeCalls = () => {
    api({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.id,
      }
    }).then((response) => {
      let rawHours = response.data.hours;
      response.data.hours = ld.orderBy(rawHours, 'start_date', 'asc')
      this.setState(state => ((state.project = response.data, state)));
    });
  }

  loadProjectPart = (page) => {
    let url = '/admin/project/' + this.props.match.params.id + '/' + page;
    document.location = url;
  }

  backToStudentView = () => {
    document.location = '/profile/view/' + this.state.project.student_id_fk._id;
  }

  viewOpportunity = () => {
    document.location = '/opportunity/view/' + this.state.project.opportunity_id_fk._id;
  }

  renderProjectContent = () => {
    switch(this.state.content_type) {
      case "hours":
        return <ProjectHoursLog project={this.state.project} />

      case "reflection":
        return <ProjectQuestions project={this.state.project} />

      case 'summary':
        return <ProjectSummary project={this.state.project} />

      case 'project':
        return <ProjectFinalProject project={this.state.project} />

      case 'application':
        return <ProjectStudentApplication project={this.state.project} />

      case 'status':
        return <ProjectStatus project={this.state.project} />

      case 'evals':
        return <ProjectEvals project={this.state.project} />

      default:
        return null
    }
  }

  renderTitle = () => {
    switch(this.state.content_type) {
      case "hours":
        return "Activity Log"

      case "reflection":
        return "Reflections"

      case 'summary':
        return "Summary"

      case 'project':
        return "Final Project"

      case 'msde':
        return "MSDE"

      case 'evals':
        return "Business Evaluations"

      case 'application':
        return "Student Application"

      case "status":
        return "Internship Status"

      default:
        return null
    }
  }

  loadDetailView = () => {
    this.setState( { content_open : true} );
  }

  closeDetailView = () => {
    this.setState( { content_open : false, content_type : 'application'});
  }

  setDetailView = (panel_type) => {
    this.loadDetailView();
    this.setState( { content_type : panel_type });
    // console.log('set the content!' + panel_type);
  }

  setApproval = (approval_type) => {
    let jsonData = {
      project_id : this.state.project._id,
      approval_type : approval_type,
    }

    console.log(jsonData);

      let that = this;
      axios({
        method: 'post',
        url: '/api/project_setAdminApproval',
        data: {
          jsonData
        }
      }).then(function (response) {
        console.log(response);
        // NotificationManager.success('Approval successfull.', 'Approval Successfull');
        let rawHours = response.data.hours;
        response.data.hours = ld.orderBy(rawHours, 'start_date', 'asc')
        that.setState(state => ((state.project = response.data, state)));
      });
  }

  render() {
    if (this.state.project == null)
      return null

    const project = this.state.project;

    return (
      <div className="side-page">

          <div className="page_header">
            <div className="row">
              <div className="col-md-9">
                <FontAwesomeIcon icon={["fal", "graduation-cap"]} className="business_icon_or_logo"/>
                <div>
                  <h1><Link to={'/profile/view/' + project.student_id_fk._id} title="View Student">{project.student_id_fk.first_name} {project.student_id_fk.last_name}</Link></h1>
                  {/* <h3><Link to={'/profile/view/' + opportunity.business_id_fk._id} title="View Opportunity">{opportunity.business_id_fk.business_name}</Link></h3> */}
                  <h3><Link to={'/opportunity/view/' + project.opportunity_id_fk._id} title="View Opportunity">{project.opportunity_id_fk.opportunity_title}</Link></h3>

                </div>
              </div>
              <div className="col-md-3 apply_container">

              <div className="linkHolder" onClick={() => (this.setDetailView('status'))}>
                <FontAwesomeIcon icon={["fal", "heart-rate"]} />
                <div className="icon-text">Work-Based Learning Status</div>
              </div>

              <div className="linkHolder" onClick={() => (this.setDetailView('application'))}>
                <FontAwesomeIcon icon={["fal", "file-alt"]} />
                <div className="icon-text">View Application</div>
              </div>


              </div>
            </div>
          </div>

          <div className="admin_project_report_container">
            <div className={this.state.content_open? ("admin_project_report_dashboard menu_mode"):("admin_project_report_dashboard")}>
              <ProjectDashboardBlock type="hours" project={this.state.project} content_open={this.state.content_open} setDetailView={this.setDetailView} setApproval={this.setApproval} />
              <ProjectDashboardBlock type="reflection" project={this.state.project} content_open={this.state.content_open} setDetailView={this.setDetailView} setApproval={this.setApproval}/>
              <ProjectDashboardBlock type="summary" project={this.state.project} content_open={this.state.content_open} setDetailView={this.setDetailView} setApproval={this.setApproval}/>
              <ProjectDashboardBlock type="project" project={this.state.project} content_open={this.state.content_open} setDetailView={this.setDetailView} setApproval={this.setApproval}/>
              <ProjectDashboardBlock type="msde" project={this.state.project} content_open={this.state.content_open} setDetailView={this.setDetailView} setApproval={this.setApproval}/>
              <ProjectDashboardBlock type="evals" project={this.state.project} content_open={this.state.content_open} setDetailView={this.setDetailView} setApproval={this.setApproval}/>
            </div>

            <div className={this.state.content_open? ("admin_project_report_detail_panel open"):("admin_project_report_detail_panel")}>
              <div className="admin_project_report_detail_panel_header">
                {this.renderTitle()}
                <div className="aprdph_icons" onClick={this.closeDetailView}>
                  <i className="fal fa-times-circle" ></i>
                </div>
              </div>
              {this.renderProjectContent()}
            </div>
          </div>

      </div>
    )
  }
}
