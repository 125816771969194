import { createSlice } from 'redux-starter-kit'

export const settings = createSlice({
  initialState:  [],
  reducers: {
    updateSettings: (state, action) => {
          return action.payload
        }
    }
  }
)

// Extract the action creators object and the reducer
const { actions, reducer } = settings
// Extract and export each action creator by name
export const { updateSettings } = actions
// Export the reducer, either as a default or named export
export default reducer
