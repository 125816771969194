import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
        useNextVariants: true,
    },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#1d3557',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    danger: {
      light: '#d2645c',
      main: '#d9463e',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will us the default color
  },
});


//https://cimdalli.github.io/mui-theme-generator/

//https://material.io/color/#!/?view.left=0&view.right=0
