import React, { Component} from "react";
import moment from 'moment';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import {api} from "../../../_helpers"
import {NotificationManager} from 'react-notifications';

export default class Project_Status extends Component {

  state = {
    business_status : this.props.project.business_status,
  }

  update_business_status = (new_state) => {
    let json = {
      project_id : this.props.project._id,
      business_status : new_state
    }

    let that = this;

    swal({
      title: "Are you sure?",
      text: 'You are going to mark this work-based learning opportunity as ' + new_state + '.',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        api({
          method: 'post',
          url: '/api/business_internship_status_change',
          data: { json }
        }).then(function (response) {
          NotificationManager.success('Work-Based Learning Opportunity was successfully ' + new_state + ' .', 'Work Based Learning Update Successfull');
          that.setState({business_status : new_state});
        });
      }
    });
  }

  render() {

    return (
      <div>
        <div>
          <div>This work-based learning opportunity was last updated on: {moment(this.props.project.updatedAt).format('MM/DD/YY')}</div>

          <div className="mt-3">The current work-based learning status is: <b><span className="capitalize_it">{this.state.business_status}</span></b></div>
          <hr />
          <div className="mt-3">If you would like to modify the current status of this work-based learning opportunity, please click below.</div>

          <div className="mt-3 admin_internship_status_block">
            <div className="buttons">

                {this.state.business_status !== 'open' ?  (
                  <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={() => this.update_business_status('open')}>
                    Re-Open
                  </Button>
                ) : (
                  <Button type="submit" variant="contained" color="primary" className="btn_action"  disabled={true}>
                    Re-Open
                  </Button>
                )}

                {this.state.business_status !== 'completed' ? (
                  <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={() => this.update_business_status('completed')}>
                    Complete
                  </Button>
                ) : (
                  <Button type="submit" variant="contained" color="default" disabled={true} className="btn_action">
                    Complete
                  </Button>
                )}

                {this.state.business_status !== 'terminated' ? (
                  <Button type="submit" variant="contained" className="btn_action danger" onClick={() => this.update_business_status('terminated')}>
                    Terminate
                  </Button>
                ) : (
                  <Button type="submit" variant="contained" disabled={true} className="btn_action">
                    Terminate
                  </Button>
                )}

            </div>

          </div>

        </div>
      </div>

    )
  }
}
