import React, { Component } from 'react';
import {api} from "../../_helpers"
import moment from 'moment';
import StudentContactLogForm from './StudentContactLogForm.jsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class StudentContactLog extends Component {

  state = {
    logs : null,
  }

  componentDidMount() {
    this.makeCalls();
  }

  makeCalls = () => {
    api({
      method: 'get',
      url: '/api/get_student_contact_log_entries',
      params: {
        student_id : this.props.student_id,
      }
    }).then((response) => {
      this.setState(state => ((state.logs = response.data, state)));
    });
  }

  contact_type = (contact_type) => {
    let dot = <FontAwesomeIcon icon={["far", "circle"]} />

    if (contact_type === 'Phone Call')
      dot = <FontAwesomeIcon icon={["fas", "phone"]} />
    else if (contact_type === 'Email')
      dot = <FontAwesomeIcon icon={["fas", "envelope"]} />
    else if (contact_type === 'Site Visit')
      dot = <FontAwesomeIcon icon={["fas", "building"]} />
    else if (contact_type === 'In-Person Meeting')
      dot = <FontAwesomeIcon icon={["fas", "people-arrows"]} />
    else if (contact_type === 'Other')
        dot = <FontAwesomeIcon icon={["fas", "question"]} />

    return dot;
  }

  render() {

    const logs = this.state.logs;

    if (logs === null) {
      return null
    }

    return (
      <div className="row">
        <div className="col-md-6">
        <StudentContactLogForm student_id={this.props.student_id} callback={this.makeCalls} />
        </div>

        <div className="col-md-6 student_contact_log_listing">
          <div className="student_contact_log_title">Existing Contact Log</div>
          {  logs.map(log => (
            <div className="student_contact_log_entry" key={log._id}>
              <div className="the_date">{moment(log.log_date).format("MM/DD/YYYY")}</div>
              <div className="row log_information">
                <div className="col-6">{this.contact_type(log.contact_type)} <b>{log.primary_contact_name} - {log.contact_relationship}</b></div>
                <div className="col-6 text-right">Business: {log.associated_business}</div>
              </div>
              <div className="log_entry" dangerouslySetInnerHTML={{__html: log.comments}}></div>
            </div>
          )) }
        </div>



      </div>
    )
  }
}
