import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import _ from "underscore";
import axios from "axios";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const ExportCSV = ({csvData, fields, fileName, field_headers, data_manipulate}) => {

	field_headers = field_headers || fields;

	data_manipulate = data_manipulate || null;

	_.mixin({
	  deep: function (obj, key) {

	    var keys = key.split('.'),
	        i = 0,
	        value = null,
	        n = keys.length;

	      while ((obj = obj[keys[i++]]) != null && i < n) {};
	      value = i < n ? void 0 : obj;
	      var result = {};
	      result[key]=value;
	      return result;
	  }
	});

	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const exportToCSV = (csvData, fields, fileName) => {

		let plucked = function(o, wantedPropArray) {
	    return _.reduce(wantedPropArray, function(acc, val){
        acc.push(_.deep(o,val));
        return acc;
	    },[]);
		}

		let desired_field_data = _.map(csvData, function(o){
	    return plucked(o, fields);
		});

		let final_csv = [];
		let csv_row = {};
		_.each(desired_field_data, function (i) {
			csv_row = {};
			_.each(i, function (obj) {
				csv_row = _.extend( csv_row, obj );
			})
			final_csv.push(csv_row);
		})

		const ws = XLSX.utils.json_to_sheet(final_csv);

		// once the worksheet (ws) is set, then this loops through the column headers,
		// and replaces them with the optional field_headers data set

		var range = XLSX.utils.decode_range(ws['!ref']);
		for(var C = range.s.c; C <= range.e.c; ++C) {
		  var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
			// var column = XLSX.utils.encode_col(C);
			var header_id = (XLSX.utils.encode_col(C).charCodeAt(0) - 65);
		  if(!ws[address]) continue;
		  ws[address].v = field_headers[header_id];
		}

		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], {type: fileType});
		FileSaver.saveAs(data, fileName + fileExtension);
	}

	return (
		<div className="linkHolder" onClick={(e) => exportToCSV(csvData,fields,fileName)}>
			<FontAwesomeIcon title="Export" icon={["fal", "file-spreadsheet"]} />
    </div>
	)
}

export const AsyncExportCSV = ({url, fields, fileName, field_headers, data_manipulate}) => {

	field_headers = field_headers || fields;

	data_manipulate = data_manipulate || null;

	_.mixin({
	  deep: function (obj, key) {

		var keys = key.split('.'),
			i = 0,
			value = null,
			n = keys.length;

		  while ((obj = obj[keys[i++]]) != null && i < n) {};
		  value = i < n ? void 0 : obj;
		  var result = {};
		  result[key]=value;
		  return result;
	  }
	});

	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';
	
	const ASYNCexportToCSV = (csvData, fields, fileName) => {
		
	axios.get(url).then(response => {
	  let csvData = response.data;
	  
	  
		  let plucked = function(o, wantedPropArray) {
		  return _.reduce(wantedPropArray, function(acc, val){
		  acc.push(_.deep(o,val));
		  return acc;
		  },[]);
		  }
	  
		  let desired_field_data = _.map(csvData, function(o){
		  return plucked(o, fields);
		  });
	  
		  let final_csv = [];
		  let csv_row = {};
		  _.each(desired_field_data, function (i) {
			  csv_row = {};
			  _.each(i, function (obj) {
				  csv_row = _.extend( csv_row, obj );
			  })
			  final_csv.push(csv_row);
		  })
	  
		  const ws = XLSX.utils.json_to_sheet(final_csv);
	  
		  // once the worksheet (ws) is set, then this loops through the column headers,
		  // and replaces them with the optional field_headers data set
	  
		  var range = XLSX.utils.decode_range(ws['!ref']);
		  for(var C = range.s.c; C <= range.e.c; ++C) {
			var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
			  // var column = XLSX.utils.encode_col(C);
			  var header_id = (XLSX.utils.encode_col(C).charCodeAt(0) - 65);
			if(!ws[address]) continue;
			ws[address].v = field_headers[header_id];
		  }
	  
		  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		  const data = new Blob([excelBuffer], {type: fileType});
		  FileSaver.saveAs(data, fileName + fileExtension);
	  
	})
}

	

	return (
		<div className="linkHolder" onClick={(e) => ASYNCexportToCSV(url,fields,fileName)}>
			<FontAwesomeIcon title="Export" icon={["fal", "file-spreadsheet"]} />
	</div>
	)
}
