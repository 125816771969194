import React, { Component } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import L from "leaflet";
import Slider from "react-slick";
import "leaflet/dist/leaflet.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class MapOpportunities extends Component {
  state = {
    opportunities: [],
    zoom: 11,
  };

  componentDidMount() {
    this.setState({ opportunities: this.props.opportunities });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunities !== this.props.opportunities) {
      this.setState({ opportunities: this.props.opportunities });
    }
  }

  render() {
    let icon = L.icon({
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      iconSize: [24, 36],
      iconAnchor: [12, 36],
      popupAnchor: [0, -30],
    });

    let counterIcon = L.icon({
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      iconSize: [0, 0],
      iconAnchor: [12, 36],
      popupAnchor: [0, -30],
    });

    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    let x = 0;
    let tmpopps = this.state.opportunities;
    let loopopps = this.state.opportunities;
    let usedIds = [];
    let opps = [];

    tmpopps.forEach((tmp) => {
      //make new shaped object if it hasn't been used
      if (tmp.location.lat && !usedIds.includes(tmp._id)) {
        opps.push({
          location: {
            lat: tmp.location.lat,
            lon: tmp.location.lon,
          },
          opps: [tmp],
        });

        //check other opps to see if they match the same location
        loopopps.forEach((loop) => {
          if (loop.location.lat === tmp.location.lat && loop.location.lon === loop.location.lon && loop._id !== tmp._id) {
            // add opp to location
            opps[x].opps.push(loop);
            // remove opp from original loop //remove didn't work
            //tmpopps.filter(el => { return el._id !== loop._id; });
            usedIds.push(loop._id);
          }
        });

        //increment the location counter
        x++;
      }
    });

    console.log("state", this.state.opportunities);
    //if (opps.length > 0)
    console.log("opps", opps);

    return (
      <div className="searchMap">
        <Map center={[this.props.user.location.lat, this.props.user.location.lon]} zoom={this.state.zoom} className="markercluster-map">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {opps.map((opp) =>
            opp.location.lat && opp.opps.length !== 1 ? (
              <>
                <MarkerWithBadge position={[opp.location.lat, opp.location.lon]} icon={counterIcon}>
                  {opp.opps.length.toString()}
                </MarkerWithBadge>
                <Marker position={[opp.location.lat, opp.location.lon]} icon={icon}>
                  <Popup>
                    <div className="sliderHolder">
                      <Slider {...settings}>
                        {opp.opps.map((o) => (
                          <MiniOpportunity opportunity={o} />
                        ))}
                      </Slider>
                    </div>
                  </Popup>
                </Marker>
              </>
            ) : (
              <Marker position={[opp.location.lat, opp.location.lon]} icon={icon}>
                <Popup>
                  <MiniOpportunity opportunity={opp.opps[0]} />
                </Popup>
              </Marker>
            )
          )}
        </Map>
      </div>
    );
  }
}

class MiniOpportunity extends Component {
  state = {
    opportunities: [],
    zoom: 10,
  };

  componentDidMount() {
    this.setState({ opportunity: this.props.opportunity });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunity !== this.props.opportunity) {
      this.setState({ opportunity: this.props.opportunity });
      console.log("path:", this.props.opportunity.business_id_fk.profile_pic_id_fk.path);
    }
  }

  view_opportunity = () => {
    document.location = "/opportunity/view/" + this.state.opportunity._id;
  };

  render() {
    let api = "http://localhost:5008/";
    if (process.env.NODE_ENV !== "development") api = "/";
    return (
      <div className="miniOpportunity">
        <div className="titleAndLogo">
          {this.props.opportunity.business_id_fk.profile_pic_id_fk && <Avatar className="business_avatar sm" src={api + this.props.opportunity.business_id_fk.profile_pic_id_fk.path} />}
          <div>
            <h1>
              {" "}
              <Link to={"/opportunity/view/" + this.props.opportunity._id} title="View Opportunity">
                {this.props.opportunity.opportunity_title}
              </Link>
            </h1>
            <h2>
              <Link to={"/profile/view/" + this.props.opportunity.business_id_fk._id} title="View Opportunity">
                {this.props.opportunity.business_id_fk.business_name}
              </Link>
            </h2>
          </div>
        </div>
        <p>{this.props.opportunity.short_description}</p>
        <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={this.view_opportunity}>
          View Opportunity
        </Button>
      </div>
    );
  }
}

const MarkerWithBadge = (props) => {
  console.log("the props", props.children);
  const initMarker = (ref) => {
    if (ref) {
      const popup = L.popup().setContent(props.children);
      ref.leafletElement
        .addTo(ref.contextValue.map)
        .bindPopup(popup, {
          className: "badge",
          closeOnClick: false,
          autoClose: false,
        })
        .openPopup()
        // prevent badge from dissapearing onClick
        .off("click");
    }
  };
  return <Marker ref={initMarker} {...props} />;
};
