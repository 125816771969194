import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import StudentView from './StudentView';
import BusinessView from './BusinessView';
import AdminProfile from './AdminProfile';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import {setUser } from '../../ducks';

const mapStateToProps = (state, props) => {
  return {
	user: state.auth.user,
	logged_in: state.auth.isAuthenticated,
	default_password: state.settings.default_password
  };
};

const actions = {
  setUser
}

class ConnectedProfileViewWrapper extends Component {

  state = {
	user: null
  }

  getProfile = () => {
	let that = this;
	let id = this.props.match.params.id;
	if (!id){
	  id = this.props.user._id;
	}
	api({
	  method: 'get',
	  url: '/api/user',
	  params: {
		id : id,
	  }
	}).then(function (response) {
	  console.log("get user:", response);
	  if (response.data){
		that.setState({user: response.data})
	  }
	  else {
		console.log("get business error");
	  }

	});
  }

  componentDidMount () {
	this.getProfile();
  }

  swapApprovalState = () => {
	let json = {
	  _id : this.state.user._id,
	}

	let that = this;

	api({
	  method: 'post',
	  url: '/api/swapApprovalStatus',
	  data: {
		json
	  }
	}).then(function (response) {
	  let newStatus = response.data.approved ? "activated" : "de-activated";
	  that.setState({ user: { ...that.state.user, approved: response.data.approved} });

	  NotificationManager.success('User has been successfully ' + newStatus + '.', 'User Change Successfull');
	});
  }

  resetPassword = () => {
	  let default_password = this.props.default_password;
	let json = {
	  _id : this.state.user._id,
	  default_password: default_password
	}

	api({
	  method: 'post',
	  url: '/auth/adminResetPassword',
	  data: {
		json
	  }
	}).then(function (response) {
	  NotificationManager.success('User has been successfully reset to "'+default_password+'".', 'User Password Reset Successfull');
	});
  }

  render() {
	if (this.state.user === null) {
        return null
      }

	return (
	  <div>
		  {this.state.user.role === "Student" ? (
			  <StudentView user={this.state.user} viewer={this.props.user} history={this.props.history} swapApprovalState={this.swapApprovalState} resetPassword={this.resetPassword} />
			) : this.state.user.role === "Business" ? (
			  <BusinessView user={this.state.user} viewer={this.props.user} history={this.props.history} swapApprovalState={this.swapApprovalState} resetPassword={this.resetPassword} />
			) : <AdminProfile />}
	  </div>
	)
  }

}

const ProfileViewWrapper = connect(mapStateToProps, actions)(ConnectedProfileViewWrapper);
export default withRouter(ProfileViewWrapper);
