import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import axios from 'axios'
import {api} from "../../_helpers"
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {NotificationManager} from 'react-notifications';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import _ from "underscore"
import {setUser} from '../../ducks'

const mapStateToProps = (state, props) => {
  return {
	user: state.auth.user
  };
};

const actions = {
  setUser
}

class Password extends Component {

constructor(props) {
  super(props);
  this.state = {
	user: this.props.user._id,
	email: this.props.user.email,
	password: "",
	new_password: "",
	new_password_match: ""
  };

}

handleChange = event => {
  this.setState({ [event.target.name] : event.target.value });
};

changePassword = (e) => {
	//e = $2a$08$EmCh6RmpSygGQ3DFClxwIOF1DKmkJw82NrkoKhC.cD9P3kRQBZTe.
  e.preventDefault();
  if (this.state.new_password !== this.state.new_password_match)
	NotificationManager.error("Passwords Don't Match", 'Try Again', 5000);
  else {
	api({
	  method: 'post',
	  url: '/auth/changePassword',
	  data: {
		email: this.state.email,
		password: this.state.password,
		new_password: this.state.new_password
	  }
	}).then((response) => {
	  console.log("response:", response);
	  if (response.data.message === "success"){
		NotificationManager.success("Log In Info Successfully Changed", 'Thanks!', 5000);
		this.props.history.push("/default");
	  }
	  else if (response.data.message === "wrongPassword") {
		NotificationManager.error("Wrong password entered", 'Password Change Error', 5000);
	  }
	  else if (response.data.message === "emailExists"){
		  NotificationManager.error("Another user already is using that email. Contact an administrator", 'Email Change Error', 5000);
	  }
	});
  }
};

componentDidMount() {
	axios({
	  method: 'get',
	  url: '/api/current-user',
	}).then(user => {
		let payload = {
		  user : user.data.user,
		  is_masquerade : user.data.is_masquerade,
		  admin_id : user.data.admin_id
		}
		this.props.setUser(payload);
	});
	
  ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			if (value !== this.state.new_password) {
				return false;
			}
			return true;
		});
}

componentDidUpdate(prevProps){
	if (!_.isEqual(prevProps.user, this.props.user)){
		this.setState({email: this.props.user.email})
	}
}

  render() {

	return (
	  <div className="login-hero">
		  <Paper className="container_accounts mx-auto" elevation={1}>
			  <h4>Update Log-in Information</h4>
			<ValidatorForm onSubmit={this.changePassword}>
				<TextValidator
					className="inputs"
					variant="outlined"
					label="Current Email"
					placeholder="Current Email"
					type="email"
					name="email"
					value={this.state.email}
					onChange={this.handleChange}
					validators={['required']}
					errorMessages={['email is required']}
				  />	
			
			  <TextValidator
				className="inputs"
				variant="outlined"
				label="Current Password"
				placeholder="Current Password"
				type="password"
				name="password"
				value={this.state.password}
				onChange={this.handleChange}
				validators={['required']}
				errorMessages={['password is required']}
			  />

			<TextValidator
				className="inputs"
				variant="outlined"
				label="New Password"
				placeholder="New Password"
				type="password"
				name="new_password"
				value={this.state.new_password}
				onChange={this.handleChange}
				//validators={['required']}
				//errorMessages={['new password is required']}
			  />

			<TextValidator
				className="inputs"
				variant="outlined"
				label="Match Password"
				placeholder="Match Password"
				type="password"
				name="new_password_match"
				value={this.state.new_password_match}
				onChange={this.handleChange}
				validators={['isPasswordMatch']} errorMessages={['this field must match the other password']}
			  />

			  <br />
			  <Button type="submit" variant="contained" color="primary" className="btn_action">
				Change Log In Information &nbsp; <FontAwesomeIcon icon={["fal", "key"]}/>
			  </Button>
			</ValidatorForm>
		  </Paper>
	  </div>
	)
  }
}

export default connect(mapStateToProps, actions)(Password);
