import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {api} from "../../_helpers"
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OpportunityTable} from './_shared.jsx';
import BootBox from '../Shared/BootBox.jsx';
import {NotificationManager} from 'react-notifications';
import PieChart from '../Shared/Charts';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
  };
};

class ConnectedOpportunityList extends Component {

  state = {
    db: {
      opportunities: [],
    },
    show_delete_modal: false,
    selected_opportunity : null,
  }

  componentDidMount() {
    this.makeCalls();
  }

  getOpportunities = () => {
    return axios({
      method: 'get',
      url: '../api/getOpportunities',
    });
  }
  
  getBusinessCounts = () => {
    return api({
      method: 'get',
      url: '/api/getBusinessInternshipStats',
      params: {client: this.props.user.client, businessId: this.props.user._id}
    })
  }

  makeCalls = () => {
    axios.all([this.getOpportunities(), this.getBusinessCounts()])
    .then(axios.spread((opportunities, businessStats) => {
      this.setState(state => ((state.db.opportunities = opportunities.data, state)));
      this.setState(state => ((state.db.businessStats = businessStats.data, state)));
    }));
  }

  deleteOpportunity = (id) => {
    this.setState({selected_opportunity : id, show_delete_modal : true})
  }

  confirmDelete = () => {
    console.log('delete!');

    let that = this;
    axios({
      method: 'post',
      url: '/api/deleteOpportnity',
      data: {
        id : this.state.selected_opportunity
      }
    }).then(function (response) {
      NotificationManager.success('Opportunity successfully deleted.', 'Successfull');
      that.setState({selected_opportunity : null, show_delete_modal : false}, that.makeCalls())
    });
  }

  handleClose = () => {
    this.setState({selected_opportunity : null, show_delete_modal : false})
  }

  render() {
    return (
      <div className="side-page">
        <div className="page_header"><h1>Manage Opportunities <span className="push-right"><Link to='/opportunity/new'><FontAwesomeIcon icon={["fal", "plus"]}  mask={['fas', 'circle']} transform="shrink-7.5"/></Link></span></h1> </div>
          <div className="row">
              <div className="col-md-0"> 
              {/**
                make above class col-md-3
                <div className="pies">
                        
                      <PieChart title="Available Opportunities" series="Opportunities" data={[
                          {name: "Available", y: this.state.db.businessStats?.avail_open[0]?.count || null},
                          {name: "Closed", y: this.state.db.businessStats?.avail_closed[0]?.count || null},
                        ]} />
                    <PieChart title="Opportunities With Applicants" series="Opportunities" data={[
                        {name: "With Applicants", y: this.state.db.businessStats?.with_applicant[0]?.count || null},
                        {name: "No Applicants", y: this.state.db.businessStats?.zero_applicants[0]?.count || null},
                        
                      ]} />
                      <PieChart title="Application Breakdown" series="Applications" data={[
                          {
                              name: 'Accepted',
                              y: this.state.db.applications?.accepted[0]?.count || null
                          },
                          {
                              name: 'Declined',
                              y: this.state.db.applications?.declined[0]?.count || null
                          },
                          {
                              name: 'New',
                              y: this.state.db.applications?.new[0]?.count || null
                          },
                          {
                              name: 'Offered',
                              y: this.state.db.applications?.offered[0]?.count || null
                          },
                          {
                              name: 'Scheduled',
                              y: this.state.db.applications?.scheduled[0]?.count || null
                          }
                      ]} />
                      <PieChart title="Opportunity Locations" series="Opportunities" data={[
                          {name: "On-Site", y: this.state.db.businessStats?.format_onsite[0]?.count || null},
                          {name: "Virtual", y: this.state.db.businessStats?.format_virtual[0]?.count || null},
                          {name: "Hybrid", y: this.state.db.businessStats?.format_hybrid[0]?.count || null},
                        ]} />
                        <PieChart title="Opportunity Type" series="Opportunities" data={[
                            {name: "Internship", y: this.state.db.businessStats?.type_internship[0]?.count || null},
                            {name: "Apprenticeship", y: this.state.db.businessStats?.type_apprenticeship[0]?.count || null},
                          ]} />
                          <PieChart title="Opportunity Time" series="Opportunities" data={[
                              {name: "Winter", y: this.state.db.businessStats?.time_winter[0]?.count || null},
                              {name: "Spring", y: this.state.db.businessStats?.time_spring[0]?.count || null},
                              {name: "Summer", y: this.state.db.businessStats?.time_summer[0]?.count || null},
                              {name: "Fall", y: this.state.db.businessStats?.time_fall[0]?.count || null},
                            ]} />
                            <PieChart title="Paid Vs Unpaid" series="Opportunities" data={[
                              {name: "Paid", y: this.state.db.businessStats?.paid[0]?.count || null},
                              {name: "Unpaid", y: this.state.db.businessStats?.unpaid[0]?.count || null},
                            ]} />
        
        
                  </div>
                  make below class col-md-9
                  **/}
                  
              </div> 
              
              <div className="col-md-12">
                <div className="col-12 table-responsive-sm">
                  <OpportunityTable data={this.state.db.opportunities} deleteOpportunity={this.deleteOpportunity} />
      
                  <BootBox
                    message="Are you sure you want to delete this opportunity?"
                    show={this.state.show_delete_modal}
                    onYesClick={this.confirmDelete}
                    onNoClick={this.handleClose}
                    onClose={this.handleClose}
                    cancelText="Cancel"
                    confirmText="Delete"
                  />
                </div>
              </div>
              </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ConnectedOpportunityList);
