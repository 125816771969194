import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const actions = {
  /*loginUser,
  logoutUser,
  updateUser*/
}

class ConnectedHome extends Component {

  componentDidMount() {
    //this.getSchools();
  }


  render() {
  if (this.props == null) {
      return null
    }
  return(
  <main className="mainPage">
  			<div className="container-fluid">
  				<section className="jumbotron row" id="top">
  				</section>
          <section className="main-content row">
                <div className="col-md-5">
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="AACountySchools"
                        options={{height: 400}}
                      />
                </div>
                <div className="col-md-7">
                  <h1>Welcome To Admintern</h1>
                  <div className="site-description">{/*Job shadowing and internships provide students with a window to their future by giving them the opportunity to actively engage and partner with businesses and the community. Students work in a variety of capacities with our partners gaining the knowledge and skills that today’s businesses want and need. Through this portal our partners can post opportunities for students and our students can search from a selection of partner internship opportunities. Please create an account or login to view or post opportunities.
                   <br />*/}
                   Site-based work experience opportunities provide students with a window to their future by giving them the opportunity to actively engage and partner with businesses and the community. Students work in a variety of capacities with our partners gaining the knowledge and skills that today’s businesses want and need. Through this portal our partners can post opportunities for students and students can search from a selection of partner work-based learning opportunities. Please create an account or login
                   to view or post opportunities.
                  </div>
                  {/*
                  <Link to="/about" className="push-right fancy">Learn More</Link>
                  */}
                </div>
  				</section>

        </div>
      </main>
)
}
}

const Home = connect(mapStateToProps, actions)(ConnectedHome);
export default withRouter(Home)
