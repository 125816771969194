import React, { Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';

export default class Project_DashboardBlock extends Component {

  render_content = () => {
    let data = this.props.project.reportData;
    let title;
    let html;
    let lup;
    let app;
    let dot = <FontAwesomeIcon icon={["far", "circle"]} />
    let can_approve = false;
    let ts;

    switch (this.props.type) {
      case "hours":
        title = "Activity Log";
        ts = "Last Updated: ";

        if (data.activity_log_status === 1)
          can_approve = true;

        if (data.activity_log_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.activity_log_status === 2){
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />
          ts = "Date of Admin Approval: "
        }

        html = <div className={this.props.content_open? ("apdb_content hidden") : ("apdb_content")}>
            <label>Hours Approved:</label> {data.accepted_hours}
            <br /><label>Hours Submitted:</label> {data.total_hours - data.accepted_hours}
            <br /><label>{ts}</label>
            {typeof(data.hours_last_updated) !== 'undefined' ? (
              moment(data.hours_last_updated).fromNow()
            ) : (
              " Not Started"
            )}

          </div>
      break;

      case "reflection":
        title = "Reflections"
        ts = "Last Updated: ";

        if (data.reflection_status === 1)
          can_approve = true;

        if (data.reflection_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.reflection_status === 2){
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />
          ts = "Date of Admin Approval: "
        }

        html = <div className={this.props.content_open? ("apdb_content hidden") : ("apdb_content")}>
            <label>Questions Answered:</label> {data.reflection_count}
            <br /><label>Total Questions:</label> {this.props.project.reflections.length}
            <br /><label>{ts}</label>
            {typeof(data.reflection_last_updated) !== 'undefined' ? (
              moment(data.reflection_last_updated).fromNow()
            ) : (
              " Not Started"
            )}
          </div>
      break;

      case "project":
        title = "Final Project";

        if (data.final_project_status === 1)
          can_approve = true;

        if (data.final_project_status !== 0) {
          lup = <div><label>Last Updated:</label>
          {typeof(this.props.project.final_project.updatedAt) != 'undefined' ? (
            moment(this.props.project.final_project.updatedAt).format('MM/DD/YYYY, h:mm a')
          ) : (
            " Not Started"
          )}
          </div>

        }

        if (data.final_project_status === 2) {
          app = <div><label>Date of Admin Approval:</label> {moment(this.props.project.final_project.updatedAt).format('MM/DD/YYYY, h:mm a')}</div>
        }

        if (data.final_project_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.final_project_status === 2)
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />

        html = <div className={this.props.content_open? ("apdb_content hidden") : ("apdb_content")}>
            <label>Status:</label> {data.final_project_status === 0 ? ("Incomplete") : (
              data.final_project_status === 1 ? ("Student Submitted") : ("Admin Approved")
            )}
            {lup}
            {app}
          </div>
      break;

      case "summary":
        title = "Final Summary";

        if (data.final_summary_status === 1)
          can_approve = true;

        if (data.final_summary_status !== 0) {
          lup = <div><label>Last Updated:</label> {moment(this.props.project.final_summary.updatedAt).format('MM/DD/YYYY, h:mm a')}</div>
        }

        if (data.final_summary_status === 2) {
          app = <div><label>Date of Admin Approval:</label> {moment(this.props.project.final_summary.updatedAt).format('MM/DD/YYYY, h:mm a')}</div>
        }

        if (data.final_summary_status === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (data.final_summary_status === 2)
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />

        html = <div className={this.props.content_open? ("apdb_content hidden") : ("apdb_content")}>
            <label>Status:</label> {data.final_summary_status === 0 ? ("Incomplete") : (
              data.final_summary_status === 1 ? ("Student Submitted") : ("Admin Approved")
            )}
            {lup}
            {app}
          </div>
      break;

      case "msde":
        title = "MSDE";

        if (data.msde_status === 1)
          can_approve = true;

        html = <div className={this.props.content_open? ("apdb_content hidden") : ("apdb_content")}></div>
      break;

      case "evals":
        title = "Business Evaluations";
        let eval_count = this.props.project.business_evaluations.length;

        if (data.evaluation_status === 1)
          can_approve = true;

        if (eval_count > 0) {
          lup = <div><label>Last Updated:</label> {moment(this.props.project.business_evaluations[eval_count-1].evaluation_date).format('MM/DD/YYYY, h:mm a')}</div>
        }

        if (eval_count === 1)
          dot = <FontAwesomeIcon icon={["fas", "circle"]} />
        else if (eval_count === 2)
          dot = <FontAwesomeIcon icon={["fal", "check-circle"]} />

        html = <div className={this.props.content_open? ("apdb_content hidden") : ("apdb_content")}>
            <label>Status:</label> {eval_count === 0 ? ("No Submissions") : (
              eval_count === 1 ? ("Incomplete") : (
                eval_count === 2 ? ("Complete") : (null)
              )
            )}
            {lup}
          </div>
      break;

      default:
        html = ""
    }


    return (
      <div>
        <div className="apdb_header" onClick={this.props.content_open? () => {this.props.setDetailView(this.props.type)} : (null)}>
          <div>{title}</div>
          <div>{dot}</div>
        </div>

        {html}
        {this.props.content_open? (null) : (
          <div className="apdb_footer">
            <div>
              <button className="btn btn-primary" onClick={() => {this.props.setDetailView(this.props.type)}}> <i className="fal fa-eye"></i> View </button>
            </div>

            <div>
              {can_approve ? (
                <button className="btn btn-success" onClick={() => {this.props.setApproval(this.props.type)}}> <i className="fal fa-check-circle"></i> Approve </button>
              ) : (
                <button className="btn btn-secondary" disabled> <i className="fal fa-check-circle"></i> Approve </button>
              )}
            </div>
          </div>
        )}

      </div>
    )

  }


  render() {
    return (
      <div className="admin_project_dashboard_block">
        {this.render_content()}
      </div>

    )
  }
}
