import React, { Component } from 'react';
import axios from 'axios'
import {api} from "../../_helpers"
import _ from 'underscore';
import ld from 'lodash';

import {ApplicantTable} from './_shared.jsx';

export default class StudentApplications extends Component {

  state = {
    db: {
      applications: null,
    },
  }

  componentDidMount() {
    this.makeCalls();
  }

  getApplicationsByStudent = () => {
    return api({
      method: 'get',
      url: '../api/getMyApplications',
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getApplicationsByStudent()])
    .then(axios.spread(function (applications) {
      that.setState(state => ((state.db.applications = applications.data, state)));
    }));
  }

  updateApplicationStatusForDisplay = (id, status) => {
    let item = ld.find(this.state.db.applications, ['_id', id]);
    item.status = status;
    this.setState(this.state);
  }

  render() {
    if (this.state.db.applications != null) {
      // const applications = this.state.db.applications;

      var app_closed = _.filter(this.state.db.applications, function(app){
        if (app.status !== "New" && app.status !== 'Offered' && app.status !== 'Interview Scheduled')
          return app;
      });

      var app_open = _.filter(this.state.db.applications, function(app){
        if (app.status === "New" || app.status === 'Offered' || app.status === 'Interview Scheduled')
          return app;
      });
    }

    if (this.state.db.applications == null)
      return null

     return (
       <div className="side-page">
         <div className="basic_form">

           <div className="page_header"><h1>Application List</h1></div>

             {this.state.db.applications.length ? (
               <div>

                 {app_open.length ? (
                   <div className="applicant_container">
                     <h3>Open Applications</h3>
                     <ApplicantTable data={app_open} type="open" updateApplicationStatusForDisplay={this.updateApplicationStatusForDisplay} />
                   </div>
                 ) : (null)}


                 {app_closed.length ? (
                   <div className="applicant_container">
                     <h3>Closed Applications</h3>
                     <ApplicantTable data={app_closed} type="closed" updateApplicationStatusForDisplay={this.updateApplicationStatusForDisplay} />
                   </div>
                 ) : (null)}


               </div>

             ) : (
               <div className="row">
                 You do not have any applications. Please click the browse link above to search for an opportunity and then apply.
               </div>
             )}

         </div>
       </div>
     )
   }
}


/*

{applications.length ? (
  <div>

    {app_open.length ? (
      <div className="applicant_container">
        <h3>Open Applications</h3>
        <ApplicantTable data={app_open} type="open" fn_updateStatus={this.updateApplicationStatus} />
      </div>
    ) : (null)}


    {app_closed.length ? (
      <div className="applicant_container">
        <h3>Closed Applications</h3>
        <ApplicantTable data={app_closed} type="closed" />
      </div>
    ) : (null)}


  </div>

) : (
  <div className="row">
    You do not have any applications. Please click the browse link above to search for an opportunity and then apply.
  </div>
)}

*/
