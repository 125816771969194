import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { api } from "../../_helpers";
import Button from "@material-ui/core/Button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder, getListStyle } from "../Shared/DragDrop";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuestionForm from "./QuestionForm";
import swal from "sweetalert";
import _ from "underscore";
import ContentLoading from "../Common/ContentLoading";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

class ReflectionQuestions extends Component {
  state = {
    ...this.props.user,
    viewAddNew: false,
    reordered: false,
    questions: [],
    loading: true,
  };

  componentDidMount() {
    // intentional delay to always show the loading state, instead of a screen blink
    _.delay(() => this.makeCalls(), 500);
  }

  getReflectionQuestions = () => {
    let api = "/api/getMyReflectionQuestions";
    if (this.props.user.role === "SuperAdmin") api = "/api/getDefaultReflectionQuestions";
    return axios({
      method: "get",
      url: api,
    });
  };

  makeCalls = () => {
    axios.all([this.getReflectionQuestions()]).then(
      axios.spread((questions) => {
        this.setState({ questions: questions.data });
        this.setState({ loading: false });
      })
    );
  };

  toggleNew = (requery = false) => {
    this.setState({ viewAddNew: !this.state.viewAddNew });
    if (requery) this.makeCalls();
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const questions = reorder(this.state.questions, result.source.index, result.destination.index);
    console.log("this is the items:", questions);
    if (!_.isEqual(questions, this.state.questions)) {
      console.log("reordered");
      this.setState({
        questions,
        reordered: true,
      });
    }
  };

  deleteQuestion = (id) => {
    let link = "/api/deleteReflectionQuestion";
    let message = "Question successfully deleted.";
    let rerunSearch = this.makeCalls;
    api({
      method: "post",
      url: link,
      data: { id },
    }).then(function (response) {
      console.log("this is the response:", response);

      if (response.data === "success") {
        NotificationManager.success(message, "Success");
        rerunSearch();
      } else {
        NotificationManager.warning("Something went wrong", "Uh Oh");
      }
    });
  };

  deleteQuestionConfirm = (question) => {
    let deleteQuestion = this.deleteQuestion;
    let message = "You are going to delete this question";
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteQuestion(question._id);
      }
    });
  };

  saveReorder = () => {
    let json = this.state.questions;
    let link = "/api/reorderReflectionQuestions";
    let message = "Reorder Successfull.";
    _.each(json, (j, k) => {
      j.ordinal = ++k;
    });
    console.log("new json:", json);
    api({
      method: "post",
      url: link,
      data: {
        json,
      },
    }).then((response) => {
      console.log("this is the response:", response);
      this.setState({ reordered: false });
      if (response.data === "success") {
        NotificationManager.success(message, "Success");
      } else {
        NotificationManager.warning("Something went wrong", "Uh Oh");
      }
    });
  };

  loadDefaultQuestions = () => {
    let makeCalls = this.makeCalls;
    axios({
      method: "get",
      url: "/api/loadDefaultQuestions",
    }).then(function (response) {
      makeCalls();
      NotificationManager.success("You have saved the default questions", "Success");
    });
  };

  render() {
    return (
      <div className="side-page">
        {this.state.loading ? (
          <>
            <div className="page_header">
              <h1>{this.props.user.role === "SuperAdmin" ? "Default" : null} Reflection Questions</h1>
            </div>
            <ContentLoading />
          </>
        ) : (
          <>
            <div className="page_header">
              <h1>
                {this.props.user.role === "SuperAdmin" ? "Default" : null} Reflection Questions
                {this.state.reordered ? (
                  <Button variant="contained" color="primary" className="push-right withExplanation" onClick={this.saveReorder}>
                    <FontAwesomeIcon icon={["fas", "save"]} />
                    Save Reorder
                  </Button>
                ) : (
                  <span className="push-right withExplanation" onClick={this.toggleNew}>
                    {!this.state.viewAddNew ? (
                      <FontAwesomeIcon icon={["fal", "plus"]} mask={["fas", "circle"]} transform="shrink-7.5" />
                    ) : (
                      <FontAwesomeIcon icon={["fal", "times"]} mask={["fas", "circle"]} transform="shrink-7.5" />
                    )}
                  </span>
                )}
              </h1>
              {this.props.user.role === "SuperAdmin" ? (
                <div className="formExplanation">These Questions will be the defaults presented to new clients</div>
              ) : (
                <div className="formExplanation">
                  Reflection questions are presented to students as they complete their work-based learning opportunity. For the default list of questions, click here
                </div>
              )}
            </div>
            {!this.state.viewAddNew ? (
              this.state.questions.length === 0 ? (
                this.props.user.role === "SuperAdmin" ? (
                  <span>Please add default questions with the plus icon above</span>
                ) : (
                  <div className="two-buttons">
                    <span className="first-button">
                      <Button variant="contained" color="primary" onClick={this.toggleNew}>
                        Add Your First Reflection Question
                      </Button>
                    </span>{" "}
                    <Button variant="contained" color="primary" onClick={this.loadDefaultQuestions}>
                      Start with Default Questions
                    </Button>
                  </div>
                )
              ) : (
                <table className="table table-striped table-hover business-list-table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" width="30px"></th>
                      <th scope="col" width="99%">
                        Question
                      </th>
                      <th scope="col" width="30px">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="components">
                      {(provided, snapshot) => (
                        <tbody className="basic_form" {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                          {this.state.questions.map((question, index) => (
                            <Draggable key={question._id} draggableId={question._id} index={index}>
                              {(provided, snapshot) => (
                                <QuestionRow
                                  innerRef={provided.innerRef}
                                  question={question}
                                  provided={provided}
                                  deleteQuestionConfirm={this.deleteQuestionConfirm}
                                  dragHandle={
                                    <span {...provided.dragHandleProps}>
                                      <FontAwesomeIcon icon={["fas", "bars"]} className="draghandle" />
                                    </span>
                                  }
                                />
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
              )
            ) : (
              <div className="basic_form">
                <QuestionForm user={this.props.user} toggleBack={this.toggleNew} api="/api/createReflectionQuestion" />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ReflectionQuestions);

class QuestionRow extends Component {
  render() {
    const { provided, innerRef } = this.props;
    return (
      <tr {...provided.draggableProps} ref={innerRef}>
        <td>{this.props.dragHandle}</td>
        <td>{this.props.question.question_text}</td>
        <td>
          <span className="table_icons" onClick={() => this.props.deleteQuestionConfirm(this.props.question)}>
            <FontAwesomeIcon icon={["fal", "trash-alt"]} />
          </span>
        </td>
      </tr>
    );
  }
}
