import React, { Component } from 'react';
import { connect } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {setUser } from '../../ducks';
import axios from 'axios'
import ProfilePic from '../Layout/ProfilePic';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const actions = {
  setUser
}

class ConnectedAdminProfileWrapped extends Component {

  state = {
    ...this.props.user
  }

  componentDidMount() {
    //this.makeCalls();
  }

  getStates = () => {
    return axios({
      method: 'get',
      url: 'api/getStates',
    });
  }

  getPrograms = () => {
    return axios({
      method: 'get',
      url: 'api/getPrograms',
    });
  }

  getFocusAreas = () => {
    return axios({
      method: 'get',
      url: 'api/getFocusAreas',
    });
  }

  getSchools = () => {
    return axios({
      method: 'get',
      url: 'api/getSchools',
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getSchools(), this.getFocusAreas(), this.getStates(), this.getPrograms()])
    .then(axios.spread(function (schools, focusAreas, states, programs) {
      that.setState(state => ((state.db.schools = schools.data, state)));
      that.setState(state => ((state.db.focusAreas = focusAreas.data, state)));
      that.setState(state => ((state.db.states = states.data, state)));
      that.setState(state => ((state.db.programs = programs.data, state)));
    }));
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  handleMaskChange = event => {
    //let that = this;
    let name = event.target.name;
    if (event.target.value.length > 0)
        this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));

    this.setState({ [event.target.name] : event.target.value });
  };

  handleMaskFocus = event => {
    let name = event.target.name;
    this.setState(state => ((state.db.shrink[name] = true, state)));
  };

  handleMaskBlur = event => {
    let name = event.target.name;
    if (event.target.value.length > 0)
      this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));
  };

  handleNestedChange(event, nestedItem ) {
    let val = event.target.value;
    let name = event.target.name;
    if (typeof this.state[nestedItem] !== "undefined") //if the nested object doesn't exist in state
      this.setState(state => ((state[nestedItem][name] = val, state)));
    else {
      this.setState({[nestedItem]: {}});
      this.setState(state => ((state[nestedItem][name] = val, state)));
    }
  }

 clickInput = event => {
   document.getElementById("fileUpload").click();
 }


  handleCheck = event => {
    this.setState({ [event.target.name] : event.target.checked });
  };

  validate = () => {
    let is_valid = this.refs.form.isFormValid(false);

    if (!is_valid)
      NotificationManager.error('You have not completely filled out this portion of the form.', 'Error');

    return is_valid;
  }

  updateProfile = (e) => {
    e.preventDefault();

    let profile = this.state;
    let setUser = this.props.setUser;

      axios({
        method: 'post',
        url: '/api/user',
        data: {
          profile
        }
      }).then(function (response) {
        if (response.data.errors === undefined  &&  response.data.name === undefined){
          setUser(response.data);
          NotificationManager.success('Profile Updated.', 'Save Successfull');
        }
        else {
          NotificationManager.error('Error Saving.', 'Something Went Wrong');
        }
      });

  }

  render() {

    return (
      <div className="side-page">
      <div className="basic_form">
        <ValidatorForm
          ref="form"
          onSubmit={this.validate}
          onError={errors => console.log(errors)}
          instantValidate={true}
        >
          <div className="inline-block">
            <ProfilePic />
          </div>
          <div className="inline-block student_name">Admin Profile</div>

            <div className="row admin-row">
              <div className="col-md-6">
                  <TextValidator
                    name="first_name" label="First Name" variant="outlined"
                    onChange={this.handleChange} value={this.state.first_name}
                    className="inputs" required={true} validators={['required']} errorMessages={['this field is required']}
                  />
              </div>
                <div className="col-md-5 col-offset-1">

                  <TextValidator
                    name="last_name" label="Last Name" variant="outlined"
                    onChange={this.handleChange} value={this.state.last_name}
                    className="inputs" required={true} validators={['required']} errorMessages={['this field is required']}
                  />
              </div>
            </div>
            {/*<div className="row admin-row">
                <div className="col-md-6">
                  <TextValidator
                    name="email" label="Email" variant="outlined"
                    onChange={this.handleChange} value={this.state.email}
                    className="inputs" required={true} validators={['required', 'isEmail']} errorMessages={['this field is required', 'must be a valid email address']}
                  />
              </div>
              </div>*/}

            <div className="mobile-center-button">
            <Button type="submit" variant="contained" color="primary" className="btn_action" onClick={this.updateProfile.bind(this)}>
              Update Profile
            </Button>
            </div>

        </ValidatorForm>
      </div>
    </div>
    )
  }
}

export default connect(mapStateToProps, actions)(ConnectedAdminProfileWrapped);
