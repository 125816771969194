import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApproveIcon, MasqueradeIcon, DeleteUserIcon } from "./_shared";
import { v4 as uuid } from "uuid";
import ContentLoading from "../Common/ContentLoading";

export default class DuplicateUsersReport extends Component {
  state = {
    schools: [],
    focus_areas: [],
    list: [],
    nonBus: [],
    approved: "All",
    db: {
      schools: [],
      focus_areas: [],
    },
    loading: true,
  };

  componentDidMount() {
    this.makeCalls();
  }

  getDupOthers = () => {
    return axios({
      method: "post",
      url: "/api/findDuplicateUsers",
      data: {
        role: "NotBusiness",
        schools: this.state.schools.length > 0 ? this.state.schools : null,
        focus_areas: this.state.focus_areas.length > 0 ? this.state.focus_areas : null,
        approved: this.state.approved,
      },
    });
  };

  getDupBusiness = () => {
    return axios({
      method: "post",
      url: "/api/findDuplicateUsers",
      data: {
        role: "Business",
        schools: this.state.schools.length > 0 ? this.state.schools : null,
        focus_areas: this.state.focus_areas.length > 0 ? this.state.focus_areas : null,
        approved: this.state.approved,
      },
    });
  };

  getSchools = () => {
    return axios({
      method: "get",
      url: "/api/getSchools",
    });
  };

  getFocusAreas = () => {
    return axios({
      method: "get",
      url: "/api/getFocusAreas",
    });
  };

  makeCalls = () => {
    var that = this;
    axios.all([this.getDupOthers(), this.getDupBusiness(), this.getSchools(), this.getFocusAreas()]).then(
      axios.spread(function (dupOther, dupBusiness, schools, focus_areas) {
        that.setState({ list: dupBusiness.data });
        that.setState({ nonBus: dupOther.data });
        that.setState({ db: { ...that.state.db, schools: schools.data } });
        that.setState({ db: { ...that.state.db, focus_areas: focus_areas.data } });
        that.setState({ loading: false });
      })
    );
  };

  updateList = (list) => {
    this.setState({ list: list });
  };

  printUser = (user) => {
    // console.log("printing: ", user)
    if (!user.createdAt) {
      user.createdAt = moment();
    }

    //let obj = user.files.find(obj => obj.description === "profile_pic_cropped");
    let obj = user.profile_pic_id_fk;

    return (
      <tr key={user.id}>
        <td>
          <Link to={"/profile/view/" + user._id}>
            {obj ? (
              <Avatar className={"business_avatar sm"} src={obj.path} />
            ) : (
              <Avatar className="business_avatar sm">
                <FontAwesomeIcon icon={["fal", "building"]} />
              </Avatar>
            )}
          </Link>
        </td>
        <td>
          <Link to={"/profile/view/" + user._id}>{user.business_name}</Link>
        </td>

        <td>&nbsp;</td>
        <td>
          <ApproveIcon userId={user._id} approved={user.approved} callBack={this.rerunSearch} />
        </td>
        <td>
          <MasqueradeIcon userId={user._id} />
        </td>
        <td>
          <DeleteUserIcon user={user} callBack={this.local_removeUser} />
        </td>
      </tr>
    );
  };

  printNonBusiness = (user) => {
    if (!user.createdAt) {
      user.createdAt = moment();
    }

    let obj = user.profile_pic_id_fk;

    return (
      <tr key={uuid()}>
        <td>
          <Link to={"/profile/view/" + user._id}>
            {obj ? (
              <Avatar className={"studnet_avatar sm"} src={obj.path} />
            ) : (
              <Avatar className="student_avatar sm">
                <FontAwesomeIcon icon={["fal", "graduation-cap"]} />
              </Avatar>
            )}
          </Link>
        </td>
        <td>
          <Link to={"/profile/view/" + user._id}>{user.first_name + " " + user.last_name}</Link>
        </td>
        <td>{user.school_attending}</td>
        <td>
          <ApproveIcon userId={user._id} approved={user.approved} callBack={this.rerunSearch} />
        </td>
        <td>
          <MasqueradeIcon userId={user._id} />
        </td>
        <td>
          <DeleteUserIcon user={user} callBack={this.local_removeUser} />
        </td>
      </tr>
    );
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.rerunSearch);
  };

  rerunSearch = () => {
    this.setState({ loading: true });

    let that = this;
    axios.all([this.getDupOthers(), this.getDupBusiness()]).then(
      axios.spread(function (dupOther, dupBusiness) {
        that.setState({ list: dupBusiness.data });
        that.setState({ nonBus: dupOther.data });
        that.setState({ loading: false });
      })
    );
  };

  local_removeUser = (_id) => {
    this.rerunSearch();
  };

  render() {
    return (
      <div className="side-page">
        <div className="basic_form">
          <div className="page_header with_search no_results">
            <h1>Duplicate Users</h1>
          </div>

          {this.state.loading ? (
            <ContentLoading />
          ) : (
            <>
              {this.state.list.length > 0 ? (
                <div>
                  <div className="row">
                    <div className="col-12 table-responsive-sm">
                      <table className="table table-striped table-hover business-list-table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col" width="30%">
                              Business
                            </th>
                            <th scope="col" width="30%"></th>
                            <th scope="col" width="10%">
                              Approve
                            </th>
                            <th scope="col" width="10%">
                              Masquerade
                            </th>
                            <th scope="col" width="10%">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.state.list.map((user) => this.printUser(user))}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="search_above">There are not any duplicate business accounts.</div>
              )}

              <br />

              {this.state.nonBus.length > 0 ? (
                <div>
                  <div className="row">
                    <div className="col-12 table-responsive-sm">
                      <table className="table table-striped table-hover business-list-table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col" width="30%">
                              Name
                            </th>
                            <th scope="col" width="30%">
                              School
                            </th>
                            <th scope="col" width="10%">
                              Approve
                            </th>
                            <th scope="col" width="10%">
                              Masquerade
                            </th>
                            <th scope="col" width="10%">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.state.nonBus.map((user) => this.printNonBusiness(user))}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="search_above">There are not any duplicate student or teacher accounts.</div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
