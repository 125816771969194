import React, { Component } from "react";
import axios from "axios";
import ld from "lodash";
import moment from "moment";
import { SingleSelect, MultiSelect } from "../../_formhelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";

import InternPanel from "./InternPanel.jsx";

export default class InternList extends Component {
  state = {
    school_attending: [],
    affiliated_program: [],
    selected_year: "All",
    db: {
      schools: [],
      programs: [],
      interns: null,
      raw_intern_data: null,
    },
    graduation_years: [],
    needs_attention: false,
  };

  componentDidMount() {
    this.makeCalls();

    let current_year = moment().year();
    let grad_array = [];
    for (let i = -2; i < 4; i++) {
      grad_array.push(i + current_year);
    }
    grad_array.push("All");

    this.setState({ graduation_years: grad_array });
  }

  getInterns = () => {
    return axios({
      method: "get",
      url: "../api/getInterns",
    });
  };

  getSchools = () => {
    return axios({
      method: "get",
      url: "../api/getSchools",
    });
  };

  getPrograms = () => {
    return axios({
      method: "get",
      url: "../api/getPrograms",
    });
  };

  makeCalls = () => {
    var that = this;
    axios.all([this.getInterns(), this.getSchools(), this.getPrograms()]).then(
      axios.spread(function (interns, schools, programs) {
        that.setState({ db: { ...that.state.db, interns: ld.groupBy(ld.orderBy(ld.orderBy(interns.data, "student_id_fk.last_name"), ["opportunity_id_fk.employment_stop"], ["desc"]), "opportunity_id_fk._id")} });
        that.setState({ db: { ...that.state.db, schools: schools.data} });
        that.setState({ db: { ...that.state.db, programs: programs.data} });
        that.setState({ db: { ...that.state.db, raw_intern_data: interns.data} });
      })
    );
  };

  updateList = (list) => {
    this.setState({ list: list });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.filterResults);
  };

  filterResults = () => {
    let filtered_results = this.state.db.raw_intern_data;
    let selected_year = this.state.selected_year;
    let school_attending = this.state.school_attending;
    let affiliated_program = this.state.affiliated_program;

    if (this.state.selected_year !== "All")
      filtered_results = filtered_results.filter(function (a) {
        return a.student_id_fk.graduation_year === String(selected_year);
      });

    if (school_attending.length > 0)
      filtered_results = filtered_results.filter(function (a) {
        return school_attending.includes(a.student_id_fk.school_attending);
      });

    if (affiliated_program.length > 0)
      filtered_results = filtered_results.filter(function (a) {
        return affiliated_program.includes(a.student_id_fk.affiliated_program);
      });

    console.log(this.state.needs_attention);
    if (this.state.needs_attention === true)
      filtered_results = filtered_results.filter(function (a) {
        let first_unapproved = ld.find(a.hours, ["business_approved", 0]);
        if (typeof first_unapproved !== "undefined") {
          return first_unapproved;
        }
      });

    let grouped_results = ld.groupBy(ld.orderBy(ld.orderBy(filtered_results, "student_id_fk.last_name"), ["opportunity_id_fk.employment_stop"], ["desc"]), "opportunity_id_fk._id");

    this.setState({ db: { ...this.state.db, interns: grouped_results} });

    // console.log(grouped_results);
  };

  filter_attention = () => {
    console.log(this.state.needs_attention);
    this.setState({ needs_attention: !this.state.needs_attention }, this.filterResults);
  };

  print_pending_hours_btn = () => {
    let icon = ["fal", "square"];
    let color = "default";
    if (this.state.needs_attention) {
      icon = ["fas", "check-square"];
      color = "primary";
    }

    return (
      <Button type="submit" variant="contained" color={color} className="btn_action" onClick={this.filter_attention}>
        <FontAwesomeIcon icon={icon} className="mr-3" />
        Pending Hours Only
      </Button>
    );
  };

  render() {
    if (this.state.db.interns == null) return null;

    return (
      <div className="side-page">
        <div className="page_header">
          <div className="row">
            <div className="col-9">
              <h1>Manage Interns</h1>
            </div>
            <div className="col-3 text-right">
              <div>{this.print_pending_hours_btn()}</div>
            </div>
          </div>
        </div>

        <div className="basic_form">
          <div className="filter-on">
            <MultiSelect
              label="Schools"
              name="school_attending"
              onChange={this.handleChange}
              value={this.state.school_attending}
              options={this.state.db.schools.map((s) => {
                return { value: s.name, label: s.name };
              })}
            />

            <MultiSelect
              label="Programs"
              name="affiliated_program"
              onChange={this.handleChange}
              value={this.state.affiliated_program}
              options={this.state.db.programs.map((s) => {
                return { value: s.name, label: s.name };
              })}
            />

            <SingleSelect
              label="Graduation Year"
              name="selected_year"
              onChange={this.handleChange}
              value={this.state.selected_year}
              options={this.state.graduation_years.map((s) => {
                return { value: s, label: s };
              })}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 table-responsive-sm">
            {Object.keys(this.state.db.interns).length > 0 ? (
              Object.keys(this.state.db.interns).map((key) => (
                <div className="mb-3" key={key}>
                  <h3>{this.state.db.interns[key][0].opportunity_id_fk.opportunity_title}</h3>

                  <div className="row">
                    {this.state.db.interns[key].map((intern) => (
                      <div className="col-lg-3 col-sm-6 col-sm-6" key={intern._id}>
                        <InternPanel data={intern} callback={this.makeCalls} />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div>You do not have any interns to manage at this time.</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
