import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';

class Icons extends Component {

    render () {
        return (
          <div className="icons project_icons">
            <NavLink className="" activeClassName="active-mini-link" to={"/project/"+this.props.project+"/hours"}>
              <FontAwesomeIcon icon={["fal", "clock"]} className="sub-icons"/>
            </NavLink>
            <NavLink className="" activeClassName="active-mini-link" to={"/project/"+this.props.project+"/reflections"}>
              <FontAwesomeIcon icon={["fal", "question-circle"]} className="sub-icons"/>
            </NavLink>
            <NavLink className="" activeClassName="active-mini-link" to={"/project/"+this.props.project+"/summary"}>
              <FontAwesomeIcon icon={["fal", "file-alt"]} className="sub-icons"/>
            </NavLink>
            <NavLink className="" activeClassName="active-mini-link" to={"/project/"+this.props.project+"/file"}>
              <FontAwesomeIcon icon={["fal", "upload"]} className="sub-icons"/>
            </NavLink>
          </div>
        )
    }
};



export {Icons}
