import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class InternPanel_Business extends Component {

  state = {
    evaluations : this.props.evaluations
  }

  render() {
    let remaining = 2 - this.state.evaluations.length;
    return (
      <div className="internPanel_evals">
        <div className="evals_line_print">
          Evaluations:
            <React.Fragment>
            <div className="new_eval_btn"><Link to={'/evaluation/new/' + this.props.project_id}><i className="fal fa-plus-circle" title="Create New Evaluation"></i></Link></div>

            <span className="remaining_evals">({remaining > 0 ? remaining : 0} remaining)</span>
            </React.Fragment>
        </div>

        <div className="eval_list">
          {this.state.evaluations.map(evaluation => (
            <div className="list_item" title="View Evaluation"><Link to={'/evaluation/view/' + this.props.project_id + '/' + evaluation._id}>{moment(evaluation.evaluation_date).format("MM/DD/YYYY")}</Link></div>
          ))}
        </div>
      </div>
    )
  }
}
