import React, { Component } from "react";
import moment from "moment";

export default class ProjectEvals extends Component {
  render() {
    return (
      <div>
        <h3>Business Evaluations</h3>

        <div>
          {this.props.project.business_evaluations.length > 0 ? (
            <ul>
              {this.props.project.business_evaluations.map((evaluation) => (
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={"/evaluation/view/" + this.props.project._id + "/" + evaluation._id}>
                    {moment(evaluation.evaluation_date).format("MM/DD/YYYY, h:mm a")}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            "There have not been any business evaluations submitted at this time."
          )}
        </div>
      </div>
    );
  }
}
