import React, { Component } from 'react';

export default class Uhoh extends Component {

  render() {

	return (
		<div className="side-page">
		  <h2>Uhoh</h2>
		  Something Went Wrong. Contact the administrator
	  </div>
	)
  }

}