import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios'
import {ProjectTable} from '../Projects/_ProjectTable'
import {ApplicantTable} from '../Applications/_shared'

import StudentContactLog from './StudentContactLog.jsx';

export default class StudentView extends Component {

  state = {
    show_log : false,
  }

  getApplications = () => {
    return axios({
      method: 'get',
      url: '/api/getApplicationsByStudent',
      params: {
        student_id : this.props.user._id,
      }
    });
  }

  getProjects = () => {
    return axios({
      method: 'get',
      url: '/api/getProjectsByStudentId',
      params: {
        student_id: this.props.user._id
      }
    });
  }

  makeCalls = () => {
    var that = this;
    axios.all([this.getApplications(), this.getProjects()])
    .then(axios.spread(function (applications, projects) {
      that.setState(state => ((state.applications = applications.data, state)));
      that.setState(state => ((state.projects = projects.data, state)));
    }));
  }

  componentDidMount() {
    this.makeCalls();
  }

  masquerade_link = () => {
    let url = '/admin/masquerade/' + this.props.viewer._id + "/" + this.props.user._id;
    this.props.history.push(url);
  }

  printAgreementBtn = () => {
    let isActive = "disabled";
    let daClick = '';
    if (typeof this.props.user.agreement_file_id_fk !== "undefined") {
      isActive = "";
      daClick = this.download_agreement
    }
    return (
      <div className="linkHolder" onClick={daClick}>
        <FontAwesomeIcon icon={["fal", "file-signature"]} className={isActive} />
        <div className="icon-text">Agreement</div>
      </div>
    )
  }

  download_agreement = () => {
    document.location = this.props.user.agreement_file_id_fk.path;
  }

  printResumeBtn = () => {
    let isActive = "disabled";
    let daClick = '';
    if (typeof this.props.user.resume_file_id_fk !== "undefined") {
      isActive = "";
      daClick = this.download_resume
    }
    return (
      <div className="linkHolder" onClick={daClick}>
        <FontAwesomeIcon icon={["fal", "file-user"]} className={isActive} />
        <div className="icon-text">Resume</div>
      </div>
    )
  }

  download_resume = () => {
    document.location = this.props.user.resume_file_id_fk.path;
  }

  show_contact_log = () => {
    this.setState({ show_log : true})
    console.log('set to true');
  }

  hide_contact_log = () => {
    this.setState({ show_log : false})
  }

  render() {
    const user = this.props.user;
    if(user === null)
      return null
    if(this.state.projects === null || this.state.applications === null)
      return null

    return (
      <div className="side-page">

          <div className="page_header">
            <div className="row">
              <div className="col-md-9">
                <FontAwesomeIcon icon={["fal", "graduation-cap"]} className="business_icon_or_logo"/>
                <div>
                  <h1 className="bigger">{user.first_name} {user.middle_name} {user.last_name} {user.pronouns ? <span className="smaller">({user.pronouns})</span> : null}</h1>
                </div>
              </div>
              <div className="col-md-3 apply_container">

                {this.props.viewer.role === "Admin" || this.props.viewer.role === "Teacher"  ? (
                  <>

                  <div className="linkHolder" onClick={this.props.swapApprovalState}>
                    {user.approved === true ? (<FontAwesomeIcon icon={["far", "toggle-on"]} />) : (<FontAwesomeIcon icon={["far", "toggle-off"]} />)}
                    <div className="icon-text">
                    {user.approved === true ? ("Activated") : ("De-Activated")}
                    </div>
                  </div>

                  <div className="linkHolder" onClick={this.masquerade_link}>
                    <FontAwesomeIcon icon={["fas", "user-secret"]} />
                    <div className="icon-text">Masquerade</div>
                  </div>

                  <div className="linkHolder" onClick={this.props.resetPassword}>
                    <FontAwesomeIcon icon={["fas", "key"]} />
                    <div className="icon-text">Reset PW</div>
                  </div>

                  <div className="linkHolder" onClick={this.show_contact_log}>
                    <FontAwesomeIcon icon={["fas", "comments"]} />
                    <div className="icon-text">Contact Log</div>
                  </div>

                  <div className="linkHolder" onClick={this.hide_contact_log}>
                    <FontAwesomeIcon icon={["fas", "info-circle"]} />
                    <div className="icon-text">Student Info</div>
                  </div>

                  {this.printAgreementBtn()}
                  </>
                ) : (null)}

                {this.printResumeBtn()}

              </div>
            </div>
          </div>

          <div className="row">

          {this.state.show_log ? (
            <div className="col-12">
              <StudentContactLog  student_id={this.props.user._id} />
            </div>
          ) : (
            <>
            <div className="col-4 opportunity_view_side">

              {this.props.viewer.role === "Admin" || this.props.viewer.role === "Teacher" ? (
                <>
                <br /><FontAwesomeIcon icon={["fal", "key-skeleton"]} className="little-icons" /><b>Account Login: {user.email}</b><br />
                </>
              ) : (null)}


              {user.__v === 0 ? (null) : (
                <>
                <br /><FontAwesomeIcon icon={["fal", "envelope"]} className="little-icons" /><a href={"mailto:"+user.email}>{user.email}</a>
                <br /><FontAwesomeIcon icon={["fal", "phone-square"]} className="little-icons" /><a href={"tel:"+user.home_phone}>{user.home_phone}</a>
                <br /><FontAwesomeIcon icon={["fal", "phone-square"]} className="little-icons" /><a href={"tel:"+user.cell_phone}>{user.cell_phone}</a>
                <br /><br /><FontAwesomeIcon icon={["fal", "user"]} className="little-icons" /> {user.parent_name}
                <br /><FontAwesomeIcon icon={["fal", "phone-square"]} className="little-icons" /><a href={"tel:"+user.parent_phone}>{user.parent_phone}</a>
                <br /><br /><FontAwesomeIcon icon={["fal", "user"]} className="little-icons" /> {user.alternate_parent_name}
                <br /><FontAwesomeIcon icon={["fal", "phone-square"]} className="little-icons" /><a href={"tel:"+user.alternate_parent_phone}>{user.alternate_parent_phone}</a>
                </>
              )}

            </div>
            <div className="col-8 opportunity_view">

              {user.__v === 0 ? (
                <div>
                  <h2>User has not yet completed their profile.</h2>
                </div>
              ) : (
                  <>
                  <div className="top_section row">
                    <div className="col-md-4">
                      <FontAwesomeIcon icon={["far", "address-card"]} className="big-icons" /> {user.student_id}
                    </div>
                    <div className="col-md-8">
                      <FontAwesomeIcon icon={["far", "bus-school"]} className="big-icons" /> {user.school_attending} class of {user.graduation_year}
                    </div>
                  </div>

                  <h1>Program</h1>
                  {user.affiliated_program}
                  <h1>Focus Areas</h1>
                  {user.focus_areas.map(function(fa, index)
                    {
                      return <span className="focus_area_list" key={index}>{fa}</span>;
                    })}

                  {this.state.projects ?
                      this.state.projects.length > 0 ?
                      <>
                      <h1>Active Work-Based Learning Opportunities</h1>
                       <ProjectTable projects={this.state.projects} user_role={this.props.viewer.role} /> </>
                      : null : null 
                  }
                  {this.state.applications ?
                      this.state.applications.length > 0 ? 
                       <><h1>Applications</h1>
                       <ApplicantTable data={this.state.applications} type="closed" user_role={this.props.viewer.role} /></>
                    : null : null }

                  </>
              )}

            </div>
            </>
          )}


          </div>

      </div>
    )
  }
}
