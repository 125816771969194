import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    logged_in: state.auth.isAuthenticated
  };
};

class ConnectedPermissionsError extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };

  }

  render() {
    if (this.props == null) {
        return null
      }
    else if (this.props.user) {
      console.log("this is the user:", this.props.user, this.props.logged_in)
    }
  

    return (
        <div className="side-page">
          <h2>Something Went Wrong</h2>
          You are unauthorized to access this page. If you think you got here in error, contact an administrator.
      </div>
    )
  }

}

const PermissionsError = connect(mapStateToProps)(ConnectedPermissionsError);
export default withRouter(PermissionsError);
