import { createSlice } from 'redux-starter-kit'

export const errors = createSlice({
  initialState: {},
  reducers: {
    getErrors: (state, action) => {
      return action.payload;
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = errors
// Extract and export each action creator by name
export const { getErrors } = actions
// Export the reducer, either as a default or named export
export default reducer
