import { configureStore } from 'redux-starter-kit'
import { combineReducers } from 'redux'
import { auth, filter, projects, files, settings} from "../ducks";
//import rootReducer from './reducers'

const rootReducer = combineReducers({
  //user: user.reducer,
  auth: auth.reducer,
  filter: filter.reducer,
  projects: projects.reducer,
  files: files.reducer,
  settings: settings.reducer
  //visibilityFilter: visibilityFilter.reducer
});

export const store = configureStore({
  reducer: rootReducer
});
