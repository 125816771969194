import React, { Component} from "react";

export default class Project_Summary extends Component {

  render() {

    return (
      <div>
        <h3>Student Summary</h3>
        <div dangerouslySetInnerHTML={{__html: this.props.project.final_summary.text}}>

        </div>
      </div>

    )
  }
}
