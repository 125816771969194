//import ReactCrop from 'react-image-crop'
//import 'react-image-crop/dist/ReactCrop.css'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {api} from "../../_helpers"
import Avatar from '@material-ui/core/Avatar';
import {setUser } from '../../ducks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CropModal from '../Shared/CropModal.jsx';
import {NotificationManager} from 'react-notifications';

const mapStateToProps = (state, props) => {
  return {
    user_id: state.auth._id,
    authenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    files: state.files
  };
};

const actions = {
  setUser
}

class ProfilePic extends Component {

  state = {
    cropToolShow: false
  }

  uploadNewPic = (e) => {
      e.preventDefault();

      let description = "profile_pic_full";
      let key = "user_id_fk";
      const formData = new FormData()
      formData.append('fileupload', e.target.files[0]);
      formData.append('description', description);
      formData.append('key', key);

      api.post("/api/upload_file", formData, {
      }).then(res => {
        console.log("what's the res:", res);
        this.setState({profile_pic_path: res.data.path});
        this.setState({cropToolShow: true})

      }).catch(error => {
          NotificationManager.error('Please choose a smaller sized file', 'File too large to upload');
      });
    };


  clickInput = (field) => {
    document.getElementById(field).click();
  }

  handleClose = () => {
    this.setState({cropToolShow: false});
  }

  confirmCrop = () => {
    console.log("confirmed crop");
  }

  render() {
    if (this.props.user === undefined)
      return null;

    let obj = this.props.files.find(obj => obj.description === "profile_pic_cropped");

    return (
      <>

      <span className="cursor" onClick={() => this.clickInput("profilePicUpload")}>
      {obj ?
        <Avatar className={this.props.user.role.toLowerCase()+"_avatar"} src={obj.path}  /> :
          this.props.user.role === "Student" ?
            <><Avatar className="student_avatar"><FontAwesomeIcon icon={["fal", "graduation-cap"]}/></Avatar>
            <div className="add-pic-text">Add Profile Pic</div>
            </>
            : <> <Avatar className="business_avatar"><FontAwesomeIcon icon={["fal", "building"]}/></Avatar><div className="add-pic-text">Add your logo</div> </>}
          </span>

      <input
        name="profilePicUpload"
        id="profilePicUpload"
        label="Profile Pic"
        onChange={this.uploadNewPic}
        type="file"
        style={{display:"none"}}
      />

    <CropModal
        message="test crop"
        show={this.state.cropToolShow}
        onYesClick={this.confirmCrop}
        onNoClick={this.handleClose}
        onClose={this.handleClose}
        cancelText="Cancel"
        confirmText="Crop"
        image={this.state.profile_pic_path}
      />
      </>
      )
  }

}

export default connect(mapStateToProps, actions)(ProfilePic);
