import React, { Component } from "react";
import MapOpportunities from "../Layout/MapOpportunities";

export default class BrowseMap extends Component {
  state = {
    opportunities: [],
  };

  componentDidMount() {
    this.setState({ opportunities: this.props.opportunities });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunities !== this.props.opportunities) {
      this.setState({ opportunities: this.props.opportunities });
    }
  }

  render() {
    return this.state.opportunities.length > 0 && <MapOpportunities user={this.props.user} opportunities={this.state.opportunities} />;
  }
}
