import React, { Component } from 'react';
import axios from 'axios'
import ld from 'lodash';

import Reflections from './Reflections.jsx';
import { Icons } from './icons';

export default class ReflectionPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page : "reflections",
      initial_question : null,
    }
  }

  componentDidMount() {
    this.makeCalls();
  }

  makeCalls = () => {
    var that = this;
    axios({
      method: 'get',
      url: '/api/loadStudentProject',
      params: {
        project_id : this.props.match.params.id,
      }
    }).then(function (response) {
      let rawHours = response.data.hours;
      response.data.hours = ld.orderBy(rawHours, 'start_date', 'asc');

      let first_available = ld.find(response.data.reflections, {status : "unlocked"})
      first_available = (typeof(first_available) == 'undefined') ? false : first_available.ordinal;
      that.setState(state => ((state.initial_question = first_available)));

      that.setState(state => ((state.project = response.data, state)));
    });
  }

  updateReflectionQuestion = (id) => {
    let question = ld.find(this.state.project.reflections, {_id : id});
    question.status = 'complete';
    this.setState(this.state, this.openNextQuestion);
  }

  openNextQuestion = () => {
    let first_available = ld.find(this.state.project.reflections, {status : "unlocked"})
    if (typeof(first_available) == 'undefined')
      first_available = false;

    this.setState({ initial_question : first_available.ordinal});
  }

  render() {
    const project = this.state.project;

    if (project == null) {
      return null
    }

    return (
      <div className="side-page">
        <div className="basic_form">

          <div className="page_header">
            <div className="row">
              <div className="col-md-10">
                <div>
                  <h1 className="bigger">{this.state.project.opportunity_id_fk.opportunity_title}</h1>
                </div>
              </div>
              <div className="col-md-2 apply_container">
                <Icons project={this.props.match.params.id}/>
              </div>
            </div>
          </div>
          <div className="sub-header">Reflections</div>

          <div className="row">
            <div className="col-md-10 offset-md-1">
              <Reflections
                projectId={this.props.match.params.id}
                reflections={project.reflections}
                initial_question={this.state.initial_question}
                updateReflectionQuestion={this.updateReflectionQuestion}
                approval_state={this.state.project.reportData.reflection_status}
              >
              </Reflections>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
