import React, { Component } from "react";
import axios from "axios";
import { api } from "../../_helpers";
import { Link } from "react-router-dom";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SingleSelect, MultiSelect } from "../../_formhelpers";
import { ApproveIcon, MasqueradeIcon, DeleteUserIcon, printAgreementBtn, printResumeBtn } from "./_shared";
import { ExportCSV } from "../Shared/ExcelExport";
import { v4 as uuid } from "uuid";
import ContentLoading from "../Common/ContentLoading";

export default class StudentReport extends Component {
  state = {
    school_attending: [],
    affiliated_program: [],
    list: [],
    graduation_years: [],
    selected_year: moment().month() > 7 ? moment().year()+1 : moment().year(),    
    approved: "Approved",
    db: {
      schools: [],
      programs: [],
    },
    loading: true,
  };

  componentDidMount() {
    this.makeCalls();
    let current_year = moment().year();
    let grad_array = [];
    for (let i = -2; i < 4; i++) {
      grad_array.push(i + current_year);
    }
    grad_array.push("All");

    this.setState({ graduation_years: grad_array });
  }

  getAllUsers = () => {
    return api({
      method: "post",
      url: "/api/filterUsers",
      data: {
        role: "Student",
        school_attending: this.state.school_attending.length > 0 ? this.state.school_attending : null,
        affiliated_program: this.state.affiliated_program.length > 0 ? this.state.affiliated_program : null,
        approved: this.state.approved,
        graduation_year: this.state.selected_year,
      },
      timeout: 5000,
    });
  };

  getSchools = () => {
    return axios({
      method: "get",
      url: "/api/getSchools",
    });
  };

  getPrograms = () => {
    return axios({
      method: "get",
      url: "/api/getPrograms",
    });
  };

  makeCalls = () => {
    axios.all([this.getAllUsers(), this.getSchools(), this.getPrograms()]).then(
      axios.spread((users, schools, programs) => {
        this.setState({ list: users.data });
        this.setState({ db: { ...this.state.db, schools: schools.data } });
        this.setState({ db: { ...this.state.db, programs: programs.data } });
        this.setState({ loading: false });
      })
    );
  };

  updateList = (list) => {
    this.setState({ list: list });
  };

  printUser = (user) => {
    if (!user.createdAt) {
      user.createdAt = moment();
    }

    let obj = user.profile_pic_id_fk;

    return (
      <tr key={uuid()}>
        <td>
          <Link to={"/profile/view/" + user._id}>
            {obj ? (
              <Avatar className={"studnet_avatar sm"} src={obj.path} />
            ) : (
              <Avatar className="student_avatar sm">
                <FontAwesomeIcon icon={["fal", "graduation-cap"]} />
              </Avatar>
            )}
          </Link>
        </td>
        <td>
          <Link to={"/profile/view/" + user._id}>{user.first_name + " " + user.last_name}</Link>
        </td>
        <td>{user.school_attending}</td>
        <td>{user.graduation_year}</td>
        <td>{user.affiliated_program}</td>
        {/* <td>{moment().format("YYYY") - moment(user.createdAt).format("YYYY") + 1}</td> */}

        <td style={{ textAlign: "right" }}>
          <ApproveIcon userId={user._id} approved={user.approved} callBack={this.rerunSearch} />
          {printAgreementBtn(user.agreement_file_id_fk)}
          {printResumeBtn(user.resume_file_id_fk)}
          <MasqueradeIcon userId={user._id} />
          <DeleteUserIcon user={user} callBack={this.local_removeUser} />
        </td>
      </tr>
    );
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.rerunSearch);
  };

  rerunSearch = () => {
    this.setState({ loading: true });
    axios.all([this.getAllUsers()]).then(
      axios.spread((users) => {
        this.setState((state) => ((state.list = users.data), state));
        this.setState({ loading: false });
      })
    );
  };

  local_removeUser = (_id) => {
    this.rerunSearch();
  };

  render() {
    return (
      <div className="side-page">
        <div className="basic_form">
          <div className="page_header with_search no_results">
            <h1>Students</h1>
            <ExportCSV
              csvData={this.state.list}
              fields={["first_name", "last_name", "email", "cell_phone", "school_attending", "graduation_year", "affiliated_program", "parent_name", "parent_phone"]}
              field_headers={["first name", "last name", "email", "cell phone", "school", "graduation year", "program", "parent name", "parent phone"]}
              fileName="student_report"
            />
          </div>
          <div className="filter-on-4">
            <MultiSelect
              label="Schools"
              name="school_attending"
              onChange={this.handleChange}
              value={this.state.school_attending}
              options={this.state.db.schools.map((s) => {
                return { value: s.name, label: s.name };
              })}
            />
            <MultiSelect
              label="Programs"
              name="affiliated_program"
              onChange={this.handleChange}
              value={this.state.affiliated_program}
              options={this.state.db.programs.map((s) => {
                return { value: s.name, label: s.name };
              })}
            />
            <SingleSelect
              label="Approved"
              name="approved"
              onChange={this.handleChange}
              value={this.state.approved}
              options={[
                { value: "All", label: "All Students" },
                { value: "Approved", label: "Approved Students" },
                { value: "Unapproved", label: "Unapproved Students" },
              ]}
            />
            <SingleSelect
              label="Graduation Year"
              name="selected_year"
              onChange={this.handleChange}
              value={this.state.selected_year}
              options={this.state.graduation_years.map((s) => {
                return { value: s, label: s };
              })}
            />
          </div>
          {this.state.loading ? (
            <ContentLoading />
          ) : this.state.list.length > 0 ? (
            <div>
              <div className="row">
                <div className="col-12 table-responsive-sm">
                  <table className="table table-striped table-hover business-list-table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col" width="20%">
                          Student
                        </th>
                        <th scope="col" width="20%">
                          School
                        </th>
                        <th scope="col" width="10%">
                          Grad Year
                        </th>
                        <th scope="col" width="20%">
                          Program
                        </th>
                        {/* <th scope="col" width="5%">
                          Years Active
                        </th> */}
                        <th scope="col" width="30%" style={{ textAlign: "right" }}>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.state.list.map((user) => this.printUser(user))}</tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="search_above">No Students Match that Filter</div>
          )}
        </div>
      </div>
    );
  }
}
