import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import StudentProfile from './StudentProfile';
import StudentProfileAlerts from './StudentProfileAlerts';
import StudentProfileOldMap from './StudentProfileOldMap'
import BusinessProfile from './BusinessProfile';
import AdminProfile from './AdminProfile';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    logged_in: state.auth.isAuthenticated
  };
};

class ConnectedProfileWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };

  }

  render() {
    if (this.props == null) {
        return null
      }

    return (
      <div>
          {this.props.user.role === "Student" ? (
              this.props.match.params.test === "test" ? (<StudentProfileAlerts />) : this.props.match.params.test === "maps" ? (<StudentProfileOldMap />) : (<StudentProfile />)
              
            ) : this.props.user.role === "Business" ? (
              <BusinessProfile />
            ) : <AdminProfile />}
      </div>
    )
  }

}

const ProfileWrapper = connect(mapStateToProps)(ConnectedProfileWrapper);
export default withRouter(ProfileWrapper);
