import React, { Component } from "react";
import axios from "axios";
import { api } from "../../_helpers";
import Avatar from "@material-ui/core/Avatar";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setUser, setMasquerade } from "../../ducks";
import { MasqueradeIcon } from "../Admin/_shared";
import { connect } from "react-redux";
import AdminForm from "./AdminForm";
import { MultiSelect } from "../../_formhelpers";
import swal from "sweetalert";
import _ from "underscore";
import { v4 as uuid } from "uuid";
import ContentLoading from "../Common/ContentLoading";

const actions = {
  setUser,
  setMasquerade,
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

class AdminManager extends Component {
  state = {
    selectedUser: null,
    viewAddNew: false,
    clearUser: false,
    list: [],
    schools: [],
    programs: [],
    approved: "All",
    loading: true,
    db: {
      schools: [],
      programs: [],
    },
  };

  componentDidMount() {
    // intentional delay to always show the loading state, instead of a screen blink
    _.delay(() => this.makeCalls(), 500);
  }

  getAllUsers = () => {
    return axios({
      method: "post",
      url: "/api/filterUsers",
      data: {
        role: ["Teacher"],
        schools: this.state.schools.length > 0 ? this.state.schools : null,
        programs: this.state.programs.length > 0 ? this.state.programs : null,
        approved: this.state.approved,
      },
    });
  };

  getSchools = () => {
    return axios({
      method: "get",
      url: "/api/getSchools",
    });
  };

  getPrograms = () => {
    return axios({
      method: "get",
      url: "/api/getPrograms",
    });
  };

  makeCalls = () => {
    var that = this;
    axios.all([this.getAllUsers(), this.getSchools(), this.getPrograms()]).then(
      axios.spread(function (users, schools, programs) {
        that.setState({ loading: false });
        that.setState((state) => ((state.list = users.data), state));
        that.setState((state) => ((state.db.schools = schools.data), state));
        that.setState((state) => ((state.db.programs = programs.data), state));
        if (that.props.match.params.id && !that.state.clearUser) {
          that.setState({ clearUser: true });
          that.edit(_.find(users.data, { _id: that.props.match.params.id }));
        }
      })
    );
  };

  toggleNewAdmin = (requery) => {
    console.log("clicked");
    this.setState({ viewAddNew: !this.state.viewAddNew, selectedUser: null });
    if (requery) this.makeCalls();
  };

  edit = (user) => {
    this.setState({ selectedUser: user, viewAddNew: true });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.rerunSearch);
  };

  rerunSearch = () => {
    let that = this;
    axios.all([this.getAllUsers()]).then(
      axios.spread(function (users) {
        that.setState((state) => ((state.list = users.data), state));
      })
    );
  };

  deleteUserConfirm = (user) => {
    let deleteAdmin = this.deleteAdmin;
    let message = "You are going to delete " + user.first_name + " " + user.last_name;
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAdmin(user._id);
      }
    });
  };

  /* note for next week
  I need to make the profile page and potentially profile pic for admin work
  */

  deleteAdmin = (id) => {
    let link = "/api/deleteTeacher";
    let message = "Admin successfully deleted.";
    let rerunSearch = this.rerunSearch;
    api({
      method: "post",
      url: link,
      data: { id },
    }).then(function (response) {
      console.log("this is the response:", response);

      if (response.data === "success") {
        NotificationManager.success(message, "Success");
        rerunSearch();
      } else {
        NotificationManager.warning("Something went wrong", "Uh Oh");
      }
    });
  };

  printUser = (user) => {
    let obj = user.profile_pic_id_fk;

    return (
      <tr key={uuid()}>
        <td>
          {obj ? (
            <Avatar className={"admin_avatar sm"} src={obj.path} />
          ) : (
            <Avatar className="admin_avatar sm">
              <FontAwesomeIcon icon={["fal", "user"]} />
            </Avatar>
          )}
        </td>
        <td>
          <span
            className="makeitalink"
            onClick={() => {
              this.edit(user);
            }}
          >
            {user.first_name} {user.last_name}
          </span>
        </td>

        <td>
          <MasqueradeIcon userId={user._id} />
        </td>
        <td>
          <span className="table_icons" onClick={() => this.deleteUserConfirm(user)}>
            <FontAwesomeIcon icon={["fal", "trash-alt"]} />
          </span>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <div className="side-page">
        <div className="basic_form">
          {this.state.loading ? (
            <>
              <div className="page_header">
                <h1>Manage Teachers</h1>
              </div>
              <ContentLoading />
            </>
          ) : (
            <>
              <div className="page_header">
                <h1>
                  Manage Teachers
                  <span className="push-right" onClick={this.toggleNewAdmin}>
                    {!this.state.viewAddNew ? (
                      <FontAwesomeIcon icon={["fal", "plus"]} mask={["fas", "circle"]} transform="shrink-7.5" />
                    ) : (
                      <FontAwesomeIcon icon={["fal", "times"]} mask={["fas", "circle"]} transform="shrink-7.5" />
                    )}
                  </span>
                </h1>
              </div>
              {!this.state.viewAddNew ? (
                <div className="filter-on">
                  <MultiSelect
                    label="Schools"
                    name="schools"
                    onChange={this.handleChange}
                    value={this.state.schools}
                    options={this.state.db.schools.map((s) => {
                      return { value: s.name, label: s.name };
                    })}
                  />
                  <MultiSelect
                    label="Programs"
                    name="programs"
                    onChange={this.handleChange}
                    value={this.state.programs}
                    options={this.state.db.programs.map((s) => {
                      return { value: s.name, label: s.name };
                    })}
                  />
                </div>
              ) : null}
              <div>
                <div className="row">
                  <div className="col-12 table-responsive-sm">
                    {!this.state.viewAddNew ? (
                      <table className="table table-striped table-hover business-list-table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col" width="70%">
                              User
                            </th>
                            <th scope="col" width="15%">
                              Masquerade
                            </th>
                            <th scope="col" width="15%">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.state.list.map((user) => this.printUser(user))}</tbody>
                      </table>
                    ) : (
                      <AdminForm toggleBack={this.toggleNewAdmin} selectedUser={this.state.selectedUser} schools={this.state.db.schools} programs={this.state.db.programs} />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(AdminManager);
