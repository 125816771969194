import React, { Component} from "react";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import {SingleSelect} from '../../_formhelpers';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from "underscore"

export default class AccountType extends Component {

  state = {
    businessselect: false,
    studentselect: false,
    selected: false,
    clients: [],
    client: null
  }

  goToSignUp = event => {
    this.props.swapComponent(false);
  }

  selectStudent = event => {
    this.setState({
      studentselect: true,
      businessselect: false,
      selected: "Student"
    });
    this.props.swapAccountType("Student");
  }

  selectBusiness = event => {
    this.setState({
      studentselect: false,
      businessselect: true,
      selected: "Business"
    });
    this.props.swapAccountType("Business");
  }

  getClients = () => {
    axios.get("/api/getClients", {params: {
        safe : true,
      }
      }).then((res) => {
        //console.log("what's the clients:", res);
        this.setState({clients: res.data});
        if (res.data.length === 1){
          this.setState({client: res.data[0]}, () => this.props.setClient(res.data[0]))
        }
      });
  }

  handleClient = (e) => {
    //console.log("client", e.target.value);
    let i = _.findIndex(this.state.clients, {_id: e.target.value});
    this.setState({client: this.state.clients[i]}, () => this.props.setClient(this.state.clients[i]))

  }

  nevermind = () => {
    this.setState({client: null});

  }

  componentDidMount() {
    this.getClients();
  }



  render() {
    return (
        <Paper className="container_accounts mx-auto" elevation={1}>
            <h4>Create a new Admintern account
            {this.state.client ?
                <span className="client-title">{this.state.client.full_name} <span onClick={this.nevermind}>[change]</span></span>
            : null }</h4>

            {!this.state.client ?
            <SingleSelect required label="School System" name="client" onChange={this.handleClient} value={this.state.client}
            options={this.state.clients.map(o =>
              {
                return {value: o._id, label: o.full_name, key: o._id};
              })} />
              :<>
            <div title="Student Account" className={"account-type" + (this.state.studentselect ? " chosen" : "")} onClick={this.selectStudent}>
              <FontAwesomeIcon icon={["fal", "graduation-cap"]}/>
            </div>
            <div title="Business Account" className={"account-type" + (this.state.businessselect ? " chosen" : "")} onClick={this.selectBusiness}>
              <FontAwesomeIcon icon={["fal", "building"]}/>
            </div>
            <br />
            <Button type="submit" variant="contained" onClick={this.goToSignUp} color="primary" disabled={!this.state.selected} className="btn_action">
              Create {this.state.selected} Account
            </Button></> }
            <br />
            <Link to='/login' className="switch-pages">Already Have An Account?</Link>
        </Paper>
    )
  }
}
