import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder, getListStyle } from "../Shared/DragDrop";
import ResourceForm from "./ResourceForm";
import swal from "sweetalert";
import _ from "underscore";
import ContentLoading from "../Common/ContentLoading";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

class ResourceList extends Component {
  state = {
    ...this.props.user,
    files: [],
    reordered: false,
    viewAddNew: false,
    type: this.props.match.params.type,
    loading: true,
  };

  componentDidMount() {
    // intentional delay to always show the loading state, instead of a screen blink
    _.delay(() => {
      this.setState({ type: this.props.match.params.type }, () => this.makeCall());
    }, 500);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.type !== this.props.match.params.type) {
      this.setState({ type: newProps.match.params.type }, () => this.makeCall());
    }
  }

  makeCall = () => {
    let that = this;
    let type = this.state.type;
    let api = "/api/getResourceFiles?type=" + type;
    axios({
      method: "get",
      url: api,
    }).then((results) => {
      that.setState({ files: results.data });
      that.setState({ loading: false });
    });
  };

  toggleNew = (requery) => {
    console.log("clicked");
    this.setState({ viewAddNew: !this.state.viewAddNew });
    if (requery) this.makeCall();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.rerunSearch);
  };

  deleteConfirm = (file) => {
    let deleteFile = this.deleteFile;
    let message = "You are going to delete this file: '" + file.name + "'";
    //swal("Are you Sure?", message, "warning");
    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFile(file._id);
      }
    });
  };

  deleteFile = (id) => {
    let api = "/api/delete_file";
    let message = "File successfully deleted.";
    let makeCall = this.makeCall;
    axios({
      method: "post",
      url: api,
      data: { file_id: id },
    }).then(function (response) {
      console.log("this is the response:", response);

      if (response.data === "success") {
        NotificationManager.success(message, "Success");
        makeCall();
      } else {
        NotificationManager.warning("Something went wrong", "Uh Oh");
      }
    });
  };

  onDragEnd = (result) => {
    console.log("this is the result: ", result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const files = reorder(this.state.files, result.source.index, result.destination.index);
    console.log("this is the items:", files);
    if (!_.isEqual(files, this.state.files)) {
      console.log("reordered");
      this.setState({
        files,
        reordered: true,
      });
    }
  };

  saveReorder = () => {
    let json = this.state.files;
    let api = "/api/reorderFiles";
    let message = "Reorder Successfull.";
    let that = this;
    _.each(json, (j, k) => {
      j.ordinal = ++k;
    });
    console.log("new json:", json);
    axios({
      method: "post",
      url: api,
      data: {
        json,
      },
    }).then(function (response) {
      console.log("this is the response:", response);
      that.setState({ reordered: false });
      if (response.data === "success") {
        NotificationManager.success(message, "Success");
      } else {
        NotificationManager.warning("Something went wrong", "Uh Oh");
      }
    });
  };

  render() {
    return (
      <div className="side-page">
        <div className="basic_form">
          {this.state.loading ? (
            <>
              <div className="page_header">
                <h1>{this.state.type.charAt(0).toUpperCase() + this.state.type.slice(1)} Resources</h1>
              </div>
              <ContentLoading />
            </>
          ) : (
            <>
              <div className="page_header">
                <h1>
                  {this.state.type.charAt(0).toUpperCase() + this.state.type.slice(1)} Resources
                  {this.state.reordered ? (
                    <Button variant="contained" color="primary" className="push-right withExplanation" onClick={this.saveReorder}>
                      <FontAwesomeIcon icon={["fas", "save"]} />
                      Save Reorder
                    </Button>
                  ) : (
                    <span className="push-right withExplanation" onClick={this.toggleNew}>
                      {!this.state.viewAddNew ? (
                        <FontAwesomeIcon icon={["fal", "plus"]} mask={["fas", "circle"]} transform="shrink-7.5" />
                      ) : (
                        <FontAwesomeIcon icon={["fal", "times"]} mask={["fas", "circle"]} transform="shrink-7.5" />
                      )}
                    </span>
                  )}
                </h1>
                <div className="formExplanation">
                  These files will be available to your {this.state.type}
                  {this.state.type === "student" ? "s" : "es"}
                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-12 table-responsive-sm">
                    {!this.state.viewAddNew ? (
                      this.state.files.length > 0 ? (
                        <table className="table table-striped table-hover business-list-table">
                          <thead className="thead-light">
                            <tr>
                              <th width="30px"></th>
                              <th width="130px"></th>
                              <th scope="col" width="99%">
                                File
                              </th>
                              <th scope="col" width="30px">
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="components">
                              {(provided, snapshot) => (
                                <tbody className="basic_form" {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                  {this.state.files.map((fa, index) => (
                                    <Draggable key={fa._id} draggableId={fa._id} index={index}>
                                      {(provided, snapshot) => (
                                        <FileRow
                                          innerRef={provided.innerRef}
                                          obj={fa}
                                          provided={provided}
                                          deleteConfirm={this.deleteConfirm}
                                          dragHandle={
                                            <span {...provided.dragHandleProps}>
                                              <FontAwesomeIcon icon={["fas", "bars"]} className="draghandle" />
                                            </span>
                                          }
                                        />
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </table>
                      ) : (
                        <div>
                          <Button variant="contained" color="primary" onClick={this.toggleNew}>
                            Add Resources
                          </Button>
                        </div>
                      )
                    ) : (
                      <ResourceForm toggleBack={this.toggleNew} type={this.state.type} />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ResourceList);

class FileRow extends Component {
  render() {
    const { provided, innerRef } = this.props;
    return (
      <tr {...provided.draggableProps} ref={innerRef} key={this.props.obj._id}>
        <td>{this.props.dragHandle}</td>
        <td></td>
        <td>
          <a href={this.props.obj.path}>{this.props.obj.name}</a>
        </td>
        <td>
          <span className="table_icons" onClick={() => this.props.deleteConfirm(this.props.obj)}>
            <FontAwesomeIcon icon={["fal", "trash-alt"]} />
          </span>
        </td>
      </tr>
    );
  }
}
