import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    logged_in: state.auth.isAuthenticated
  };
};

class ConnectedUnfinishedProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };

  }

  render() {
    if (this.props == null) {
        return null
      }

    return (
        <div className="side-page">
          <h2>Please Complete or Review Your Profile</h2>
          You must complete your <a href="/profile">profile</a> before accessing this page, or if your account was imported, you will need to review the data to make sure it was imported correctly
      </div>
    )
  }

}

const unfinishedProfile = connect(mapStateToProps)(ConnectedUnfinishedProfile);
export default withRouter(unfinishedProfile);
