import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

class ConnectedResourceList extends Component {
  
  state = {
    files: [],
    }
  
  componentDidMount = () => {
      let that = this;
      let type = this.props.user.role;
      let api = "/api/getResourceFiles?type="+type;
      axios({
        method: 'get',
        url: api
      }).then(results => {
        console.log('results:', results)
        that.setState({files: results.data})
      });
  }

  render() {

    return (
      <div className="side-page">
        <div className="page_header"><h1>{this.props.user.role} Resources</h1> </div>

        {this.state.files.length > 0 ?
         <table className="table table-striped table-hover business-list-table">
           <thead className="thead-light">
             <tr>
               <th width="30px"></th>
                <th scope="col" width="99%">File</th>
             </tr>
           </thead>
          <tbody>
            {this.state.files.map((fa, index) => (
            <tr>
               <td></td>
               <td><a href={fa.path}>{fa.name}</a></td>
             </tr>
            ))}
            </tbody>  
         </table> : null }
      </div>
    )
  }
}

export default connect(mapStateToProps)(ConnectedResourceList);
