import React, { Component } from 'react';
import axios from 'axios'
import {NotificationManager} from 'react-notifications';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import BootBox from '../Shared/BootBox.jsx';

class ApplicantTable extends Component {

  state = {
    areyousure: false,
    id: null
  }

  updateApplicationStatus = (id, status) => {
    let json = {
      application_id : id,
      status : status
    }

    let that = this;
    axios({
      method: 'post',
      url: '/api/updateApplicationStatus',
      data: {
        json
      }
    }).then(function (response) {
      NotificationManager.success('Application successfully ' + status.toLowerCase() + '.', 'Application Status Change Successfull');
      that.props.updateApplicationStatusForDisplay(id, status);
    });
  }

  recind = (id) => {
    // console.log("rescind");
    this.setState({areyousure: true});
    this.setState({id: id})
  }

  handleClose = () => {
    this.setState({areyousure: false});
  }

  confirmRecind = () => {
    this.updateApplicationStatus(this.state.id, "Rescinded");
    this.setState({id: null, areyousure: false})
  }

  render () {
    if (this.props.data === undefined)
    return null

      return (
        <div>
          <div className="row">
            <div className="col-12 table-responsive-sm d-none d-sm-block">

              <table className="table table-striped table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" width="40%">Title</th>
                    <th scope="col" width="20%">Business</th>
                    <th scope="col" width="7%">Status</th>
                    <th scope="col" width="7%">Application</th>
                    <th scope="col" width="7%">Last Updated</th>
                    {(this.props.type !== "closed" ? <th scope="col" width="20%">Actions</th> : null)}
                  </tr>
                </thead>
                <tbody>
                  {this.props.data.map(app => (

                    <tr key={app._id}>
                      <td><Link to={'/opportunity/view/' + app.opportunity_id_fk._id} title="View Opportunity">{app.opportunity_title}</Link></td>
                      <td><Link to={'/profile/view/' + app.opportunity_id_fk.business_id_fk._id}>{app.opportunity_id_fk.business_id_fk.business_name}</Link></td>
                      <td>{app.status}</td>
                      <td>{moment(app.createdAt).format("MM/DD/YYYY")}</td>
                      <td>{moment(app.updatedAt).format("MM/DD/YYYY")}</td>
                      {(this.props.type !== "closed" ?
                      <td>
                        { (app.status === "New" || app.status === "Interview Scheduled") ? (
                          <Button type="submit" variant="contained" color="default" className="btn_action" onClick={(e) => this.recind(app._id)}>
                            Rescind
                          </Button>
                        ) : (null)}

                        {app.status === "Offered" ? (
                          <div>
                          <Button type="submit" variant="contained" color="default" className="btn_action" onClick={(e) => this.updateApplicationStatus(app._id, "Accepted")}>
                            Accept
                          </Button>

                          <Button type="submit" variant="contained" color="default" className="btn_action" onClick={(e) => this.updateApplicationStatus(app._id, "Declined")}>
                            Decline
                          </Button>
                          </div>
                        ) : (null)}

                      </td>
                     : null)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="col-12 table-responsive d-block d-sm-none">
              {this.props.data.map(app => (
                <div key={app._id} className="mobile_application_opp_name">
                  <div>
                    <Link to={'/opportunity/view/' + app.opportunity_id_fk._id} title="View Opportunity" className="mobile_big_link" >{app.opportunity_title}</Link>
                    <br />{app.opportunity_id_fk.business_id_fk.business_name}
                  </div>
                  <div>{app.status}</div>

                  <div className="break"></div>
                  {(this.props.type !== "closed" ?
                  <div className="mobile_application_buttons">
                    { (app.status === "New" || app.status === "Interview Scheduled") ? (
                      <Button type="submit" variant="contained" color="default" className="btn_action danger" onClick={(e) => this.recind(app._id)}>
                        Rescind
                      </Button>
                    ) : (null)}

                    {app.status === "Offered" ? (
                      <div>
                      <Button type="submit" variant="contained" color="default" className="btn_action success" onClick={(e) => this.updateApplicationStatus(app._id, "Accepted")}>
                        Accept
                      </Button>

                      <Button type="submit" variant="contained" color="default" className="btn_action danger" onClick={(e) => this.updateApplicationStatus(app._id, "Declined")}>
                        Decline
                      </Button>
                      </div>
                    ) : (null)}

                  </div>
                  : null)}
                </div>
              ))}
            </div>


          </div>
        <BootBox
          message="Are you sure you want to recind your application?"
          show={this.state.areyousure}
          onYesClick={this.confirmRecind}
          onNoClick={this.handleClose}
          onClose={this.handleClose}
          cancelText="Cancel"
          confirmText="Recind"
        />
      </div>
      )
  }
};

/*
class WrappedResumeLink extends Component {

    render () {
      if (typeof this.props.files == 'undefined') {
        return null;
      }

      return (
        <div><a href={this.props.files.link()+"?download=true"}><FontAwesomeIcon icon={["fal", "file-user"]} className="big-icons"/> Resume</a></div>
      )
    }
};

const ResumeLink =  withTracker((props) => {
  Meteor.subscribe("files");

  return {
    files : Files.findOne({userId: props.student, "meta.description": "resume"})
  };

})(WrappedResumeLink);
*/


export {ApplicantTable}
