import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AutoCompleteFilter} from '../../_formhelpers';
import {ApproveIcon, MasqueradeIcon, printAgreementBtn, DeleteUserIcon} from './_shared';
import _ from 'underscore';

export default class UserSearch extends Component {

  state = {
    ac: "",
    list: [],
    db: {
      users: [],
    },
  }
  
  local_removeUser = (_id) => {
    this.setState({ac: ""});
  }


  updateList = (list) => {
    _.each(list, function(l){
      if (l.role !== "Business")
        l.display = l.first_name + " " + l.last_name;
      else
        l.display = l.business_name;
    });

    this.setState({list: _.sortBy(list, (i) => i.display.toLowerCase())})
  }

  printUser = (user) => {
    //console.log("printing: ", user)
    if (!user.createdAt){
      user.createdAt = moment();
    }

    //let obj = user.files.find(obj => obj.description === "profile_pic_cropped");
    let obj = user.profile_pic_id_fk;

    let theLink = '/profile/view/' + user._id;
    if (user.role === "Teacher")
      theLink = '/admins/' + user._id;

    //console.log("The link:", theLink)

    return (

      <tr key={user._id}>
        <td>
        <Link to={theLink}>

        {obj ?
          <Avatar className={user.role.toLowerCase()+"_avatar sm"} src={obj.path}  /> :
            user.role === "Student" ? (
              <Avatar className="student_avatar sm"><FontAwesomeIcon icon={["fal", "graduation-cap"]}/></Avatar>
            ) :(
              user.role === "Admin" ? (
                <Avatar className="admin_avatar sm"><FontAwesomeIcon icon={["fal", "user"]}/></Avatar>
              ) : (
                user.role === "Teacher" ? (
                  <Avatar className="business_avatar sm"><FontAwesomeIcon icon={["fal", "school"]}/></Avatar>
                ) : (
                  <Avatar className="business_avatar sm"><FontAwesomeIcon icon={["fal", "building"]}/></Avatar>
                )

              )
            )

           }
            </Link></td>
            <td><Link to={theLink}>
              <span>{user.display}</span>
            </Link></td>

        <td >
          {printAgreementBtn(user.agreement_file_id_fk)}

        </td>
        <td>
          <ApproveIcon userId={user._id} approved={user.approved} />
        </td>
        <td className="table-actions even-spaced">
        {/*
        // previously only allowed masquerade here for student and businesses
        // changes on 5.12.21 to allow for all user types
        // expecting that the backend limit will prevent showing users that should not be shown
        // {user.role === "Student" || user.role === "Business" ?
        // : null}
        */}
          <MasqueradeIcon userId={user._id} />
          <DeleteUserIcon user={user} callBack={this.local_removeUser} />
        </td>
      </tr>
    )
  }

  handleChange = event => {
    this.setState({ ac : event.target.value });
  };



   render() {

     return (
       <div className="side-page">
         <div className="basic_form">

           <div className="page_header with_search no_results"><h1>User Search</h1>
           <AutoCompleteFilter
            api="/api/searchUsers"
            display="search"
            key="search"
            id="search"
            name="search"
            value={this.state.ac}
            onChange={this.handleChange}
            updateList={this.updateList} /></div>

            {this.state.list.length > 0 ?

             <div>
               <div className="row">
                 <div className="col-12 table-responsive-sm">
                   <table className="table table-striped table-hover business-list-table">
                     <thead className="thead-light">
                       <tr>
                         <th scope="col"></th>
                         <th scope="col" width="70%">User</th>
                         <th scope="col" width="10%">Agreement</th>
                         <th scope="col" width="10%">Approve</th>
                         <th scope="col" width="10%">Actions</th>
                       </tr>
                     </thead>
                     <tbody>
                       {this.state.list.map(user => (
                         this.printUser(user)
                       ))}
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>

             : <div className="search_above">Search for a User above</div> }


         </div>
       </div>
     )
   }
}
