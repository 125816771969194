import React, { Component } from "react";
import axios from "axios";
import { api } from "../../_helpers";
import { Link } from "react-router-dom";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SingleSelect, MultiSelect } from "../../_formhelpers";
import { ExportCSV } from "../Shared/ExcelExport";
import _ from "underscore";

export default class AvailableOpportunitiesReport extends Component {
  state = {
    school_attending: [],
    affiliated_program: [],
    list: [],
    availability: "Open",
    db: {
      schools: [],
      programs: [],
    },
    loading: true,
  };

  componentDidMount() {
    this.makeCalls();
  }

  getOpportunities = () => {
    return api({
      method: "post",
      url: "/api/filterAvailableOpportunities",
      data: {
        school_attending: this.state.school_attending.length > 0 ? this.state.school_attending : null,
        affiliated_program: this.state.affiliated_program.length > 0 ? this.state.affiliated_program : null,
        availability: this.state.availability,
      },
    });
  };

  getPrograms = () => {
    return axios({
      method: "get",
      url: "/api/getPrograms",
    });
  };

  getSchools = () => {
    return axios({
      method: "get",
      url: "/api/getSchools",
    });
  };

  makeCalls = () => {
    var that = this;
    axios.all([this.getOpportunities(), this.getSchools(), this.getPrograms()]).then(
      axios.spread(function (users, schools, programs) {
        _.each(users.data, function (e) {
          e.school_print = e.schools.length > 0 ? e.schools.join(", ") : "All Schools";
          e.program_print = e.programs.length > 0 ? e.programs.join(", ") : "All Programs";
          e.print_app_open = moment(e.application_open).format("MM/DD/YYYY");
          e.print_app_close = moment(e.application_close).format("MM/DD/YYYY");
        });

        that.setState({ list : users.data });
        that.setState({ db: { ...that.state.db, schools: schools.data} });
        that.setState({ db: { ...that.state.db, programs: programs.data} });
        that.setState({ loading: false });
      })
    );
  };

  updateList = (list) => {
    this.setState({ list: list });
  };

  printOpportunity = (opp) => {
    let obj = opp.business_id_fk.profile_pic_id_fk;

    return (
      <tr key={opp._id}>
        <td>
          <Link to={"/profile/view/" + opp._id}>
            {obj ? (
              <Avatar className={"studnet_avatar sm"} src={obj.path} />
            ) : (
              <Avatar className="student_avatar sm">
                <FontAwesomeIcon icon={["fal", "building"]} />
              </Avatar>
            )}
          </Link>
        </td>

        <td>
          <Link to={"/opportunity/view/" + opp._id}>{opp.opportunity_title}</Link>
        </td>

        <td>
          <Link to={"/profile/view/" + opp.business_id_fk._id}>{opp.business_id_fk.business_name}</Link>
        </td>

        <td>{opp.schools.length > 0 ? opp.schools.join(", ") : "All Schools"}</td>

        <td>
          {moment(opp.application_open).format("MM/DD/YYYY")} - {moment(opp.application_close).format("MM/DD/YYYY")}
        </td>
      </tr>
    );
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.rerunSearch);
  };

  rerunSearch = () => {
    this.setState({ loading: true });

    let that = this;
    axios.all([this.getOpportunities()]).then(
      axios.spread(function (users) {
        that.setState({ list : users.data });
        that.setState({ loading: false });
      })
    );
  };

  render() {
    return (
      <div className="side-page">
        <div className="basic_form">
          <div className="page_header with_search no_results">
            <h1>Opportunities Report</h1>
            {
              <ExportCSV
                csvData={this.state.list}
                fields={["opportunity_title", "business_id_fk.business_name", "school_print", "program_print", "print_app_open", "print_app_close"]}
                fileName="opportunity_availability"
                field_headers={["Opportunity Title", "Business Name", "Available Schools", "Available Programs", "Registration Open", "Registration Close"]}
              />
            }
          </div>

          <div className="filter-on">
            <MultiSelect
              label="Schools"
              name="school_attending"
              onChange={this.handleChange}
              value={this.state.school_attending}
              options={this.state.db.schools.map((s) => {
                return { value: s.name, label: s.name };
              })}
            />

            <MultiSelect
              label="Programs"
              name="affiliated_program"
              onChange={this.handleChange}
              value={this.state.affiliated_program}
              options={this.state.db.programs.map((s) => {
                return { value: s.name, label: s.name };
              })}
            />

            <SingleSelect
              label="Availability"
              name="availability"
              onChange={this.handleChange}
              value={this.state.availability}
              options={[
                { value: "All", label: "All Opportunities" },
                { value: "Open", label: "Available Opportunities" },
                { value: "Closed", label: "Closed Opportunities" },
              ]}
            />
          </div>

          {this.state.loading ? (
            <div className="content_loader">
              <FontAwesomeIcon icon={["far", "circle-notch"]} spin />
            </div>
          ) : this.state.list.length > 0 ? (
            <div>
              <div className="row">
                <div className="col-12 table-responsive-sm">
                  <table className="table table-striped table-hover business-list-table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col" width="30%">
                          Opportunity
                        </th>
                        <th scope="col" width="25%">
                          Business
                        </th>
                        <th scope="col" width="30%">
                          Available Schools
                        </th>
                        <th scope="col" width="15%">
                          Registration Dates
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.state.list.map((opp) => this.printOpportunity(opp))}</tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="search_above">No Opportunities Match that Filter</div>
          )}
        </div>
      </div>
    );
  }
}
