import React, { Component } from 'react';
import RichTextEditor from 'react-rte';
import {toolbarConfig} from './_helpers';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import axios from 'axios'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Autosuggest from 'react-autosuggest';
import Avatar from '@material-ui/core/Avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';



class MultiSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      labelClass: "big-label",
      value: [],
      ...props
    }
  }

 

  componentDidMount(){
    //if (this.props.labelClass){
    //  this.setState({labelClass: this.props.labelClass, value: this.props.value });
    //}
    if (this.props.value.length > 0) {
         this.setState({
           value: this.props.value,
           labelClass: "small-label"
         });
       }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value){

     if (this.props.value.length > 0) {
       this.setState({
         value: this.props.value,
         labelClass: "small-label"
       });
     }
    }
  }

  openMenu = () => {
    this.setState({labelClass: "small-label" })
  }

  closeMenu = () => {
      
      let currentVal = this.state.value;
      if (currentVal === null){
        if (this.props.required)
          this.setState({error: true});
          
        this.setState({labelClass: "big-label" })
      }
      else if (currentVal.length === 0){
        if (this.props.required)
          this.setState({error: true});
        
        this.setState({labelClass: "big-label" })
      }
      else {
        if (this.props.required)
          this.setState({error: false});
      }

  }


    handleChange = (value: string) => {

      this.setState({value: value});
      if (value === null) {
        value = [];
      }
      if (value !== null) {
      if (value.value === "" && !value.value) {
        this.setState({labelClass: "big-label" })
        if (this.props.required)
          this.setState({error: true});
      }
      else {
        this.setState({labelClass: "small-label" });
        if (this.props.required)
          this.setState({error: false});
      }



      this.props.onChange({
            target: {
                name: this.props.name,
                value: value.map(v =>
                  {
                    return v.label;
                  })
            }
        });
      }
    };

  render() {
    let values = [];
    if (this.state.value !== undefined && this.state.value !== null) {
      values = this.props.options.filter(({value}) => this.state.value.includes(value));
    }
    return (
      <div className={(this.state.error ? "error " : "") + "react-select-container"}>
        <label className={this.state.labelClass}>{this.props.label}</label>
        <Select
          isDisabled={this.props.disabled}
          options={this.props.options}
          onChange={this.handleChange}
          placeholder={this.props.label}
          components={makeAnimated()}
          value={values}
          styles={{
                    valueContainer: (base) => ({
                        ...base,
                        padding: "12px",
                    }),
                    menu: (base) => ({
                      ...base,
                      background: "white",
                      zIndex: 1031
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#757575"
                    })
                }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
            ...theme.colors,
              primary25: '#C7D0DA',
              primary: '#20416c',
              primary50: '#90A0B5',
              primary75: '#587191'
            },
          })}
          onMenuOpen={this.openMenu}
          onMenuClose={this.closeMenu}
          isMulti
          closeMenuOnSelect={false}
          />
      </div>
    );
  }

}

class SingleSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      labelClass: "big-label",
      error: false,
      ...props
    }
  }

  componentDidUpdate(prevProps) {
    //if (nextProps.value !== this.props.value) {
    if (this.props.value !== prevProps.value){
      if (this.props.value !== undefined){
      this.setState({
        value: this.props.value, labelClass: "small-label"
        //value: {label: nextProps.value, value: nextProps.value} found this on stackoverflow
      });
    }
  }
  }

  componentDidMount() {
    if (this.props.value !== undefined){
      this.setState({labelClass: "small-label" });
    }
  }

  openMenu = () => {
    this.setState({labelClass: "small-label" });
  }

  closeMenu = () => {
    let currentVal = this.state.value;
    if (currentVal === "" || currentVal === undefined){
      if (this.props.required)
        this.setState({error: true});
        
      this.setState({labelClass: "big-label" })
    }
    else {
      if (this.props.required)
      this.setState({error: false});
    }
  }

  handleAutoFill = () => {
    console.log("chrome autofill happened!");
  }

  handleChange = (value: string) => {
    this.setState({value: value.value});
    if (value.value !== "" && !value.value){
      if (this.props.required)
        this.setState({error: true});
      
    this.setState({labelClass: "big-label" })
    }
    else{
      this.setState({labelClass: "small-label" });
      if (this.props.required)
        this.setState({error: false});
    }
      

      this.props.onChange({
          target: {
              name: this.props.name,
              value: value.value
          }
      });
  };

  render() {

    let value = "";
    var autoFill = (this.props.autoFill === undefined) ? "false" : this.props.autoFill;
    var autoComplete = (this.props.autoComplete === undefined) ? "false" : this.props.autoComplete;
    if (this.state.value !== undefined) {
      value = this.props.options.filter(({value}) => value === this.state.value);
    }

    if (this.props.options === undefined) {
      return null
    }
    return (
      <div className={(this.state.error ? "error " : "") + "react-select-container"}>
        <label className={this.state.labelClass}>{this.props.label}</label>
        <Select
          isDisabled={this.props.disabled}
          autoFill={autoFill}
          autoComplete={autoComplete}
          multi={false}
          autosize={false}
          options={this.props.options}
          onChange={this.handleChange}
          placeholder={this.props.label}
          onAutoFill={this.handleAutoFill}
          value={value}
          components={makeAnimated()}
          styles={{
                    valueContainer: (base) => ({
                        ...base,
                        padding: "12px",
                    }),
                    menu: (base) => ({
                      ...base,
                      background: "white",
                      zIndex: 1031
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#757575"
                    })
                }}
          theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                  ...theme.colors,
                    primary25: '#C7D0DA',
                    primary: '#20416c',
                    primary50: '#90A0B5',
                    primary75: '#587191'
                  },
                })}
          onMenuOpen={this.openMenu}
          onMenuClose={this.closeMenu}
          />
      </div>
    );
  }

}

class ContentEditable extends Component {

  constructor(props) {
    super(props);

    this.emitChange = this.emitChange.bind(this);
}

    shouldComponentUpdate(nextProps){
        return nextProps.value !== ReactDOM.findDOMNode(this).innerHTML;
    }

    componentDidUpdate() {
        if ( this.props.value !== ReactDOM.findDOMNode(this).innerHTML ) {
           ReactDOM.findDOMNode(this).innerHTML = this.props.value;
        }
    }

    emitChange(){
        var html = ReactDOM.findDOMNode(this).innerHTML;
        if (this.props.onChange && html !== this.lastHtml) {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: html
                }
            });
        }
        this.lastHtml = html;
    }

    render () {
        return <div
            className={this.props.className}
            onInput={this.emitChange}
            onBlur={this.emitChange}
            contentEditable
            dangerouslySetInnerHTML={{__html: this.props.value}}></div>;
    }
}



class RTEHelper extends Component {

  constructor(props) {
    //console.log("props are: ", props);
    super(props);

    this.additionalClass = this.props.additionalClass || "";
    this.label = this.props.label || null;
    this.state = {
      value : RichTextEditor.createEmptyValue(),
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    let val = "";
    if (this.props.value !== undefined){
      val = this.props.value;
    }

    if (val.length > 0)
      this.setState({value: RichTextEditor.createValueFromString(this.props.value, "html")});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value){
      if (this.props.value !== undefined){
        this.setState({value: RichTextEditor.createValueFromString(this.props.value, "html")});
      }
    }
  }

   handleChange = (value) => {
    this.setState({value: value});
  };


  handleBlur = (value) => {
    this.props.onChange(this.props.name, this.state.value);
  };

  render() {

    return (
              //adding 100% width style to this to fill a box used in reflection questions
              <div className={this.additionalClass + " RTE_Container"}>
              {(this.label ? <label className="rte-label">{this.label}</label> : null)}
                <RichTextEditor
                  name={this.props.name}
                  value={this.state.value}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  toolbarConfig={toolbarConfig}
                  className="text-editor"
                />
              </div>

    )
  }
}

function renderWithPic(suggestion) {
    
  let obj = suggestion.profile_pic_id_fk.path;
  
  return (
    <span className="autoc-results-withpic">
      {obj ?
      <Avatar className={"businesses_avatar pic"} src={obj}  /> :
        <Avatar className="business_avatar pic"><FontAwesomeIcon icon={["fal", "building"]}/></Avatar> }{suggestion.business_name}</span>
  );
}

function renderNormal(suggestion) {
  
  return (
    <span className="autoc-results">
      {suggestion.business_name}</span>
  );
}

function blankRender(suggestion){
  return (
    <span></span>
  )
}

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAC: "",
      suggestions: [],
      value: "",
      id: "",
      className: "react-autosuggest__input"
    }
  }
  
  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
    
    this.props.onAutoCompleteChange(event);
  }
  
  onFocus = (event) => {
      this.setState({
      currentAC: document.activeElement.id
      });
  }
  
  getSuggestions = (value, key) => {
    let escapedValue = escapeRegexCharacters(value.trim());
    let that = this;
    if (escapedValue === "") {
      return [];
    }
    
      axios({
        method: 'post',
        url: this.props.api,
        data : {
          search: escapedValue
        }
      }).then(function (response) {
        console.log("response:", response);
        that.setState({suggestions: response.data})
      });
      
      
      
    
  }
  
  onSuggestionsFetchRequested = ({ value }) => {
    const key = this.state.currentAC;
    this.getSuggestions(value, key);
  }
  
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  }
  
  handleSelection = (suggestion) => {
    let el = {
      target: {
        name: this.props.name,
        value: suggestion
      }
    }
    this.props.onAutoCompleteChange(el);
  }
  
  handleKeyboardSelection = (test, event) => {
    let el = {
      target: {
        name: this.props.name,
        value: event.suggestion
      }
    }
    this.props.onBlur(el)
    this.setState({currentAC: null});
  }
  
  componentDidMount = () => {
  }
  
  componentDidUpdate = (prevProps, prevState) => {
    if (!_.isEqual(prevProps.errors, this.props.errors)){
      let className = "react-autosuggest__input";
      if (this.props.errors.includes(this.props.name)){
        className="react-autosuggest__input error";
      }
      this.setState({className: className})
    }
  }
  
  render() {
    if (this.props === undefined)
      return null
    // if autocomplete stops working, it's this
    //const { value, suggestions } = this.state;
    const { suggestions } = this.state;
    const inputProps = {
      value: this.props.value || "",
      onChange: this.props.onChange,
      onFocus: this.onFocus,
      id: this.props.id,
      name: this.props.name,
      disabled: this.props.disabled,
      className: this.state.className
    }
    let renderSuggestion = renderNormal;
    if (this.props.withPic)
      renderSuggestion = renderWithPic;
      
    return (
      <div className="metaData">
        {this.props.label ? 
          <span className="metaData-label">{this.props.label}:</span>
          : null }
        <span className="metaData-content">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.handleKeyboardSelection}
          getSuggestionValue={this.handleSelection}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        </span>
      </div>
    );
  }
  
}

class AutoCompleteFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      value: "",
      id: ""
    }
  }
  
  onChange = (event, { newValue, method }) => {
    this.setState({
      value: event.target.value
    });
    
    this.props.onChange(event);
  }
  
  
  getSuggestions = (value, key) => {
    let escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === "") {
      this.props.updateList([]);
    }
    
      axios({
        method: 'post',
        url: this.props.api,
        data : {
          search: escapedValue
        }
      }).then((response) => {
        console.log("response:", response);
        this.setState({suggestions: response.data})
        this.props.updateList(response.data);
      });
      
    
  }
  
  onSuggestionsFetchRequested = ({ value }) => {
    const key = this.state.currentAC;
    if (value.length > 2)
      this.getSuggestions(value, key);
  }
  
  onSuggestionsClearRequested = () => {
    console.log("clear requested", this.state.value);
    if (this.state.value === "") {
      this.props.updateList([]);
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    if (this.props.value !== prevProps.value)
      if (this.props.value === "")
        this.props.updateList([]);
  }
  
  render() {
    if (this.props === undefined)
      return null
    // if autocomplete stops working, it's this
    //const { value, suggestions } = this.state;
    const { suggestions } = this.state;
    const inputProps = {
      value: this.state.value || "",
      onChange: this.onChange,
      id: this.props.id,
      name: this.props.name
    }
      
    return (
      <div className="metaData">
        {this.props.label ? 
          <span className="metaData-label">{this.props.label}:</span>
          : null }
        <span className="metaData-content">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          inputProps={inputProps}
          renderSuggestion={blankRender}
        />
        </span>
      </div>
    );
  }
  
}

export {RTEHelper, ContentEditable, MultiSelect, SingleSelect, AutoComplete, AutoCompleteFilter}

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}