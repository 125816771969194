import React, {Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  //KeyboardTimePicker,
  //KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/core/styles';
//import { AlertList, Alert, AlertContainer } from "react-bs-notifier";

import {NotificationContainer} from 'react-notifications';

import AdminternHeader from "./components/Layout/AdminternHeader";
import Header from "./components/Layout/Header.jsx";
import Main from "./components/Layout/Main.jsx";
import Footer from "./components/Layout/Footer.jsx";

import "./_fontAwesome.jsx"
import muiTheme from "./_muiTheme.jsx"
import {setUser, updateProjects, updateFiles, updateSettings } from './ducks'
import axios from 'axios'
import _ from "underscore"

// MomentUtils.prototype.getStartOfMonth=MomentUtils.prototype.startOfMonth //fixes datepicker

const actions = {
  setUser, updateProjects, updateFiles, updateSettings
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
  };
};

class ConnectedAppContainer extends Component {

  state = {
    loading: true
  }

  componentDidMount() {
    this.makeCalls();
    /*api.get("/api/getSettings").then((response) => {
      console.log("these are the settings", response)
    })*/
  }

  getUser = () => {
    return axios({
      method: 'get',
      url: '/api/current-user',
      params: {
        safe : true,
      }
    });
  }

  getProjects = () => {
    return axios({
      method: 'get',
      url: '/api/getProjectsByStudent',
      params: {
        safe : true,
      }
    });
  }

  getFiles = () => {
    return axios({
      method: 'get',
      url: '/api/getUsersFiles',
      params: {
        safe : true,
      }
    });
  }
  
  getSettings = () => {
    return axios({
      method: 'get',
      url: '/api/getSettings',
      params: {
        safe : true,
      }
    });
  }
  

  makeCalls = () => {
    let setUser = this.props.setUser;
    let updateProjects = this.props.updateProjects;
    let updateFiles = this.props.updateFiles;
    let updateSettings = this.props.updateSettings;
    
    //var that = this;
    axios.all([this.getUser(), this.getSettings(), this.getProjects(), this.getFiles()])
    .then(axios.spread(function (user, settings, projects, files) {
      if (settings.data.length !== 0){
        //console.log("settings:", settings);
        updateSettings(settings.data[0])
      }
      else {
        //console.log("no settings");
      }
      //console.log("user:", user)
      if(files){
        // console.log("files:", files)
        updateFiles(files.data);
      }
      if (projects){
        // console.log("projects", projects.data);
        updateProjects(projects.data);
      }
      if (!Array.isArray(user.data)){
        //console.log("user:", user);
        let payload = {
          user : user.data.user,
          is_masquerade : user.data.is_masquerade,
          admin_id : user.data.admin_id
        }
        setUser(payload);
        this.setState({loading: false});
      } else {
        this.setState({loading: false});
      }
    }.bind(this)));
  }

  render() {
    if (this.state.loading)
      return null;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          <ThemeProvider theme={muiTheme}>
        <>
          {_.isEmpty(this.props.user) || this.props.user.role === "SuperAdmin" || (this.props.settings.length === 0) ? <AdminternHeader /> : <Header />}
          <Main />
          <Footer />

          <NotificationContainer/>
        </>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    )
  }
}
const ConnectedApp = connect(mapStateToProps, actions)(ConnectedAppContainer);
const App = withRouter(ConnectedApp);
export default App;


