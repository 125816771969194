import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    logged_in: state.auth.isAuthenticated,
    projects: state.projects,
    settings: state.settings
  };
};

class ConnectedDefault extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };

  }

  componentDidMount() {
    //console.log("user info:", this.props.settings.length, this.props.user.role);
      if (this.props.settings.length === 0){
        if (this.props.user.role === "Admin")
          this.props.history.push("/admin/siteSettings");
        else if (this.props.user.role === "SuperAdmin")
          this.props.history.push("/admintern/dashboard");
        else
          this.props.history.push("/uhoh");
      } else {
        if (this.props.user.__v === 0 && this.props.user.role !== "Teacher" && this.props.user.role !== "Admin") { // user hasn't saved profile
          //document.location = "/profile";
          this.props.history.push("/profile");
        }
        else if (this.props.user.role === "Student"){
          console.log("logging in, projects length:", this.props.projects.length)
          if (this.props.projects.length > 0)
            this.props.history.push("/projects/viewall");
          else
            this.props.history.push("/opportunity/browse");
        }
        else if (this.props.user.role === "Business"){
          this.props.history.push("/opportunity/all");
        }
        else if (this.props.user.role === "Admin" || this.props.user.role === "Teacher"){
          this.props.history.push("/admin/dashboard");
        }
        
  }
}

  render() {
    if (this.props === null) {
        return null
      }

    return (
        <div className="side-page">
          <h2>Redirect</h2>
          This page should push you to where you are supposed to go
      </div>
    )
  }

}

const Default = connect(mapStateToProps)(ConnectedDefault);
export default withRouter(Default);
