import React from "react";
import MaskedInput from 'react-text-mask';


export function PhoneMask(props) {
const { inputRef, ...other } = props;

return (
  <MaskedInput
    {...other}
    ref={inputRef}
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    guide={false}
  />
);
}

export function ExpDateMask(props) {
const { inputRef, ...other } = props;

return (
  <MaskedInput
    {...other}
    ref={inputRef}
    mask={[/[0-1]/, /[1-9]/,'/', /[2]/, /[0]/, /[0-9]/,/[0-9]/]}
    guide={false}
  />
);
}
