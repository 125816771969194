import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import {api} from "../../_helpers"
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {setUser, setMasquerade } from '../../ducks';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MultiSelect } from "../../_formhelpers";

const actions = {
  setUser,
  setMasquerade
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
  };
};

class AdminForm extends Component {

  state = {
    disabled: false,
    user: null,
    db: {
      schools: [],
      programs: []
    },
  }

  componentDidMount() {
    if (this.props.selectedUser !== null)
      this.setState({
        user: this.props.selectedUser,
        id: this.props.selectedUser._id,
        first_name: this.props.selectedUser.first_name,
        last_name: this.props.selectedUser.last_name,
        email: this.props.selectedUser.email,
        schools: this.props.selectedUser.schools,
        programs: this.props.selectedUser.programs,
      })
      
    this.setState(state => ((state.db.schools = this.props.schools, state)));
    this.setState(state => ((state.db.programs = this.props.programs, state)));
    this.setState({default_password: this.props.settings.default_password})
  }
  
  
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };
  

  
  createAdmin = (e) => {
    let json = _.omit(this.state, 'db');
    let goBack = this.props.toggleBack;
    let link = '/api/createTeacher';
    let message = 'Admin successfully created.';
    if (this.state.user) {
      link = '/api/editTeacher';
      message = 'Admin successfully edited.';
    }
    api({
        method: 'post',
        url: link,
        data: {
          json
        }
      }).then(function (response) {
        console.log("this is the response:", response)
        
          if (response.data === "success"){
            NotificationManager.success(message, 'Success');
            goBack(true);
          }
          else if (response.data === "User Exists") {
            NotificationManager.warning("Check the Email", 'User Exists');
          }
          else {
            NotificationManager.warning("Something went wrong", 'Uh Oh');
          }
        })
        
        console.log("create admin:", json);
  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.createAdmin()}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        <TextValidator
        name="first_name" label="First Name" variant="outlined"
        onChange={this.handleChange} value={this.state.first_name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <TextValidator
        name="last_name" label="Last Name" variant="outlined"
        onChange={this.handleChange} value={this.state.last_name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <TextValidator
        name="email" label="Email" variant="outlined"
        onChange={this.handleChange} value={this.state.email}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <MultiSelect label="Schools" name="schools" onChange={this.handleChange}
        value={this.state.schools || []}
        options={this.state.db.schools.map(s =>
          {
            return {value: s.name, label: s.name};
          })}
        />
        <MultiSelect label="Programs" name="programs" onChange={this.handleChange}
        value={this.state.programs || []}
        options={this.state.db.programs.map(s =>
        {
          return {value: s.name, label: s.name};
        })}
        />
        
        <div className="mobile-center-button">
        { this.state.user === null ? (
          <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
            Create Admin
          </Button>
        ) : (
        <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
          Edit Admin
        </Button>
        )
        }

        </div>
        
       </ValidatorForm>
     )
   }
}

export default connect(mapStateToProps, actions)(AdminForm);
