import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PhoneMask} from '../../_masks';
import {setUser } from '../../ducks';
import {MultiSelect, SingleSelect} from '../../_formhelpers';
import ProfilePic from '../Layout/ProfilePic';
import {ClickToViewMap} from '../Layout/EditMap';
import _ from 'underscore';
import {getStreetCords, getZipCords } from '../../_map'
import {api} from "../../_helpers"

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    is_masquerade : state.auth.isMasquerade,
    admin_id : state.auth.masquerade_user
  };
};

const actions = {
  setUser
}

class ConnectedStudentProfileWrapped extends Component {

  state = {
    disabled: false,
    db: {
      states: [],
      focusAreas: [],
      programs: [],
      schools: [],
      cords: [],
      shrink: {
        home_phone: (this.props.user.home_phone) ? true : false,
        cell_phone: (this.props.user.cell_phone) ? true : false,
        parent_phone: (this.props.user.parent_phone) ? true : false,
        alternate_parent_phone: (this.props.user.alternate_parent_phone) ? true : false,
      }
    },
    //...this.props.user
     ...JSON.parse(JSON.stringify(this.props.user))
  }

  componentDidMount() {
    this.makeCalls();
  }

  getStates = () => {
    return axios({
      method: 'get',
      url: 'api/getStates',
    });
  }

  getPrograms = () => {
    return axios({
      method: 'get',
      url: 'api/getPrograms',
    });
  }

  getFocusAreas = () => {
    return axios({
      method: 'get',
      url: 'api/getFocusAreas',
    });
  }

  getSchools = () => {
    return axios({
      method: 'get',
      url: 'api/getSchools',
    });
  }

  getFile = () => {
    return axios({
      method: 'get',
      url: 'api/getUsersFiles',
    })
  }

  makeCalls = () => {
    axios.all([this.getSchools(), this.getFocusAreas(), this.getStates(), this.getPrograms(), this.getFile()])
    .then(axios.spread((schools, focusAreas, states, programs, file) => {
      this.setState(state => ((state.db.schools = schools.data, state)));
      this.setState(state => ((state.db.focusAreas = focusAreas.data, state)));
      this.setState(state => ((state.db.states = states.data, state)));
      this.setState(state => ((state.db.programs = programs.data, state)));
      _.each(file.data, (f) => {
        if (f.description === "student_resume_file")
          this.setState(state => ((state.db.resume = f, state)));
        if (f.description === "student_agreement_file")
          this.setState(state => ((state.db.agreement = f, state)));
      });
    }));
  }

  clickInput = (field) => {
    console.log("this is the field", field)
    document.getElementById(field).click();
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  handleNestedChange(event, nestedItem ) {
    let val = event.target.value;
    let name = event.target.name;
    if (typeof this.state[nestedItem] !== "undefined") //if the nested object doesn't exist in state
      this.setState(state => ((state[nestedItem][name] = val, state)));
    else {
      this.setState({[nestedItem]: {}});
      this.setState(state => ((state[nestedItem][name] = val, state)));
    }
  }

  handleMaskChange = event => {
    //let that = this;
    let name = event.target.name;
    if (event.target.value.length > 0)
        this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));

    this.setState({ [event.target.name] : event.target.value });
  };

  handleMaskFocus = event => {
    let name = event.target.name;
    this.setState(state => ((state.db.shrink[name] = true, state)));
  };

  handleMaskBlur = event => {
    let name = event.target.name;
    if (event.target.value.length > 0)
      this.setState(state => ((state.db.shrink[name] = true, state)));
    else
      this.setState(state => ((state.db.shrink[name] = false, state)));
  };

  handleCheck = event => {
    this.setState({ [event.target.name] : event.target.checked });
  };

  validate = () => {
    console.log("validation")
    let is_valid = this.refs.form.isFormValid(false);

    if (!is_valid)
      NotificationManager.error('You have not completely filled out this portion of the form.', 'Error');
    else {
      this.updateProfile()
    }

  }

  updateLocation = () => {
      this.setState(state => ((state.location.userOverwroteLocation = true, state)));
    }

  makeOSMCalls = () => {
    if (this.state.location.userOverwroteLocation)
      return fetch("/auth/userdata").then(response => [true]);

    var that = this;
    let cord = {};
    return axios.all([getStreetCords(this.state.location),getZipCords(this.state.location)])
    .then(axios.spread(async (streetcords, zipcords) => {
      console.log("stuff:", streetcords, zipcords);
      if (streetcords) { // location query successful

        cord.lat = streetcords.lat;
        cord.lon = streetcords.lon;
      }
      else if (zipcords) { //fall back to zip
        NotificationManager.error('Your address is not found, trying zip code.', 'Error');
        cord.lat = zipcords.lat;
        cord.lon = zipcords.lon;
      }
      else { //fall back to they are dummies
        NotificationManager.error('Your address is not found, location queries will not work.', 'Error');
        cord.lat = 0;
        cord.lon = 0;
      }
      that.setState(state => ((state.location.lat = cord.lat, state)));
      that.setState(state => ((state.location.lon = cord.lon, state)));
      return true
    })).catch(error => {
      NotificationManager.error('Error Reaching all APIS.', 'Something Really Bad Went Wrong');
      return false
    });
  }

  updateProfile = () => {
    let profile = this.state;
    let setUser = this.props.setUser;
    let history = this.props.history;
    let payload = {
      is_masquerade : this.props.is_masquerade,
      admin_id : this.props.admin_id
    }

    let errors =  document.getElementsByClassName('error', 'react-select-container');
    if (errors.length > 0){
      NotificationManager.error('Error Saving.', 'Check your form for errors');
      return false;
    }

    axios.all([this.makeOSMCalls()])
      .then( data => {
        console.log("this is returned data from osmcalls", data)
        if (data[0]){
      api({
      //axios({
        method: 'post',
        url: '/api/user',
        data: {
          profile
        }
      }).then(function (response) {
        if (response.data.errors === undefined  &&  response.data.name === undefined){
          payload.user = response.data;
          setUser(payload);
          NotificationManager.success('Profile Updated.', 'Save Successfull');
          history.push("/default");
        }
        else {
          NotificationManager.error('Error Saving.', 'Something Went Wrong');
        }

      });
    }
    });

  }

  formErrors = (errors) => {
    NotificationManager.error('Check Your Form for errors', 'Form Error');
  }

  handleFileUpload = e => {
    this.setState({disabled: true})
    e.preventDefault();
    
    let jsonData = {
      user_id : this.props.user._id,
    }
    let api = "/api/user_saveFile";
    let type= "resume";
    let description = "student_resume_file";
    let key = "resume_file_id_fk";
    if (e.target.name === "agreementfileUpload"){
      description = "student_agreement_file";
      key = "agreement_file_id_fk";
      type = "agreement";
    }
    const formData = new FormData()
    formData.append('fileupload', e.target.files[0]);
    formData.append('description', description);
    formData.append('key', key);

    axios.post("/api/upload_file", formData, {
    }).then(res => {
      console.log("returned results", res);
      
      this.setState({[key]: res.data._id, disabled: false});
      this.setState(state => ((state.db[type] = res.data, state)));
      jsonData.file_id = res.data._id;
      jsonData.key = key;
      NotificationManager.success(type.toUpperCase() + ' Successfully Uploaded', 'Successfull');
      axios.post(api, jsonData, {
      }).then(res => {
        axios.get("/api/current-user").then(response => {
          let payload = {
            user : response.data.user,
            is_masquerade : response.data.is_masquerade,
            admin_id : response.data.admin_id
          }
          this.props.setUser(payload);
        })
      });
    }).catch(error => {
        NotificationManager.error('Please choose a smaller sized file', 'File too large to upload');
    });
    
  };

  render() {
    return (
      <div className="side-page">
        <div className="basic_form">
          <div className="">
            <ValidatorForm
              ref="form"
              onSubmit={() => this.updateProfile()}
              onError={errors => this.formErrors(errors)}
              instantValidate={true}
            >
              <div className="inline-block">
                <ProfilePic />
              </div>

              <div className="inline-block student_name">{this.state.first_name} {this.state.middle_name} {this.state.last_name}</div>
              <div className="row">
                <div className="col-md-6">
                  <h4>Personal Information</h4>

                  <FontAwesomeIcon icon={["fal", "key-skeleton"]} className="little-icons" /><b>Account Login: {this.props.user.email}</b><br />

                  <TextValidator
                    name="student_id" label="Student ID" variant="outlined"
                    onChange={this.handleChange} value={this.state.student_id}
                    className="inputs" validators={['required']} errorMessages={['this field is required']}
                  />

                  <TextValidator
                    name="first_name" label="First Name" variant="outlined"
                    onChange={this.handleChange} value={this.state.first_name}
                    className="inputs" validators={['required']} errorMessages={['this field is required']}
                  />

                  <TextValidator
                    name="middle_name" label="Middle Name" variant="outlined"
                    onChange={this.handleChange} value={this.state.middle_name}
                    className="inputs"
                  />

                  <TextValidator
                    name="last_name" label="Last Name" variant="outlined"
                    onChange={this.handleChange} value={this.state.last_name}
                    className="inputs" validators={['required']} errorMessages={['this field is required']}
                  />

                  <SingleSelect label="Current Gender Identity Is" name="gender" onChange={this.handleChange} value={this.state.gender} required
                      options={[{value: "Male", label: "Male"},{value: "Female", label: "Female"},{value: "Non-Binary", label: "Non-Binary"}]} />

                  <SingleSelect label="My Pronouns Are (this helps us know the best way to address you)" name="pronouns" onChange={this.handleChange} value={this.state.pronouns}
                    options={[{value: "He/Him", label: "He/Him"},{value: "She/Her", label: "She/Her"},{value: "They/Them", label: "They/Them"}]} />

                    {/*
                  <SingleSelect label="Ethnicity" name="ethnicity" onChange={this.handleChange} value={this.state.ethnicity}
                      options={[{value: "White/Non-Hispanic", label: "White/Non-Hispanic"},{value: "African American", label: "African American"}
                    ,{value: "Hispanic", label: "Hispanic"},{value: "Asian", label: "Asian"},{value: "Pacific Islander", label: "Pacific Islander"}
                  ,{value: "Native American", label: "Native American"},{value: "Other", label: "Other"}]} />
                  */}

                  <h4>Location Information</h4>
                  {this.state.location ?
                  <ClickToViewMap id={this.state._id} location={this.state.location} collection="User" updateLocation={this.updateLocation} /> : null }
                    <TextValidator
                      name="street" label="Street" variant="outlined"
                      onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.street}
                      className="inputs" validators={['required']} errorMessages={['this field is required']}
                    />

                    <TextValidator
                      name="city" label="City" variant="outlined"
                      onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.city}
                      className="inputs" validators={['required']} errorMessages={['this field is required']}
                    />

                  <SingleSelect required autoFill="address-level1" label="State" name="state" onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.state}
                            options={this.state.db.states.map(o =>
                              {
                                return {value: o.code, label: o.name, key: o._id};
                              })} />


                    <TextValidator
                      name="zip" label="Zip" variant="outlined"
                      onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.zip}
                      className="inputs" validators={['required']} errorMessages={['this field is required']}
                    />

              </div>

              <div className="col-md-5 offset-md-1">

              <h4>School Information</h4>

                <TextValidator
                  name="graduation_year" label="Graduation Year" variant="outlined"
                  onChange={this.handleChange} value={this.state.graduation_year}
                  className="inputs" validators={['required']} errorMessages={['this field is required']}
                />

                <SingleSelect required label="School Attending" name="school_attending" onChange={this.handleChange} value={this.state.school_attending}
                      options={this.state.db.schools.map(o =>
                        {
                          return {value: o.name, label: o.name, key: o._id};
                        })} />

                <SingleSelect required label="Affiliated Program" name="affiliated_program" onChange={this.handleChange} value={this.state.affiliated_program}
                      options={this.state.db.programs.map(o =>
                        {
                          return {value: o.name, label: o.name, key: o._id};
                        })} />

                <MultiSelect label="Preferred Work-Based Learning Focus Area(s)" name="focus_areas" onChange={this.handleChange} required
                  value={this.state.focus_areas}
                  options={this.state.db.focusAreas.map(s =>
                    {
                      return {value: s.name, label: s.name};
                    })} />


                <h4>Contact Information</h4>

                    <TextValidator
                      name="home_phone" label="Home Phone" variant="outlined"
                      onChange={this.handleMaskChange} value={this.state.home_phone}
                      onFocus={this.handleMaskFocus}
                      onBlur={this.handleMaskBlur}
                      className="inputs"
                      placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
                      InputLabelProps={{ shrink: this.state.db.shrink.home_phone }}
                    />

                    <TextValidator
                      name="cell_phone" label="Cell Phone" variant="outlined"
                      onChange={this.handleMaskChange} value={this.state.cell_phone}
                      onFocus={this.handleMaskFocus}
                      onBlur={this.handleMaskBlur}
                      className="inputs"
                      placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
                      InputLabelProps={{ shrink: this.state.db.shrink.cell_phone }}
                    />

                    <TextValidator
                      name="parent_name" label="Parent/Guardian Name" variant="outlined"
                      onChange={this.handleChange} value={this.state.parent_name}
                      className="inputs" validators={['required']} errorMessages={['this field is required']}
                    />

                    <TextValidator
                      name="parent_phone" label="Parent/Guardian Phone" variant="outlined"
                      onChange={this.handleMaskChange} value={this.state.parent_phone}
                      onFocus={this.handleMaskFocus}
                      onBlur={this.handleMaskBlur}
                      className="inputs"
                      placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
                      InputLabelProps={{ shrink: this.state.db.shrink.parent_phone }}
                    />

                    {/*
                    <TextValidator
                      name="alternate_parent_name" label="Alternate Parent/Guardian Name" variant="outlined"
                      onChange={this.handleChange} value={this.state.alternate_parent_name}
                      className="inputs" validators={['required']} errorMessages={['this field is required']}
                    />

                    <TextValidator
                      name="alternate_parent_phone" label="Alternate Parent/Guardian Phone" variant="outlined"
                      onChange={this.handleMaskChange} value={this.state.alternate_parent_phone}
                      onFocus={this.handleMaskFocus}
                      onBlur={this.handleMaskBlur}
                      className="inputs"
                      placeholder="(000)000-0000" InputProps={{ inputComponent: PhoneMask }}
                      InputLabelProps={{ shrink: this.state.db.shrink.alternate_parent_phone }}
                    />
                    */}
                  <h4>Resume</h4>
                    {this.state.db.resume !== undefined ?
                      this.state.db.resume._id !== null ? (
                        <><a href={this.state.db.resume.path}>{this.state.db.resume.name}</a><br /></>
                      ):(null):(null)}
                    <Button variant="contained" color="default" onClick={() => this.clickInput("resumefileUpload")}>
                      {!this.state.db.resume ? ("Upload Resume \u00a0"):("Replace Resume \u00a0")}
                      <FontAwesomeIcon icon={["far", "upload"]}/>
                    </Button>

                    <input
                      name="resumefileUpload"
                      id="resumefileUpload"
                      label="Resume"
                      onChange={this.handleFileUpload}
                      type="file"
                      style={{display:"none"}}
                    />

                  <h4>Work-Based Learning Agreement</h4>
                    {this.state.db.agreement !== undefined ?
                      this.state.db.agreement._id !== null ? (
                        <><a href={this.state.db.agreement.path}>{this.state.db.agreement.name}</a><br /></>
                      ):(null):(null)}
                    <Button variant="contained" color="default" onClick={() => this.clickInput("agreementfileUpload")}>
                      {!this.state.db.agreement ? ("Upload Agreement \u00a0"):("Replace Agreement \u00a0")}
                      <FontAwesomeIcon icon={["far", "upload"]}/>
                    </Button>

                    <input
                      name="agreementfileUpload"
                      id="agreementfileUpload"
                      label="Agreement"
                      onChange={this.handleFileUpload}
                      type="file"
                      style={{display:"none"}}
                    />

                    <div className="agreement_instructions mt-3">
                      <ol type="1">
                        <li>Download the Work-Based Learning agreement from the Resources tab</li>
                        <li>Read and sign the agreement</li>
                        <li>Upload the agreement to the your profile</li>
                      </ol>
                      <p>*Your account will not be approved until a signed agreement has been uploaded</p>
                    </div>
              </div>
            </div>



            <div className="mobile-center-button">
            <Button type="submit" variant="contained" color="primary" disabled={this.state.disabled} className="btn_action">
              {this.state.disabled ? <span key="unique"><i class="fal fa-spinner fa-spin"></i>&nbsp;&nbsp;Uploading...</span> : <span key="not_unique">Update Profile</span> }
            </Button>
            </div>

            </ValidatorForm>
        </div>
      </div>
    </div>
    )
  }
}
const ConnectedStudentProfile = withRouter(ConnectedStudentProfileWrapped);
export default connect(mapStateToProps, actions)(ConnectedStudentProfile);
