import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class BrowseList extends Component {
  state = {
    opportunities: [],
  };

  componentDidMount() {
    this.setState({ opportunities: this.props.opportunities });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunities !== this.props.opportunities) {
      this.setState({ opportunities: this.props.opportunities });
    }
  }

  render() {
    return (
      <table className="table table-striped table-hover student-opportunity-list">
        <thead className="thead-light">
          <tr>
            <th scope="col">Opportunity</th>
            <th scope="col">Apply Before</th>
            <th scope="col">Availability</th>
            <th scope="col">Distance</th>
          </tr>
        </thead>
        <tbody>
          {this.state.opportunities.map((opp) => (
            <tr key={opp._id}>
              <td>
                <div className="opportunity-title">
                  {opp.application ? (
                    opp.application.status === "Accepted" ? (
                      <FontAwesomeIcon icon={["fas", "check-circle"]} title="You Have Been Accepted for This Opportunity" />
                    ) : (
                      <FontAwesomeIcon icon={["fal", "check-circle"]} title="You Applied for this Opportunity" />
                    )
                  ) : null}{" "}
                  <Link to={"/opportunity/view/" + opp._id} title="View Opportunity">
                    {opp.opportunity_title}
                  </Link>
                </div>
                <div className="business-name">
                  <Link to={"/profile/view/" + opp.business_id_fk._id} title="Business View">
                    {opp.business_id_fk.business_name}
                  </Link>
                </div>
              </td>
              <td>{moment(opp.application_close).format("MM/DD/YYYY")}</td>
              <td>{parseInt(opp.available_slots) - opp.reportData.accepted_count === 0 ? "Opportunity Full" : parseInt(opp.available_slots) - opp.reportData.accepted_count}</td>
              <td>{opp.distance === "NaN" ? <>UNKNOWN</> : opp.distance === 0 ? <>Virtual</> : <>{opp.distance} mi</>}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
