import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import _ from "underscore";
import { RTEHelper } from "../../_formhelpers";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickToViewMap } from "../Layout/EditMap";
import { getStreetCords, getZipCords } from "../../_map";
import { Popover, OverlayTrigger } from "react-bootstrap";

import { KeyboardDatePicker } from "@material-ui/pickers";

import { MultiSelect, SingleSelect } from "../../_formhelpers";

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

const flattenObject = (obj) => {
  //console.log("I've called flatten Object", obj)
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && !Array.isArray(obj[key]) && obj[key] !== null) {
      //console.log("flattened key", key)
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      //console.log("else flattened key", key, obj[key])
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

let initialState = {
  db: {
    states: [],
    focusAreas: [],
    programs: [],
    schools: [],
  },
  searching: false,
  disabled: false,
  opportunity_title: "",
  available_slots: "",
  short_description: "",
  skills_required: "",
  full_description: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  lat: "",
  lon: "",
  application_open: moment().format(),
  application_close: moment().format(),
  employment_start: moment().format(),
  employment_stop: moment().format(),
  focus_areas: [],
  schools: [],
  programs: [],
  audience: "Open",
  selected_students: [],
  student_ids: [],
};

class OpportunityForm extends Component {
  state = initialState;

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ ...initialState });
      this.makeCalls();
    }
  }

  componentDidMount() {
    this.makeCalls();
    this.setState({
      state: this.props.user.location.state,
      city: this.props.user.location.city,
      street: this.props.user.location.street,
      zip: this.props.user.location.zip,
      lat: this.props.user.location.lat,
      lon: this.props.user.location.lon,
      userOverwroteLocation: this.props.user.location.userOverwroteLocation,
      location: this.props.user.location,
    });
  }

  getStates = () => {
    return axios({
      method: "get",
      url: "/api/getStates",
    });
  };

  getPrograms = () => {
    return axios({
      method: "get",
      url: "/api/getPrograms",
    });
  };

  getFocusAreas = () => {
    return axios({
      method: "get",
      url: "/api/getFocusAreas",
    });
  };

  getSchools = () => {
    return axios({
      method: "get",
      url: "/api/getSchools",
    });
  };

  getOpportunity = () => {
    if (this.props.match.params.id) {
      return axios({
        method: "get",
        url: "/api/loadOpportunity",
        params: {
          opp_id: this.props.match.params.id,
        },
      });
    }
  };

  makeCalls = () => {
    axios.all([this.getSchools(), this.getFocusAreas(), this.getStates(), this.getPrograms(), this.getOpportunity()]).then(
      axios.spread((schools, focusAreas, states, programs, opp) => {
        if (opp) {
          let newState = _.omit(opp.data, "business_id_fk", "reportData", "business");
          if (!_.isEqual(newState.location, this.props.user.location)) {
            this.setState({ location: newState.location });
          }
          let theOpp = flattenObject(newState);
          theOpp._id = this.props.match.params.id;
          if (this.props.location.pathname.split("/")[2] === "duplicate") {
            theOpp.selected_students = [];
            theOpp.audience = "Open";
            theOpp.opportunity_title = theOpp.opportunity_title + " [Copy]";
          }
          this.setState({ ...theOpp });
        } else {
          console.log("doesn't think there is an opp");
          this.setState({ schools: this.props.user.schools, focus_areas: this.props.user.focus_areas });
        }
       
        this.setState({ db: { ...this.state.db, schools: schools.data} });
        this.setState({ db: { ...this.state.db, focusAreas: focusAreas.data} });
        this.setState({ db: { ...this.state.db, states: states.data} });
        this.setState({ db: { ...this.state.db, programs: programs.data} });
      })
    );
  };

  updateLocation = () => {
    this.setState({ userOverwroteLocation: true });
  };

  makeOSMCall = () => {
    if (this.state.userOverwroteLocation) return fetch("/auth/userdata").then((response) => false);

    if (this.state.opportunity_type === "Virtual") return fetch("/auth/userdata").then((response) => false);

    this.setState({ loading: true });
    let cord = {};
    return axios
      .all([getStreetCords(this.state.location), getZipCords(this.state.location)])
      .then(
        axios.spread(async (streetcords, zipcords) => {
          console.log("stuff:", streetcords, zipcords);
          if (streetcords) {
            // location query successful

            cord.lat = streetcords.lat;
            cord.lon = streetcords.lon;
          } else if (zipcords) {
            //fall back to zip
            NotificationManager.error("Your address is not found, trying zip code.", "Error");
            cord.lat = zipcords.lat;
            cord.lon = zipcords.lon;
          } else {
            //fall back to they are dummies
            NotificationManager.error("Your address is not found, location queries will not work.", "Error");
            cord.lat = 0;
            cord.lon = 0;
          }
          this.setState({ lat: cord.lat, lon: cord.lon });
          return cord;
          //that.setState(state => ((state.location.lat = cord.lat, state)));
          //that.setState(state => ((state.location.lon = cord.lon, state)));
        })
      )
      .catch((error) => {
        NotificationManager.error("Error Reaching all APIS.", "Something Really Bad Went Wrong");
        return false;
      });
  };

  handleClickSearch = () => {
    this.setState({ searching: true });
    console.log("search this term: ", this.state.student_search);
    let that = this;
    let studentList = this.state.selected_students;
    //let student_ids = this.state.student_ids;
    if (!studentList) studentList = [];

    /*if (!student_ids)
      student_ids = [];*/

    axios({
      method: "get",
      url: "/api/user_by_email",
      params: {
        email: this.state.student_search,
      },
    }).then(function (response) {
      console.log("search user:", response);
      setTimeout(function () {
        if (response.data) {
          if (response.data.role === "Student") {
            studentList.push({ email: response.data.email, id: response.data._id });
            //student_ids.push(response.data._id)
            that.setState({ searching: false, student_search: "", selected_students: studentList });
          } else {
            that.setState({ searching: false, student_search: "" });
            NotificationManager.error("Sorry No Student with that email exists", "Student does not exist");
          }
        } else {
          that.setState({ searching: false, student_search: "" });
          NotificationManager.error("Sorry No User with that email exists", "Student does not exist");
        }
      }, 500);
    });
  };

  pressEnter = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.handleClickSearch();
    }
  };

  handleChange = (event) => {
    //console.log("fired:", event.target.name, event.target.value);
    if (event.target.name === "student_search") this.setState({ disabled: true });
    else this.setState({ disabled: false });

    if (event.target.name === "audience" && event.target.value === "Open") this.setState({ selected_students: [] });

    if (event.target.name === "opportunity_type" && event.target.value === "Virtual") {
      this.setState({ state: "", street: "", zip: "", city: "" });
    }

    if (event.target.name === "opportunity_type" && event.target.value !== "Virtual") {
      this.setState({ state: this.props.user.location.state, city: this.props.user.location.city, street: this.props.user.location.street, zip: this.props.user.location.zip });
    }

    if (event.target.name === "internship_type" && event.target.value === "Apprenticeship") {
      this.setState({ paidUnpaid: "Paid" });
    }

    if (event.target.name === "street") {
      if (event.target.value !== this.state.street) this.setState({ userOverwroteLocation: false });
    }

    if (event.target.name === "state") {
      if (event.target.value !== this.state.state) this.setState({ userOverwroteLocation: false });
    }

    if (event.target.name === "city") {
      if (event.target.value !== this.state.city) this.setState({ userOverwroteLocation: false });
    }

    if (event.target.name === "zip") {
      if (event.target.value !== this.state.zip) this.setState({ userOverwroteLocation: false });
    }

    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date._d });
  };

  handleRTEChange = (field, value) => {
    this.setState({ [field]: value.toString("html") });
  };

  MShandleChange = (selectedItem) => {
    if (this.state.selectedItem.includes(selectedItem)) {
      this.removeSelectedItem(selectedItem);
    } else {
      this.addSelectedItem(selectedItem);
    }
  };

  MSaddSelectedItem = (item) => {
    this.setState(({ selectedItem, items }) => ({
      inputValue: "",
      selectedItem: [...selectedItem, item],
      items: items.filter((i) => i.name !== item),
    }));
  };

  MSremoveSelectedItem = (item) => {
    this.setState(({ selectedItem, items }) => ({
      inputValue: "",
      selectedItem: selectedItem.filter((i) => i !== item),
      items: [...items, { name: item, id: item.toLowerCase() }],
    }));
  };

  MShandleChangeInput = (inputVal) => {
    const t = inputVal.split(",");
    if (JSON.stringify(t) !== JSON.stringify(this.state.selectedItem)) {
      this.setState({ inputValue: inputVal });
    }
  };

  formErrors = (errors) => {
    NotificationManager.error("Check Your Form for errors", "Form Error");
  };

  pushOpportunity = (e) => {
    let json = _.omit(this.state, "db");
    json.business_id_fk = this.props.user._id;

    if (this.state.selected_students.length !== 0) json.available_slots = 0;
    /*if (this.state.selected_students.length !== 0)
      json.selected_students = this.state.selected_students;
    json.business = {
      business_name: this.props.user.business_name,
      contact_name: this.props.user.contact_name,
      contact_email: this.props.user.contact_email,
      contact_phone: this.props.user.contact_phone,
      location : this.props.user.location,
    }*/
    json.location = {
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
    };
    let api = "/api/createOpportunity";
    let message = "Opportunity successfully created.";
    if (this.props.match.params.id && this.props.location.pathname.split("/")[2] !== "duplicate") {
      api = "/api/editOpportunity";
      message = "Opportunity successfully edited.";
    }
    if (this.props.match.params.id && this.props.location.pathname.split("/")[2] === "duplicate") {
      json = _.omit(json, "_id");
      message = "Opportunity successfully duplicated.";
    }

    this.makeOSMCall().then((data) => {
      if (data) {
        json.location.lat = data.lat;
        json.location.lon = data.lon;
      }

      let that = this;
      console.log("this is the json:", json);
      axios({
        method: "post",
        url: api,
        data: {
          json,
        },
      }).then(function (response) {
        console.log("this is the id:", response);
        let json = {};
        if (that.state.selected_students.length !== 0 && !that.props.match.params.id) {
          //this used to use .map if it breaks (chads react error log fix)
          _.each(that.state.selected_students, function (val, k) {
            json = {
              cover_letter: "Auto Applied",
              date_application: Date().valueOf(),
              opportunity_id_fk: response.data,
              student_id_fk: val.id,
              status: "Offered",
            };
            axios({
              method: "post",
              url: "/api/createApplication",
              data: { json },
            }).then(function (response) {
              if (that.state.selected_students.length === k + 1) {
                NotificationManager.success(message, "Success");
                that.props.history.push("/opportunity/all");
              }
            });
          });
        } else {
          NotificationManager.success(message, "Success");
          that.props.history.push("/opportunity/all");
        }
      });
    });
  };

  popover = () => {
    return (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Popover right</Popover.Header>
        <Popover.Body>
          And here's some <strong>amazing</strong> content. It's very engaging. right?
        </Popover.Body>
      </Popover>
    );
  };

  render() {
    if (this.state.db.schools == null) return null;

    let editingDisabled = false;

    if (this.props.match.params.id && this.props.location.pathname.split("/")[2] !== "duplicate" && this.state.audience !== "Open") editingDisabled = true;

    return (
      <div className="side-page">
        <div className="basic_form">
          <div className="page_header">
            {this.props.match.params.id ? this.props.location.pathname.split("/")[2] === "duplicate" ? <h1>Duplicate Opportunity</h1> : <h1>Edit Opportunity</h1> : <h1>Create Opportunity</h1>}
          </div>

          <ValidatorForm ref="form" id="form" onSubmit={() => this.pushOpportunity()} onError={(errors) => this.formErrors(errors)} instantValidate={true}>
            <div className="row">
              <div className="col-md-6">
                <h4>Audience</h4>
                {this.props.match.params.id ? (
                  this.props.location.pathname.split("/")[2] !== "duplicate" && this.state.audience !== "Open" ? (
                    <div>
                      You may not edit the audience portion of an opportunity that is limited to certain students. You may duplicate this opportunity if you need to offer it to different students or
                      open it to everyone
                    </div>
                  ) : null
                ) : null}
                <SingleSelect
                  disabled={editingDisabled}
                  label="Opportunity Audience"
                  name="audience"
                  onChange={this.handleChange}
                  value={this.state.audience}
                  options={[
                    { value: "Open", label: "Open to All Students" },
                    { value: "Specific", label: "Limited to Specific Students" },
                  ]}
                />

                {this.state.audience === "Specific" && !this.props.match.params.id && this.props.location.pathname.split("/")[2] !== "duplicate" ? (
                  <TextField
                    id="student_search"
                    name="student_search"
                    value={this.state.student_search}
                    className="inputs"
                    onChange={this.handleChange}
                    onKeyUp={this.pressEnter}
                    label="Student Search"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="search_in_input">
                          <IconButton aria-label="search for student" onClick={this.handleClickSearch} edge="end">
                            {this.state.searching ? <FontAwesomeIcon icon={["fal", "spinner"]} spin /> : <FontAwesomeIcon icon={["fal", "search"]} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}

                {this.state.selected_students.length !== 0 ? (
                  <MultiSelect
                    label="Selected Students"
                    name="selected_students"
                    onChange={this.handleChange}
                    value={this.state.selected_students.map((s) => {
                      return s.email;
                    })}
                    labelClass="small-label"
                    disabled={editingDisabled}
                    options={this.state.selected_students.map((s) => {
                      return { value: s.email, label: s.email };
                    })}
                  />
                ) : null}

                <div className="row" style={{ paddingTop: "40px" }}>
                  <div className="col-md-9">
                    <h4>Opportunity Information</h4>
                  </div>
                  <div className="col-md-3 text-right">
                    <OverlayTrigger
                      placement="right"
                      trigger="hover"
                      overlay={
                        <Popover>
                          <Popover.Title as="h3">Opportunity Type</Popover.Title>
                          <Popover.Content>
                            <p>
                              <b>Internship</b>
                            </p>
                            <p>
                              A minimum 135 hour paid or unpaid professional learning experience that offers meaningful, practical work related to a student’s program of study or career interest.{" "}
                            </p>

                            <p>
                              <b>Apprenticeship</b>
                            </p>
                            <p>
                              One year of related classroom instruction and a workplace component with an employer of at least 450 hours. The workplace component is a paid (at least minimum wage)
                              mentored, on-the-job partnership among the student, school, DOL, and the employer.
                            </p>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      {/* <Button variant="success">Open Popover</Button> */}
                      <FontAwesomeIcon icon={["fas", "info-circle"]} style={{ color: "#005488", marginTop: "20px", fontSize: "18pt" }} />
                    </OverlayTrigger>
                  </div>
                </div>

                <TextValidator
                  name="opportunity_title"
                  label="Opportunity Title"
                  variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.opportunity_title}
                  className="inputs"
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />

                <SingleSelect
                  label="Opportunity Type"
                  name="internship_type"
                  onChange={this.handleChange}
                  value={this.state.internship_type}
                  options={[
                    { value: "Internship", label: "Internship" },
                    { value: "Apprenticeship", label: "Apprenticeship" },
                  ]}
                />

                <SingleSelect
                  label="Opportunity Format"
                  name="opportunity_type"
                  onChange={this.handleChange}
                  value={this.state.opportunity_type}
                  options={[
                    { value: "Onsite", label: "Onsite" },
                    { value: "Virtual", label: "Virtual" },
                    { value: "Hybrid", label: "Hybrid" },
                  ]}
                />

                <SingleSelect
                  label="Time Frame"
                  name="time_frame"
                  onChange={this.handleChange}
                  value={this.state.time_frame}
                  options={[
                    { value: "Summer", label: "Summer" },
                    { value: "Fall", label: "Fall" },
                    { value: "Spring", label: "Spring" },
                    { value: "Full Year", label: "Full Year" },
                  ]}
                />

                <TextValidator
                  name="short_description"
                  label="Short Description (~150-250 words)"
                  variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.short_description}
                  className="inputs"
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />

                {this.state.audience !== "Specific" ? (
                  <TextValidator
                    name="available_slots"
                    label="Number of Positions Available"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.available_slots}
                    className="inputs"
                    validators={["required", "isNumber"]}
                    errorMessages={["this field is required", "this field can only be a number"]}
                  />
                ) : null}

                <SingleSelect
                  label="Compensation"
                  name="paidUnpaid"
                  onChange={this.handleChange}
                  value={this.state.paidUnpaid}
                  options={[
                    { value: "Unpaid", label: "Unpaid" },
                    { value: "Paid", label: "Paid" },
                  ]}
                  disabled={this.state.internship_type === "Apprenticeship" ? true : false}
                />

                <RTEHelper label="Skills Required *" name="skills_required" onChange={this.handleRTEChange} value={this.state.skills_required} />

                <RTEHelper label="Opportunity Description *" name="full_description" onChange={this.handleRTEChange} value={this.state.full_description} />
              </div>

              <div className="col-md-5 offset-md-1">
                <h4>Application Window</h4>

                <div className="row>">
                  <div className="col-5" style={{ marginTop: "12px", display: "inline-block", verticalAlign: "text-top" }}>
                    <KeyboardDatePicker
                      className="inputs"
                      name="application_open"
                      variant="outlined"
                      label="Open"
                      format="MM/DD/YYYY"
                      onChange={(date) => this.handleDateChange(date, "application_open")}
                      onBlur={this.handleChange}
                      value={this.state.application_open}
                      validators={["applicationOpenBefore", "required"]}
                      placeholder="MM/DD/YYYY"
                      //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                      keyboard
                      autoOk={true}
                      disableOpenOnEnter
                      animateYearScrolling={false}
                    />
                  </div>
                  <div className="col-5 offset-1" style={{ marginTop: "12px", display: "inline-block", verticalAlign: "text-top" }}>
                    <KeyboardDatePicker
                      className="inputs"
                      name="application_close"
                      variant="outlined"
                      label="Close"
                      format="MM/DD/YYYY"
                      onChange={(date) => this.handleDateChange(date, "application_close")}
                      onBlur={this.handleChange}
                      value={this.state.application_close}
                      validators={["applicationCloseAfter", "required"]}
                      placeholder="MM/DD/YYYY"
                      //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                      keyboard
                      autoOk={true}
                      disableOpenOnEnter
                      animateYearScrolling={false}
                    />
                  </div>
                </div>

                <h4>Employment Dates</h4>
                <div className="row>">
                  <div className="col-5" style={{ marginTop: "12px", display: "inline-block", verticalAlign: "text-top" }}>
                    <KeyboardDatePicker
                      className="inputs"
                      name="employment_start"
                      variant="outlined"
                      label="Start"
                      format="MM/DD/YYYY"
                      onChange={(date) => this.handleDateChange(date, "employment_start")}
                      onBlur={this.handleChange}
                      value={this.state.employment_start}
                      validators={["employmentStartBefore", "required"]}
                      placeholder="MM/DD/YYYY"
                      //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                      keyboard
                      autoOk={true}
                      disableOpenOnEnter
                      animateYearScrolling={false}
                    />
                  </div>
                  <div className="col-5 offset-1" style={{ marginTop: "12px", display: "inline-block", verticalAlign: "text-top" }}>
                    <KeyboardDatePicker
                      className="inputs"
                      name="employment_stop"
                      variant="outlined"
                      label="Stop"
                      format="MM/DD/YYYY"
                      onChange={(date) => this.handleDateChange(date, "employment_stop")}
                      onBlur={this.handleChange}
                      value={this.state.employment_stop}
                      validators={["employmentStopAfter", "required"]}
                      placeholder="MM/DD/YYYY"
                      //mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                      keyboard
                      autoOk={true}
                      disableOpenOnEnter
                      animateYearScrolling={false}
                    />
                  </div>
                </div>
                {this.state.opportunity_type !== "Virtual" ? (
                  <div>
                    <h4>Opportunity Location</h4>
                    {this.state.location ? <ClickToViewMap id={this.props.match.params.id} location={this.state.location} collection="Opportunity" updateLocation={this.updateLocation} /> : null}
                    <TextValidator name="street" label="Street" variant="outlined" onChange={this.handleChange} value={this.state.street} className="inputs" />

                    <TextValidator name="city" label="City" variant="outlined" onChange={this.handleChange} value={this.state.city} className="inputs" />

                    <SingleSelect
                      label="State"
                      name="state"
                      onChange={this.handleChange}
                      value={this.state.state}
                      options={this.state.db.states.map((o) => {
                        return { value: o.code, label: o.name, key: o._id };
                      })}
                    />

                    <TextValidator name="zip" label="Zip" variant="outlined" onChange={this.handleChange} value={this.state.zip} className="inputs" />
                  </div>
                ) : null}

                <h4>Opportunity Affiliation</h4>
                <h6>Program descriptions and school locations can be found in the Resources tab.</h6>

                <MultiSelect
                  label="Focus Area(s)"
                  name="focus_areas"
                  onChange={this.handleChange}
                  value={this.state.focus_areas}
                  options={this.state.db.focusAreas.map((fa) => {
                    return { value: fa.name, label: fa.name };
                  })}
                />

                <MultiSelect
                  label="Programs"
                  name="programs"
                  onChange={this.handleChange}
                  value={this.state.programs}
                  options={this.state.db.programs.map((p) => {
                    return { value: p.name, label: p.name };
                  })}
                />

                <MultiSelect
                  label="Schools"
                  name="schools"
                  onChange={this.handleChange}
                  value={this.state.schools}
                  options={this.state.db.schools.map((s) => {
                    return { value: s.name, label: s.name };
                  })}
                />
              </div>
            </div>

            <div className="mobile-center-button">
              {this.state._id == null ? (
                <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
                  Create Opportunity
                </Button>
              ) : this.props.location.pathname.split("/")[2] === "duplicate" ? (
                <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
                  Duplicate Opportunity
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
                  Edit Opportunity
                </Button>
              )}
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(OpportunityForm);
