import React, { Component } from 'react';
import {NotificationManager} from 'react-notifications';
import axios from 'axios'
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import _ from 'underscore';
import { SingleSelect } from "../../_formhelpers";
import {getStreetCords, getZipCords} from '../../_map'

const actions = {
}

const mapStateToProps = (state, props) => {
  return {
  };
};

class SchoolForm extends Component {

  state = {
    disabled: false,
    user: null,
    cont: true,
    name: "",
    location: {},
    db: {
      states: []
    },
  }

  componentDidMount() {
    if (this.props.selectedUser !== null)
      this.setState({
        ...this.props.selectedUser
      })
      
      axios({
        method: 'get',
        url: '/api/getStates',
      }).then(results => {
        this.setState(state => ((state.db.states = results.data, state)));
      });
  }
  
  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };
  
  handleNestedChange(event, nestedItem ) {
    let val = event.target.value;
    let name = event.target.name;
    if (typeof this.state[nestedItem] !== "undefined") //if the nested object doesn't exist in state
      this.setState(state => ((state[nestedItem][name] = val, state)));
    else {
      this.setState({[nestedItem]: {}});
      this.setState(state => ((state[nestedItem][name] = val, state)));
    }
  }

  makeOSMCalls = () => {
    var that = this;
    let cord = {};
    return axios.all([getStreetCords(this.state.location), getZipCords(this.state.location)])
    .then(axios.spread(async (streetcords, zipcords) => {
      console.log("stuff:", streetcords, zipcords);
      if (streetcords) { // location query successful

        cord.lat = streetcords.lat;
        cord.lon = streetcords.lon;
      }
      else if (zipcords) { //fall back to zip
        NotificationManager.error('Your address is not found, trying zip code.', 'Error');
        cord.lat = zipcords.lat;
        cord.lon = zipcords.lon;
        that.setState({cont: false})
      }
      else { //fall back to they are dummies
        NotificationManager.error('Your address is not found, location queries will not work.', 'Error');
        cord.lat = 0;
        cord.lon = 0;
        that.setState({cont: false})
      }
      that.setState(state => ((state.location.lat = cord.lat, state)));
      that.setState(state => ((state.location.lon = cord.lon, state)));
      return true
    })).catch(error => {
      NotificationManager.error('Error Reaching all APIS.', 'Something Really Bad Went Wrong');
      return false
    });;
  }

  
  createSchool = (end) => {
    this.setState({disabled: true, cont: true});
    if (this.state.name === "" && end){
      this.props.toggleBack(true);
      return 
    }
    
    let that = this;
    let json = _.omit(this.state, ["db"]);
    let api = '/api/createSchool';
    let goBack = this.props.toggleBack;
    let message = 'School successfully created.';
    if (this.state._id) {
      api = '/api/editSchool';
      message = 'School successfully edited.';
    }
    let errors =  document.getElementsByClassName('error', 'react-select-container');
    if (errors.length > 0){
      NotificationManager.error('Error Saving.', 'Check your form for errors');
      return false; 
    }

    axios.all([this.makeOSMCalls()])
      .then(data => {
        if (data[0]){
      if (that.state.cont){
        axios({
          method: 'post',
          url: api,
          data: {
            json
          }
        }).then(function (response) {
          console.log("this is the response:", response)
          if (response.data === "no user"){
            NotificationManager.error("You've been logged out", 'Log Back In');
          }
          else if (response.data === "School Exists"){
            NotificationManager.error("A School already exists with that name, make sure you aren't accidentially addiding a duplicate", 'Try Again');
          }
          else if (response.data !== "fail"  &&  response.data.name === undefined){
            NotificationManager.success(message, 'Save Successfull');
            if (end)
              goBack(true);
            else 
              that.setState({name: "", location: {street: "", city: "", zip: "", state: that.state.location.state}, disabled: false})
          }
          else {
            that.setState({disabled: false})
            NotificationManager.error('Error Saving.', 'Something Went Wrong, check that all fields are filled out');
          }
        });
      }
      else {
        console.log("don't continue")
        that.setState({disabled: false, cont: true})
      }
    } else {
      NotificationManager.error('Google Maps API error.', 'Something Went Wrong');
    }
    });

  }


   render() {

     return (
       
         <ValidatorForm
         ref="form"
         id="form"
         onSubmit={() => this.createSchool(false)}
         onError={errors => this.formErrors(errors)}
         instantValidate={true}
       >
        <TextValidator
        name="name" label="Name" variant="outlined"
        onChange={this.handleChange} value={this.state.name}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
        />
        <SingleSelect label="Type" name="type" onChange={this.handleChange} required
        value={this.state.type} validators={['required']}
        options={[{value: "High", label: "High"},{value: "Middle", label: "Middle"},{value: "Elementary", label: "Elementary"}]}
        />
        <TextValidator
        name="street" label="Street" variant="outlined"
        onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.street}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
      />

      <TextValidator
        name="city" label="City" variant="outlined"
        onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.city}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
      />

    <SingleSelect required label="State" name="state" onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.state}
          options={this.state.db.states.map(o =>
            {
              return {value: o.code, label: o.name, key: o._id};
            })} />

      <TextValidator
        name="zip" label="Zip" variant="outlined"
        onChange={(e) => this.handleNestedChange(e, 'location')} value={(this.state.location === undefined) ? "" : this.state.location.zip}
        className="inputs" validators={['required']} errorMessages={['this field is required']}
      />
        
        <div className="mobile-center-button">
        { !this.state._id ? (
          <>
          <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
            Save and Add Another School
          </Button>
          <Button type="button" variant="contained" color="primary" className="btn_action" onClick={() => this.createSchool(true)}>
          Save and Go Back
        </Button>
         </>
        ) : (
        <Button type="submit" variant="contained" color="primary" className="btn_action" disabled={this.state.disabled}>
          Edit School
        </Button>
        )
        }

        </div>
        
       </ValidatorForm>
     )
   }
}

export default connect(mapStateToProps, actions)(SchoolForm);
