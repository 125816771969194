import React, { Component } from 'react';
import SettingsForm from './SettingsForm';

export default class SiteSettings extends Component {

   render() {

     return (
       <div className="side-page">
         <div className="basic_form">
          <div className="page_header">
             <h1>Site Settings</h1>
          </div>
             
             <div>
               <div className="row">
                 <div className="col-12 table-responsive-sm">
                   <SettingsForm history={this.props.history} />
                 </div>
               </div>
             </div>
          </div>
       </div>
     )
   }
}

