import { createSlice } from 'redux-starter-kit'

export const projects = createSlice({
  initialState: {},
  reducers: {
    updateProjects: (state, action) => {
        return action.payload
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = projects
// Extract and export each action creator by name
export const { updateProjects } = actions
// Export the reducer, either as a default or named export
export default reducer
